
import React, { FC, useEffect, useState } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';
import API from '../../../../helpers/api';

const APIManager = API.instance;

interface Props {
    form: any
    closeModalFinishUpload: any,
    visible: boolean
    updateDocument: any
}

const ModalFinishUpload: FC<Props> = (props) => {
    const handleConfirm = (mode: string) => {
        APIManager.updateFormStatus(props.form.id, 8)
            .then((res) => {
                if (res.status === 200) {
                    props.updateDocument()
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const closeModal = (e: any) => {
        e.preventDefault();
        props.closeModalFinishUpload();
    }

    return (
        <div className={`modal-delete ${props.visible ? 'visible-modal' : ''}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div>
                            <h4>{I18n.t('CONFIRMATION_FINISH_UPLOAD')}</h4>
                        </div>
                        <div className="modal-footer">
                            <div onClick={closeModal}>
                                <Button isButton defaultStyle title={I18n.t('ACCOUNT_MODAL_CLOSE')} />
                            </div>
                            <div onClick={() => handleConfirm('approve')}>
                                <Button isButton defaultStyle title={I18n.t('ACCOUNT_FINISH')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ModalFinishUpload;