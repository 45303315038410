import React, { useState, FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import renderHTML from 'react-render-html';
import Button from '../../../../components/button/button';
import {
    setSelectedWay,
    setYear,
    setBasePrice,
    setAmountByYear,
    setQuestionsResident,
    setAccountInfo,
    setQuestionsNonResident,
    setFinished,
} from '../../../../redux/questionnaire';

interface Props {
    data: any;
    activeStep: number;
    maxStep: number;
    oldForms: Array<any>;
    loadFromOtherForm: boolean;
    setLoadFromOtherForm: (state: boolean) => void;
}

const RequestHeader: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [selectedYear, setSelectedYear] = useState();

    useEffect(() => {
        if (props.oldForms.length > 0) {
            setSelectedYear(props.oldForms[0].year);
        }
    }, [props.oldForms]);

    const title =
        props.activeStep === 1 || props.activeStep === 2
            ? props.data.questionaire_title
            : props.activeStep === 3
            ? props.data.questionaire_price_title
            : props.data.account_creation_title;
    const text =
        props.activeStep === 1
            ? props.data.questionaire_text
            : props.activeStep === 2 || props.activeStep === 3
            ? props.data.questionaire_price_text
            : props.data.account_creation_text;

    const handleSelectYear = () => {
        const selected = props.oldForms.find((f) => f.year === selectedYear);
        if (selected !== undefined) {
            dispatch(setYear(selected.year));
            dispatch(setSelectedWay(selected.answers_form.selectedWay));
            dispatch(setBasePrice(selected.answers_form.basePrice));
            dispatch(setAmountByYear(selected.answers_form.amountByYear));
            dispatch(
                setAccountInfo({
                    firstName: '',
                    lastName: '',
                    zipCode: '',
                    birthdate: '',
                    houseNumber: '',
                    address: '',
                    city: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    acceptedTerms: false,
                })
            );
            dispatch(setQuestionsResident(selected.answers_form.questionsResident));
            dispatch(setQuestionsNonResident(selected.answers_form.questionsNonResident));
            dispatch(setFinished(selected.answers_form.finished));
            props.setLoadFromOtherForm(false);
        }
    };

    return (
        <div className='container request-header'>
            <div className='row d-flex justify-content-end'>
                <div className='col-12 col-lg-7 p-lg-0 content'>
                    <h1 className='title'>{title && title}</h1>
                    <span className='text'>{text && renderHTML(text)}</span>
                </div>
                <div className='col-12 col-lg-4 p-lg-0 d-flex justify-content-center justify-content-lg-end content'>
                    <span className='step'>
                        {I18n.t('QUESTIONAIRE_STEP')} {props.activeStep === 4 ? props.maxStep : props.activeStep}{' '}
                        {I18n.t('QUESTIONAIRE_OF')} {props.maxStep}
                    </span>
                </div>
            </div>
            <div className='row d-flex justify-content-center'>
                <div className='col-12 col-lg-10 p-lg-0 content'>
                    <div className='progress-bar'>
                        {props.maxStep === 4 ? (
                            <div
                                className={`progress 
                                    ${props.activeStep === 1 && '_1-4'}
                                    ${props.activeStep === 2 && '_1-2'}
                                    ${props.activeStep === 3 && '_3-4'}
                                    ${props.activeStep === 4 && 'full'}`}
                            />
                        ) : (
                            <div
                                className={`progress 
                                    ${props.activeStep === 1 && '_1-3'}
                                    ${props.activeStep === 2 && '_2-3'}
                                    ${props.activeStep === 3 && 'full'}`}
                            />
                        )}
                    </div>
                </div>
            </div>
            {props.loadFromOtherForm && (
                <div className='row d-flex justify-content-center'>
                    <div className='col-12 col-lg-10 p-lg-0 content'>
                        <div className='question-container mb-4'>
                            <h2>{renderHTML(props.data.text_imported_from_the_latest_taxt_refund)}</h2>
                            <div className='d-inline-flex flex-sm-row flex-column'>
                                <select
                                    id='filetype'
                                    value={selectedYear}
                                    onChange={(e: any) => setSelectedYear(e.target.value)}
                                >
                                    {props.oldForms.map((form: any) => {
                                        return (
                                            <option key={form.year} value={form.year}>
                                                {form.year}
                                            </option>
                                        );
                                    })}
                                </select>
                                <div className='mt-3 mt-sm-0 ml-0 mx-sm-3'>
                                    <Button title={I18n.t('FORM_IMPORT')} defaultStyle onPress={handleSelectYear} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default RequestHeader;
