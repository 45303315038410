import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import { forgotpage } from '../../redux/pages';
import TextInput from '../../components/textinput/textinput';
import Button from '../../components/button/button';
import { clearLoginStatus, doLogin, login_status } from '../../redux/auth';
import { Link, useHistory } from 'react-router-dom';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import useDebounce from '../../hooks/use-debounce';
import Loader from '../../components/loader/loader';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const ForgotPassword: FC<Props> = (props) => {
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [loaderForm, setLoaderForm] = useState(false);
    const dispatch = useDispatch();
    const PAGEFORGOT = useSelector(forgotpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [formSent, setFormSent] = useState(false);

    const loginStatus = useSelector(login_status);

    useEffect(() => {
        setLoader(true);
    }, []);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGEFORGOT);
    }, [PAGEFORGOT]);

    useEffect(() => {
        if (loginStatus === 'success') {
            dispatch(clearLoginStatus());
            setEmailError(false);
            history.push('/email');
        } else if (loginStatus !== '') {
            setEmailError(true);
            setEmailErrorText(loginStatus);
        }
        setLoaderForm(false);
    }, [loginStatus]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        let valEmail = Validators.validateString(email);

        if (!valEmail[0]) {
            setEmailError(valEmail[0]);
            setEmailErrorText(valEmail[1]);
        } else {
            return;
        }

        sendForm();
    };

    const sendForm = () => {
        setLoaderForm(true);
        APIManager.forgotPassword(email)
            .then((res) => {
                if (res.status === 200) {
                    setFormSent(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoaderForm(false);
            });
    };

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    return (
        <Wrapper loader={loader} activePage={I18n.t('NAV_SIGNIN')} has_title bottom_banner_button_arrow page={PAGE}>
            <section className='login'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-login'>
                            {loaderForm ? (
                                <div className='container-loader'>
                                    <Loader show={loaderForm} />
                                </div>
                            ) : formSent ? (
                                <p>{I18n.t('FORM_FORGOT_SENT')}</p>
                            ) : (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <p>{I18n.t('FORM_FORGOT_EXPLANATION')}</p>
                                        <TextInput
                                            label={I18n.t('FORM_EMAIL_OR_USERNAME')}
                                            value={email}
                                            error={emailError}
                                            errorText={emailErrorText}
                                            onChange={(val: any) => setEmail(val.target.value)}
                                            border
                                        />
                                        <div className='container-button'>
                                            <Button
                                                isButton
                                                defaultStyle
                                                showArrow
                                                title={I18n.t('FORM_FORGOT_PASSWORD_BUTTON')}
                                            />
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default ForgotPassword;
