import React, { useState, FC, useEffect } from 'react';
import '../../style/style.scss';
import API from '../../helpers/api';

const APIManager = API.instance;

interface Props {
    show: boolean
}

const Loader : FC <Props> = (props) => {  
    return (
        props.show ?
            <div className="btn-loader">
                <div className="loader"/>
            </div>
        : null
    );
}
export default Loader;