import React, { FC, useEffect } from 'react';
import '../../style/style.scss';
import { useHistory  } from 'react-router-dom';
import arrow from '../../images/arrow.svg';
import arrowBlue from '../../images/arrow-blue.svg';
import plus from '../../images/plus.svg';

interface Props {
    title: string,
    path?: string,
    disabled?: boolean,
    fullwidth?: boolean,
    showArrow?: boolean,
    showPlus?: boolean,
    reverseArrow?: boolean,
    isButton?: boolean,
    onPress?: () => void,
    //styles
    defaultStyle?: boolean,
    secondaryStyle?: boolean,
    greenStyle?: boolean
}

const Button : FC<Props> = (props) => {
    const history = useHistory();

    const onPress = (e: any) => {
        e.preventDefault();

        if(props.disabled){
            return false;
        } else if(props.path) {
            history.push(props.path);
        } else if (props.onPress){
            props.onPress();
        }
    }

    const getStyle = () => {
        var style = "button link-router";
        if(props.defaultStyle){
            style = style + " primary";
        } else if(props.secondaryStyle){
            style = style + " secondary";
        } else if(props.greenStyle){
            style = style + " green";
        }

        if(props.fullwidth){
            style = style + " fullwidth";
        }

        if(props.disabled){
            style = style + " disabled";
        }
        if(props.isButton){
            style = style + " button-form";
        }
        return style;
    }

    if (props.isButton) {
        return (
            <button className={getStyle()}>
                {props.title} {props.showArrow && <img src={arrow} className="arrow" />}
            </button>
        );
    } else {
        return (
            <div 
                className={getStyle()}
                onClick={(event) => onPress(event)}
            >
                {props.reverseArrow && <img src={arrow} className="arrow-reverse"/>}
                {props.title}
                {props.showArrow && !props.disabled ? <img src={arrow} className="arrow" /> : null}
                {props.showArrow && props.disabled ? <img src={arrowBlue} className="arrow"/> : null}
                {props.showPlus && <img src={plus} className="plus"/> }
            </div>
        );
    }
}
export default Button;