import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import { useHistory, useLocation } from 'react-router-dom';

interface Props {
    activeTab: string,
    viewModalLogOut?: any
}

const Menu : FC<Props> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const navTo = (e: any, page: string) => {
        e.preventDefault();
        history.push(page);
    }


    const navItems = [
        {
            title: I18n.t('ACCOUNT_DASHBOARD'),
            onClick: '/account'
        },
        {
            title: I18n.t('ACCOUNT_PERSONAL'),
            onClick: '/account/personal'
        }
    ]

    return (
        <section className="account">
            <div className="container account-navigation">
                <div className="row justify-content-lg-center gap-2">
                    {navItems.map((item: any) => {
                        return (
                            <div className="col-12 col-lg-3 p-0" key={item.title}>
                                <a
                                    onClick={(event) => navTo(event, item.onClick)} 
                                    className={props.activeTab == item.title ? "nav-block active" : "nav-block"}
                                >
                                    {item.title}
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
}
export default Menu;