import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';

const APIManager = API.instance;

interface thisState {
    questionnaire_request: any;
    questionnaire_answers: any;
    data_user: {
        selectedWay: number;
        year: number;
        basePrice: number;
        amountByYear: number;
        accountInfo: any;
        questionsResident: any;
        questionsNonResident: any;
        finished: boolean;
    };
}

const initialState: thisState = {
    questionnaire_request: [],
    questionnaire_answers: [],
    data_user: {
        selectedWay: 0,
        year: 0,
        basePrice: 0,
        amountByYear: 0,
        accountInfo: {
            firstName: '',
            lastName: '',
            zipCode: '',
            houseNumber: '',
            address: '',
            city: '',
            email: '',
            password: '',
            country: 'NL',
            confirmPassword: '',
            acceptedTerms: false,
        },
        questionsResident: [],
        questionsNonResident: [],
        finished: false,
    },
};

export const thisSlice = createSlice({
    name: 'questionnaire',
    initialState,
    reducers: {
        setQuestionsRequest: (state, action: PayloadAction<[]>) => {
            state.questionnaire_request = action.payload;
        },
        setQuestionsAnswers: (state, action: PayloadAction<[]>) => {
            state.questionnaire_answers = action.payload;
        },
        setSelectedWay: (state, action: PayloadAction<number>) => {
            state.data_user.selectedWay = action.payload;
        },
        setYear: (state, action: PayloadAction<number>) => {
            state.data_user.year = action.payload;
        },
        setBasePrice: (state, action: PayloadAction<number>) => {
            state.data_user.basePrice = action.payload;
        },
        setAmountByYear: (state, action: PayloadAction<number>) => {
            state.data_user.amountByYear = action.payload;
        },
        setAccountInfo: (state, action: PayloadAction<any>) => {
            state.data_user.accountInfo = action.payload;
        },
        setQuestionsResident: (state, action: PayloadAction<any>) => {
            state.data_user.questionsResident = action.payload;
        },
        setQuestionsNonResident: (state, action: PayloadAction<any>) => {
            state.data_user.questionsNonResident = action.payload;
        },
        setFinished: (state, action: PayloadAction<any>) => {
            state.data_user.finished = action.payload;
        },
    },
});
export const {
    setQuestionsAnswers,
    setQuestionsRequest,
    setSelectedWay,
    setYear,
    setBasePrice,
    setAmountByYear,
    setAccountInfo,
    setQuestionsResident,
    setQuestionsNonResident,
    setFinished,
} = thisSlice.actions;

export const getQuestionsAnswers = (): AppThunk => (dispatch) => {
    APIManager.get('/items/questionnaire_answers?fields=*.*', '').then((data) => {
        dispatch(setQuestionsAnswers(data));
    });
};

export const getQuestionsRequest = (): AppThunk => (dispatch) => {
    APIManager.get('/items/questionnaire_request?fields=*.*', '').then((data) => {
        dispatch(setQuestionsRequest(data));
    });
};

export const questionsRequest = (state: RootState) => state.questionnaire.questionnaire_request;
export const questionsAnswers = (state: RootState) => state.questionnaire.questionnaire_answers;
export const dataUser = (state: RootState) => state.questionnaire.data_user;

export default thisSlice.reducer;
