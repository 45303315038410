import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import Loader from '../../components/loader/loader';
import { validateacountpage } from '../../redux/pages';
import Button from '../../components/button/button';
import { useHistory, useParams } from 'react-router-dom';

const APIManager = API.instance;
interface Props {}

const AccountPage: FC<Props> = (props) => {
    const { salt } = useParams<{ salt: string }>();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [errorValidating, setErrorValidating] = useState(false);
    const [loaderValidating, setLoaderValidating] = useState(true);
    const [messageLoader, setMessageLoader] = useState(I18n.t('VALIDATE_VALIDATING'));
    const dispatch = useDispatch();
    const PAGEVALIDATE = useSelector(validateacountpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGEVALIDATE);
        validateAccount();
    }, [PAGEVALIDATE]);

    const validateAccount = () => {
        APIManager.validateAccount(salt)
            .then((res) => {
                if (res.data.message === 'User activated') {
                    setLoaderValidating(false);
                    setMessageLoader(I18n.t('VALIDATE_REDIRECT'));
                    setTimeout(() => {
                        history.push('/sign-in');
                    }, 1000);
                } else if (res.data.message === 'User already activated') {
                    setTimeout(() => {
                        setLoaderValidating(false);
                        setMessageLoader(I18n.t('VALIDATE_REDIRECT'));
                    }, 1000);
                    setTimeout(() => {
                        history.push('/sign-in');
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setLoaderValidating(false);
                        setErrorValidating(true);
                        setMessageLoader(I18n.t('VALIDATE_INVALID'));
                    }, 1000);
                }
            })
            .catch((err) => {
                setTimeout(() => {
                    setLoaderValidating(false);
                    setErrorValidating(true);
                    setMessageLoader(I18n.t('VALIDATE_INVALID'));
                }, 1000);
            });
    };

    return (
        <Wrapper
            loader={loader}
            activePage={I18n.t('NAV_ACCOUNT')}
            circles_is_request_page
            bottom_banner_button_arrow
            page={PAGE}
        >
            <div className={`container validate-page ${PAGE.show_page_banner && 'page-with-header'}`}>
                <div className='row'>
                    <div className='col-12'>
                        <h2>{I18n.t('VALIDATE_TITLE')}</h2>
                        <div className='container-laoder'>
                            <h4 className={`${errorValidating && 'error'}`}>{messageLoader}</h4>
                            <div className='container-bottom-validate'>
                                <Loader show={loaderValidating} />
                                {errorValidating && (
                                    <Button defaultStyle title={I18n.t('NAV_CONTACT')} path={'/contact'} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default AccountPage;
