const CryptoJS = require("crypto-js");
const secretKey = 'myUltraSecretKey'

export default class CryptoJs { 
    static instance: CryptoJs = new CryptoJs();

    encrypt = (text: string) => {
        return CryptoJS.AES.encrypt(text, secretKey)
    }

    decrypt = (text: string) => {
        const bytes = CryptoJS.AES.decrypt(text, secretKey);
        const decryptInfo = bytes.toString(CryptoJS.enc.Utf8);
        return decryptInfo;
    }
}