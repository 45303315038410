import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import CircleLeft from '../../images/circle_left.png';
import CircleRight from '../../images/circle_right.png';

interface Props {
    isHomePage? : boolean,
    isRequestPage?: boolean
}

const CirclesBackground: FC<Props> = (props) => {
    return (
        <div className={
            `circles-background 
                ${props.isHomePage ? "home-page" : 
                props.isRequestPage ? "request-page" : 
                ""
            }`
        }>
            <div className="container-left">
                <img src={CircleLeft} className="circleLeft" />
            </div>
            <div className="container-right">
                <img src={CircleRight} className="circleRight"/>
            </div>
        </div>
    );
}
export default CirclesBackground;