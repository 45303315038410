import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Helpers from '../../../../helpers/functions';
import { Link } from 'react-router-dom';

const Validators = Helpers.instance;

interface Props {
    errorForm: number,
}

const ErrorForm: FC<Props> = (props) => {

    return (
        <div className="container error-form">
            {props.errorForm === 1 ?
                <div className="error">
                    <p>{I18n.t('FORM_ERROR_EMAIL_EXIST')}</p>
                    <p><Link to={{pathname: '/sign-in', state: {}}}>{I18n.t('FORM_ERROR_HAVE_ACCOUNT')}</Link></p>
                </div>
                :
                null
            }
            {props.errorForm === 2 ?
                <div className="error">
                    <p>{I18n.t('FORM_ERROR_SEND_FORM')}</p>
                </div>
                :
                null
            }
            {props.errorForm === 3 ?
                <div className="error">
                    <p>{I18n.t('FORM_ERROR_DO_LOGIN')}</p>
                    <p><Link to={{pathname: '/sign-in', state: {}}}>{I18n.t('NAV_SIGNIN')}</Link></p>
                </div>
                :
                null
            }
        </div>
    );
};

export default ErrorForm;
