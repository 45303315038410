import React, { FC, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLangStrings } from '../redux/general';
import { getPages } from '../redux/pages';
import { getGeneralSettings } from '../redux/general';
import HomePage from '../screens/home/home';
import TextPage from '../screens/textpage/textpage';
import RequestPage from '../screens/request/request';
import NoMatchPage from '../screens/404/404';
import AccountPage from '../screens/account/overview';
import ContactPage from '../screens/contact/contact';
import AccountPersonalInfoPage from '../screens/account/subpages/personal_info/personal_info';
import LoginPage from '../screens/login/login';
import FaqPage from '../screens/faq/faq';
import ValidateAccountPage from '../screens/validate-account/validate-account';
import useGaTracker from './useGaTracker';
import BillingPage from '../screens/account/subpages/billing/billing';
import ForgotPassword from '../screens/forgotPassword/forgotPassword';
import NewPasswordPage from '../screens/newPassword/newPassword';

const Navigation: FC = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLangStrings());
        dispatch(getPages());
        dispatch(getGeneralSettings());
    }, []);

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path='/request' component={RequestPage} />
                <Route exact path='/account' component={AccountPage} />
                <Route exact path='/contact' component={ContactPage} />
                <Route exact path='/sign-in' component={LoginPage} />
                <Route exact path='/account/personal' component={AccountPersonalInfoPage} />
                <Route exact path='/account/billing/:idForm' component={BillingPage} />
                <Route exact path='/about-us' component={TextPage} />
                <Route exact path='/terms-and-conditions' component={TextPage} />
                <Route exact path='/cookie-policy' component={TextPage} />
                <Route exact path='/copyright-notice' component={TextPage} />
                <Route exact path='/privacy-statement' component={TextPage} />
                <Route exact path='/faq' component={FaqPage} />
                <Route exact path='/validate-account/:salt' component={ValidateAccountPage} />
                <Route exact path='/forgot-password' component={ForgotPassword} />
                <Route exact path='/new-password/:token' component={NewPasswordPage} />
                <Route component={NoMatchPage} />
            </Switch>
        </Router>
    );
};

export default Navigation;
