
import React, { FC, useEffect, useState } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';
import API from '../../../../helpers/api';
import TextInput from '../../../../components/textinput/textinput';

const APIManager = API.instance;

interface Props {
    form: any
    closeModalRejectApprove: any,
    mode: string,
    visible: boolean
    updateDocument: any
}

const ModalRejectApprove: FC<Props> = (props) => {
    const [reason, setReason] = useState('');
    const [reasonError, setReasonError] = useState(false);

    const handleConfirm = (mode: string) => {
        const MODE = mode === 'reject' ? 5 : 6;

        setReasonError(false);
        if (mode === 'reject' && reason === '') {
            setReasonError(true);
            return;
        }

        APIManager.updateFormStatus(props.form.id, MODE, mode === 'reject' ? reason : undefined)
            .then(res => {
                if (res.status === 200) {
                    props.updateDocument()
                }
            })
            .catch(err => console.log(err))
    }

    const closeModal = (e: any) => {
        e.preventDefault();
        props.closeModalRejectApprove();
    }

    return (
        <div className={`modal-delete ${props.visible ? 'visible-modal' : ''}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        {props.mode === 'reject' && <h4>{I18n.t('CONFIRMATION_REJECT')}</h4>}
                        {props.mode === 'approve' && <h4>{I18n.t('CONFIRMATION_APPROVE')}</h4>}
                        {
                            props.mode === 'reject' &&
                            <div className="col-12">
                                <TextInput 
                                    label={I18n.t('FORM_REASON_REJECT')}
                                    value={reason}
                                    error={reasonError}
                                    errorText={I18n.t('FORM_REASON_REJECT_ERROR_TEXT')}
                                    textArea
                                    numberofRows={3}
                                    onChange={e => setReason(e.target.value)}
                                />
                            </div>
                        }
                        <div className="modal-footer">
                            <div onClick={closeModal}>
                                <Button isButton defaultStyle title={I18n.t('ACCOUNT_MODAL_CLOSE')} />
                            </div>
                            {
                                props.mode === 'reject' &&
                                <div onClick={() => handleConfirm('reject')}>
                                    <Button isButton defaultStyle title={I18n.t('ACCOUNT_REJECT')} />
                                </div>
                            }
                            {
                                props.mode === 'approve' &&
                                <div onClick={() => handleConfirm('approve')}>
                                    <Button isButton defaultStyle title={I18n.t('ACCOUNT_APPROVE')} />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ModalRejectApprove;