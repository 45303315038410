import React, { useState, FC } from 'react';
import '../../style/style.scss';
import I18n from "../../helpers/i18n";
import { useHistory } from "react-router-dom";
import Wrapper from '../../components/wrapper/wrapper';
import Button from '../../components/button/button';

interface Props {
}

const FOUROFOURPage : FC <Props> = (props) => {
    const history = useHistory();
    const page404 = {
        id: 0,
        status: '',
        cms_name: '',
        seo_title: I18n.t('PAGE_404_SEO_TITLE'),
        seo_description: '',
        title: '',
        text: '',
        show_page_banner: false,
        banner_title: '',
        banner_subtitle: '',
        banner_photo: { filename_disk: '' },
        banner_button_text: '',
        banner_button_link: '',
        show_bottom_banner: false,
        bottom_banner_title: '',
        bottom_banner_title_logged: '',
        banner_bottom_text: '',
        button_link: '',
        button_text: '',
    }

    const navTo = () => {
        history.push('/');
    }

    return (
        <Wrapper
            loader={false}
            activePage={''}
            circles_is_request_page
            page={page404}
        >
            <section className="fourofour">
                <div className="container">
                    <div className="row container-fourofour d-flex flex-column justify-content-center text-center">
                        <span className="number">404</span>
                        <span className="error">{I18n.t('PAGE_404_TITLE')}</span>
                        <div className="container-buttons d-flex flex-column flex-md-row justify-content-center align-items-center gap-3">
                            <Button 
                                title={I18n.t('PAGE_404_HOME')}
                                path={'/'}
                                secondaryStyle />
                            <Button 
                                title={I18n.t('PAGE_404_SERVICES')}
                                path={'/request'}
                                defaultStyle
                                showArrow />
                        </div>
                        
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

export default FOUROFOURPage;