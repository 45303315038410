import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';
import ModalUpload from '../modal_upload/modal_upload';
import ModalDelete from '../modal_delete/modal_delete';
import FUNCTIONS from '../../../../helpers/functions';
import Children from '../children/children';
import uploadImg from '../../../../images/file_upload.svg';
import pendingImg from '../../../../images/hourglass.svg';
import rejectedImg from '../../../../images/close.svg';
import successImg from '../../../../images/done.svg';

const FunctionsManager = FUNCTIONS.instance;
interface Props {
    fileType: any;
    form: any;
    user: any;
    documents: any;
    expandItem: (id: number) => void;
    updateDocument: any;
    confirmationDeleteDocument: (id: number) => void;
}

const DocExpanded: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [documentToDelete, setDocumentToDelete] = useState(undefined);
    const [isVisibleModalUpload, setIsVisibleModalUpload] = useState(false);
    const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);

    const uploadFile = () => {
        setIsVisibleModalUpload(true);
    };

    const deleteDocument = (doc: any) => {
        setDocumentToDelete(doc);
        setIsVisibleModalDelete(true);
    };

    const closeModalDelete = () => {
        setDocumentToDelete(undefined);
        setIsVisibleModalDelete(false);
    };

    const confirmationDeleteDocument = () => {
        setIsVisibleModalDelete(false);
        // @ts-ignore
        props.confirmationDeleteDocument(documentToDelete.documents_id.id);
    };

    const closeModalUpload = () => {
        setIsVisibleModalUpload(false);
    };

    const getStatus = () => {
        let status = 'status expanded';
        let image = undefined;
        let content = '';
        const pending = props.documents?.find((d: any) => d.documents_id.document_status.id === 2);
        const rejected = props.documents?.find((d: any) => d.documents_id.document_status.id === 3);
        const success = props.documents?.filter((d: any) => d.documents_id.document_status.id === 1);

        if (props.documents === undefined) {
            status = status + ' upload';
            image = uploadImg;
        } else if (pending) {
            status = status + ' pending';
            image = pendingImg;
        } else if (rejected) {
            status = status + ' rejected';
            image = rejectedImg;
        } else if (success.length === props.documents.length) {
            status = status + ' success';
            image = successImg;
        }

        if (props.documents === undefined) {
            content = I18n.t('ACCOUNT_UPLOAD');
        } else if (pending) {
            content = I18n.t('ACCOUNT_FORM_IN_PROGRESS');
        } else if (rejected) {
            content = I18n.t('ACCOUNT_REJECTED');
        } else if (success.length === props.documents.length) {
            content = I18n.t('ACCOUNT_APPROVED');
        } else {
            content = I18n.t('ACCOUNT_FORM_IN_PROGRESS');
        }

        return (
            <div className={status}>
                <div className='image'>
                    <img src={image} />
                </div>
                <span className='text-right'>{content}</span>
            </div>
        );
    };

    return (
        <div className='row content-block-account' onClick={() => props.expandItem(props.fileType.id)}>
            <div onClick={(e) => e.stopPropagation()}>
                <ModalUpload
                    key={props.fileType.id * Math.random()}
                    closeModalUpload={closeModalUpload}
                    visible={isVisibleModalUpload}
                    form={props.form}
                    user={props.user}
                    selectedFileType={props.fileType}
                    updateDocument={props.updateDocument}
                />
                <ModalDelete
                    document={documentToDelete}
                    closeModalDelete={closeModalDelete}
                    confirmationDeleteDocument={confirmationDeleteDocument}
                    visible={isVisibleModalDelete}
                />
            </div>
            <div className='col-12 col-lg-2 d-flex p-0'>{getStatus()}</div>
            <div className='col-12 col-lg-9 container-title'>
                <span className='title'>
                    {FunctionsManager.computedStringQuestionaire(
                        props.fileType.type,
                        props.form.year,
                        props.form.answers_form.amountByYear
                    )}
                </span>
                <span className='text subtitle'>
                    {props.fileType.name_document &&
                        FunctionsManager.computedStringQuestionaire(
                            props.fileType.name_document,
                            props.form.year,
                            props.form.answers_form.amountByYear
                        )}
                </span>
                <span className='text description'>
                    {props.fileType.description &&
                        FunctionsManager.computedStringQuestionaire(
                            props.fileType.description,
                            props.form.year,
                            props.form.answers_form.amountByYear
                        )}
                </span>
            </div>
            <div className='col-12 col-lg-1 justify-content-end d-none d-lg-flex'>
                <div className='arrow expanded' />
            </div>
            <div className='col-12 col-lg-10 offset-lg-2 d-flex flex-column justify-content-center justify-content-lg-end'>
                {props.fileType.id === 5 ? (
                    <Children form={props.form} updateChilds={props.updateDocument} />
                ) : props.documents === undefined ? (
                    <div className='m-1' onClick={(e) => e.stopPropagation()}>
                        <div className='row m-1 d-flex justify-content-end'>
                            <div className='col-12 col-lg-8 col-xl-6'>
                                <Button
                                    defaultStyle
                                    title={I18n.t('UPLOAD_CHOOSE')}
                                    onPress={() => uploadFile()}
                                    fullwidth
                                    showPlus
                                    disabled={props.form.form_status.id !== 1}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    props.documents.map((doc: any) => {
                        return (
                            <div
                                className={`w-100 m-auto mt-4 row file-document ${
                                    props.fileType.multiple_files && 'extra-padding'
                                }`}
                                onClick={(e) => e.stopPropagation()}
                            >
                                {props.fileType.multiple_files && (
                                    <div className={`status-document ${doc.documents_id.document_status.status}`}>
                                        {doc.documents_id.document_status.status}
                                    </div>
                                )}
                                <div className='col-12 col-lg-6 d-flex flex-column justify-content-center align-items-center'>
                                    <a
                                        className='link align-self-lg-center'
                                        href={`https://beheer.taxt.nl/assets/${doc.documents_id.file.filename_disk}?download`}
                                        download={doc.documents_id.file.filename_download}
                                        target='_blank'
                                    >
                                        {doc.documents_id.file.filename_download}
                                    </a>
                                    {doc.documents_id.note && <p className='mt-3'>{doc.documents_id.note}</p>}
                                </div>
                                <div className='col-12 col-lg-6 mt-3 mt-lg-0'>
                                    <Button
                                        title={I18n.t('DELETE_DOCUMENT')}
                                        secondaryStyle
                                        onPress={() => deleteDocument(doc)}
                                        fullwidth
                                        disabled={
                                            doc.documents_id.document_status.id === 1 || props.form.form_status.id !== 1
                                        }
                                    />
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
            {props.fileType.multiple_files && (
                <div className='col-12 mt-4' onClick={(e) => e.stopPropagation()}>
                    <div className='row m-1 d-flex justify-content-end'>
                        <div className='col-12 col-lg-6'>
                            <Button
                                defaultStyle
                                title={I18n.t('UPLOAD_ANOTHER')}
                                onPress={() => uploadFile()}
                                fullwidth
                                showPlus
                                disabled={props.form.form_status.id !== 1}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className='col-12 col-lg-1 d-flex d-lg-none justify-content-center container-external-arrow container-arrow-expanded'>
                <div className='arrow-container'>
                    <div className='arrow expanded' />
                </div>
            </div>
        </div>
    );
};
export default DocExpanded;
