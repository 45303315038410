import React, { useState, FC, useEffect } from 'react';
import renderHTML from 'react-render-html';
import I18n from '../../../../helpers/i18n';
import '../../../../style/style.scss';
import ImageQuestion from '../../../../images/question.svg';
import ModalDescription from '../modal_description/modal_description';
import FUNCTIONS from '../../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, setQuestionsResident, setYear } from '../../../../redux/questionnaire';
import InputAddress from '../input_address/input_address';
import { amountQuestion } from '../../../../helpers/amountQuestion';
import TextInput from '../../../../components/textinput/textinput';

const FunctionsManager = FUNCTIONS.instance;

interface Props {
    question: any;
    answers: any;
}

const calculateDefaultValue = (dataUser: any, questionId: number) => {
    const question = dataUser.questionsResident.find((q: any) => q.question.id === questionId);
    if (question) {
        return question.answer.id;
    } else {
        return 0;
    }
};

const Question: FC<Props> = (props) => {
    const DATA_USER = useSelector(dataUser);
    const dispatch = useDispatch();
    const [visibleSelectYear, setVisibleSelect] = useState(true);
    const [isVisibleModalDescription, setIsVisibleModalDescription] = useState(false);
    const [defaultValue, setDefaultValue] = useState<number>(calculateDefaultValue(DATA_USER, props.question.id));

    const toggleCheck = (idAnswer: number, valueAnswer: string, amount1: number, amount2: number) => {
        const questionId = props.question.id;

        if ([31, 33, 35, 37, 39, 41, 43, 45, 47, 50].includes(questionId)) {
            if (idAnswer === 0) return;
        }

        const fiscalPartner = DATA_USER.questionsResident.find(
            (q: any) => q.question.id === 8 && [1].includes(q.answer.id)
        );
        const amount = amountQuestion(questionId, idAnswer, valueAnswer, amount1, amount2, fiscalPartner);

        const questionInsert = {
            question: {
                id: questionId,
                question: props.question.question,
                order: props.question.order,
            },
            answer: {
                id: idAnswer,
                answer: valueAnswer,
                amount: amount,
            },
        };

        const insert = DATA_USER.questionsResident.filter((q: any) => {
            // if ([31, 33, 35, 37, 39, 41, 43, 45, 47, 50].includes(questionId)) {
            //     return
            // }
            return q.question.order < questionInsert.question.order;
        });

        dispatch(setQuestionsResident([...insert, questionInsert]));
    };

    const changeYear = (e: any) => {
        setVisibleSelect(false);
        dispatch(setYear(parseInt(e.target.value)));
    };

    const selectAnswer = () => {
        if (props.question.id === 3) {
            return renderYears();
        } else if ([31, 33, 35, 37, 39, 41, 43, 45, 50].includes(props.question.id)) {
            return renderHowMany();
        } else if (props.question.id === 47) {
            return renderOtherAssets();
        } else {
            return renderAnswers();
        }
    };

    const renderYears = () => {
        let years = [];
        const numberOfYears = 5;
        const startYear = new Date().getFullYear() - 1;
        for (let index = 0; index < numberOfYears; index++) {
            years.push(startYear - index);
        }

        return (
            <select id='filetype' value={DATA_USER.year} onChange={changeYear}>
                {visibleSelectYear && <option value={0}>{I18n.t('FORM_SELECT_YEAR')}</option>}
                {years.map((year: any) => {
                    return (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    );
                })}
                {/* <option key={1970} value={1970}>{`${I18n.t('FORM_BEFORE')} ${startYear - 4}`}</option> */}
            </select>
        );
    };

    const renderHowMany = () => {
        return (
            <div className='d-flex flex-column flex-lg-row gap-2 col-12 col-md-6 col-lg-12 m-auto mt-4'>
                <InputAddress
                    onChange={(val: string, focus: boolean) => changeHowMany(+val, focus)}
                    defaultQuantity={defaultValue}
                />
            </div>
        );
    };

    const changeHowMany = (quantity: number, focus: boolean) => {
        if (focus) {
            toggleCheck(quantity, `${quantity}_howMany`, props.question.amount_1, props.question.amount_2);
        }
    };

    const [otherValue, setOtherValue] = useState('');
    const renderOtherAssets = () => {
        return (
            <div className='col-12'>
                <TextInput
                    textArea
                    label={''}
                    value={otherValue}
                    error={false}
                    errorText={''}
                    onChange={(val) => handleOtherValue(val.target.value)}
                />
            </div>
        );
    };

    const handleOtherValue = (val: string) => {
        toggleCheck(1, val, props.question.amount_1, props.question.amount_2);
        setOtherValue(val);
    };

    const renderAnswers = () => {
        return props.answers.map((answer: any) => {
            const selected = DATA_USER.questionsResident.find((object: any) => {
                return object.question.id === props.question.id && object.answer.id === answer.id;
            });
            return (
                <span
                    className={`button ${selected ? 'blue' : 'secondary'}`}
                    key={answer.id}
                    onClick={() =>
                        toggleCheck(answer.id, answer.answer, props.question.amount_1, props.question.amount_2)
                    }
                >
                    {answer.answer}
                </span>
            );
        });
    };

    const closeModalDescription = () => {
        setIsVisibleModalDescription(false);
    };

    return (
        <div className='question-container' id={`question-${props.question.id}`}>
            {typeof props.question.description === 'string' && props.question.description !== '' && (
                <ModalDescription
                    closeModalDescription={closeModalDescription}
                    visible={isVisibleModalDescription}
                    // @ts-ignore: Unreachable code error
                    text={renderHTML(
                        FunctionsManager.computedStringQuestionaire(
                            props.question.description,
                            DATA_USER.year,
                            DATA_USER.amountByYear
                        )
                    )}
                />
            )}
            {/* prettier-ignore */}
            <span
                className={`question \
                ${DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) && 'hide-dividend-tax'} \
                ${DATA_USER.year >= 2022 && 'hide-study-cost'} \
                ${DATA_USER.questionsResident.find((q: any) => q.question.id === 8 && q.answer.id === 2) && 'no-fiscal-partner'}`
                }
            >
                {renderHTML(
                    FunctionsManager.computedStringQuestionaire(
                        props.question.question,
                        DATA_USER.year,
                        DATA_USER.amountByYear
                    )
                )}
            </span>
            {typeof props.question.description === 'string' && props.question.description !== '' && (
                <img
                    className='button-question'
                    src={ImageQuestion}
                    alt='description-question'
                    onClick={() => setIsVisibleModalDescription(true)}
                />
            )}
            <div className='d-flex flex-column flex-lg-row gap-2 col-12 col-md-6 col-lg-12 m-auto mt-4'>
                {selectAnswer()}
            </div>
        </div>
    );
};

export default Question;
