import React, { useState, FC, useEffect } from 'react';
import Plus from '../../../../images/add.png';
import Minus from '../../../../images/remove.png';
import '../../../../style/style.scss';

interface Props {
    onChange: any;
    defaultQuantity: number;
    index?: number;
}

const InputAddress: FC<Props> = (props) => {
    const [quantity, setQuantity] = useState(props.defaultQuantity);
    const [focus, setFocus] = useState(false);
    // let focus = false;

    useEffect(() => {
        props.defaultQuantity !== undefined && setQuantity(props.defaultQuantity);
    }, [props.defaultQuantity]);

    useEffect(() => {
        props.onChange(quantity, focus, props.index);
    }, [quantity]);

    const plusOne = () => {
        setQuantity(quantity + 1);
    };

    const minusOne = () => {
        quantity > 1 && setQuantity(quantity - 1);
    };

    return (
        <div
            className='input-address'
            key={Math.random()}
            onMouseEnter={() => {
                setFocus(true);
            }}
            onMouseLeave={() => {
                setFocus(false);
            }}
        >
            <button className='button-input minus' onClick={minusOne}>
                <img src={Minus} alt='minus' />
            </button>
            <input
                type='number'
                name='input-address'
                id='input-address'
                value={quantity}
                onChange={(e) => {
                    if (e.target.value === '') {
                        setQuantity(1);
                    } else if (isNaN(parseInt(e.target.value))) {
                        setQuantity(quantity);
                    } else if (parseInt(e.target.value) === 0) {
                        setQuantity(1);
                    } else {
                        setQuantity(parseInt(e.target.value));
                    }
                }}
            />
            <button className='button-input plus' onClick={plusOne}>
                <img src={Plus} alt='plus' />
            </button>
        </div>
    );
};

export default InputAddress;
