import React, { FC, useState, useEffect } from 'react';
import '../../../style/style.scss';
import I18n from "../../../helpers/i18n";
import { Link } from "react-router-dom";
import API from '../../../helpers/api';
import { login_status, setLoginStatus, user } from '../../../redux/auth';
import OnOutsideClick from 'react-outclick';
import MenuIconLight from '../../../images/menu_light.png';
import MenuIconDark from '../../../images/menu_dark.png';
import CloseIconLight from '../../../images/close_light.png';
import CloseIconDark from '../../../images/close_dark.png';
import { useDispatch, useSelector } from 'react-redux';

const APIManager = API.instance;
interface Props {
    items: any,
    activePage?: string,
    light?: boolean,
    openModalLogOut: any
}

const Menu : FC <Props> = (props) => {  
    const dispatch = useDispatch();
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [showMenu, setShowMenu] = useState(false);
    const loginStatus = useSelector(login_status);

    const openCloseMenu = (e: any) => {
        e.preventDefault();
        setShowMenu(!showMenu);
    }

    useEffect(() => {
        APIManager.isUserLoggedIn().then( (loggedIn) => {
            if (loggedIn) {
                setLoggedIn(loggedIn);
                dispatch(setLoginStatus('success'));
            }
        });
    }, [loginStatus])

    const getStyle = () => {
        var style = "menu";

        if(showMenu){
            style = style + " open";
        }
        if(props.light){
            style = style + " light";
        }

        return style;
    }

    return (
        <OnOutsideClick onOutsideClick={(ev: Event) => setShowMenu(false)}>
            {showMenu ?
                <a href="" onClick={(event) => openCloseMenu(event)} className="mobile-item">
                    <img src={props.light ? CloseIconLight : CloseIconDark} alt={I18n.t('NAV_CLOSE_MENU')} className="close-menu-icon"/>
                </a>
            :
                <a href="" onClick={(event) => openCloseMenu(event)} className="mobile-item">
                    <img src={props.light ? MenuIconLight : MenuIconDark} alt={I18n.t('NAV_MENU')} className="menu-icon"/>
                </a>
            }

            <div className={getStyle()}>
                <div className="container">
                    <div className="row mobile-row">
                        <div className="col-12">
                            {props.items.map((item: any) => {
                                return (
                                    <Link
                                        to={{ pathname: item.url, state: {} }}
                                        key={item.title}
                                        className="link-router">
                                        <div
                                            className="mobile-item"
                                            key={item.title}>
                                            {item.title}
                                            {(props.activePage && props.activePage === item.title) &&
                                                <span className="active-indicator-mobile"/>}
                                        </div>
                                    </Link>
                                )
                            })}
                            {loggedIn ?
                                <div>
                                    <a className={`link-router ${props.activePage === I18n.t('ACCOUNT_LOGOUT') && 'active'}`} onClick={() => props.openModalLogOut()}>
                                        <div className="mobile-item">
                                            {I18n.t('ACCOUNT_LOGOUT')}
                                        </div>
                                    </a>
                                    <Link to={{pathname: '/account', state: {}}} className={`link-router ${props.activePage === I18n.t('NAV_ACCOUNT') && 'active'}`}>
                                        <div className="mobile-item">
                                            {I18n.t('NAV_ACCOUNT')}
                                            {(props.activePage && props.activePage === I18n.t('NAV_ACCOUNT')) &&
                                                    <span className="active-indicator-mobile"/>}
                                        </div>
                                    </Link>
                                </div>
                            :
                                <div>
                                    <Link to={{pathname: '/sign-in', state: {}}} className={`link-router ${props.activePage === I18n.t('NAV_SIGNIN') && 'active'}`}>
                                        <div className="mobile-item">
                                            {I18n.t('NAV_SIGNIN')}
                                            {(props.activePage && props.activePage === I18n.t('NAV_SIGNIN')) &&
                                                <span className="active-indicator-mobile"/>}
                                        </div>
                                    </Link>
                                    <Link to={{pathname: '/request', state: {}}} className={`link-router ${props.activePage === I18n.t('NAV_SIGNUP') && 'active'}`}>
                                        <div className="mobile-item">
                                            {I18n.t('NAV_SIGNUP')}
                                            {(props.activePage && props.activePage === I18n.t('NAV_SIGNUP')) &&
                                                <span className="active-indicator-mobile"/>}
                                        </div>
                                    </Link>
                                </div>
                            }
                        </div>
                    </div>
                </div>     
            </div>

        </OnOutsideClick>
    );
}
export default Menu;