import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import FUNCTIONS from '../../../../helpers/functions';
import uploadImg from '../../../../images/file_upload.svg'
import pendingImg from '../../../../images/hourglass.svg'
import rejectedImg from '../../../../images/close.svg'
import successImg from '../../../../images/done.svg'

const FunctionsManager = FUNCTIONS.instance;

interface Props {
    year: number
    amountByYear: number
    documents: any
    fileType: any
    expandItem: (id: number) => void
}

const DocCollapsed : FC<Props> = (props) => {
    const dispatch = useDispatch();

    const getStatus = () => {
        let status = "status";
        let image = undefined;
        const pending = props.documents?.find((d: any) => d.documents_id.document_status.id === 2)
        const rejected = props.documents?.find((d: any) => d.documents_id.document_status.id === 3);
        const success = props.documents?.filter((d: any) => d.documents_id.document_status.id === 1);

        if(props.documents === undefined){
            status = status + " upload";
            image = uploadImg;
        } else if (pending){
            status = status + " pending";
            image = pendingImg;
        } else if (rejected){
            status = status + " rejected";
            image = rejectedImg;
        } else if (success.length === props.documents.length){
            status = status + " success";
            image = successImg;
        }

        return (
            <div className={status}>
                <div className="image">
                    <img src={image} />
                </div>
            </div>
        );
    }

    return (
        <div className="row content-block-account" onClick={() => props.expandItem(props.fileType.id)}>
            <div className="col-12 col-lg-2 d-flex p-0">
                {getStatus()}
            </div>
            <div className="col-12 col-lg-9">
                <span className="title">
                    {FunctionsManager.computedStringQuestionaire(
                        props.fileType.type,
                        props.year,
                        props.amountByYear)
                    }
                </span>
            </div>
            <div className="col-12 col-lg-1 d-none d-lg-flex justify-content-center justify-content-lg-end">
                <div className="arrow"/>
            </div>
            <div className="col-12 col-lg-1 d-flex d-lg-none justify-content-center container-external-arrow" >   
                <div className="arrow-container">
                    <div className="arrow"/>
                </div>
            </div>
        </div>
    );
}
export default DocCollapsed;