import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/style.scss';
import I18n from "../../../helpers/i18n";
import renderHTML from 'react-render-html';
import { useHistory, useLocation } from "react-router-dom";
interface Props {
    iconImage: any,
    iconNumber: any,
    title: string,
    text: string
}

const IconBlock : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
   
    return (
        <div className="col-12 col-lg-3 icon-container d-flex flex-column align-items-center">
            <div className="icon-block d-flex align-items-center justify-content-center">
                <img 
                    src={props.iconImage} 
                    className={"icon-" + props.iconNumber} alt={props.iconNumber}
                />
                <div className="number-container d-flex justify-content-center align-items-center">
                    <span className="number">
                        {props.iconNumber}
                    </span>
                </div>
            </div>
            <span className="title">{props.title}</span>
            <span className="text">
                {props.text &&
                    renderHTML(props.text)
                }
            </span>
        </div>
    );
}

export default IconBlock;