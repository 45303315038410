import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import renderHTML from 'react-render-html';
import FUNCTIONS from '../../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, questionsAnswers, questionsRequest, setFinished } from '../../../../redux/questionnaire';
import QuestionPrice from '../../components/question/question_price';
import { login_status } from '../../../../redux/auth';
import CalculateCost from '../../components/calculate_cost/calculate_cost';

const FunctionsManager = FUNCTIONS.instance;

interface Props {
    disabledNextStep: any;
    denniedText: any;
}

interface questionsAnsweredInterface {
    idQuestion: number;
    idAnswer: number | null;
    amount: number | undefined;
}

const PriceQuestions: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const QUESTIONS_REQUEST = useSelector(questionsRequest);
    const QUESTIONS_ANSWERS = useSelector(questionsAnswers);
    const DATA_USER = useSelector(dataUser);
    const loginStatus = useSelector(login_status);

    useEffect(() => {
        props.disabledNextStep(true);
    }, []);

    useEffect(() => {
        const condition1 = DATA_USER.questionsResident.find((q: any) => q.question.id === 21);
        const condition2 = DATA_USER.questionsResident.find(
            (q: any) => q.question.id === 7 && [2].includes(q.answer.id)
        );
        const condition3 =
            (DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) &&
                DATA_USER.questionsResident.find((q: any) => q.question.id === 15 && [2].includes(q.answer.id))) ||
            (DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) &&
                DATA_USER.questionsResident.find((q: any) => q.question.id === 16));

        if (condition1 || condition2 || condition3) {
            props.disabledNextStep(false);
            if (loginStatus === 'success') {
                dispatch(setFinished(true));
            } else {
                dispatch(setFinished(false));
            }
        } else {
            dispatch(setFinished(false));
            props.disabledNextStep(true);
        }
    }, [DATA_USER.questionsResident]);

    // Scroll to active question
    useEffect(() => {
        const className = `question-container`;
        const elements = document.getElementsByClassName(className);
        if (elements) {
            elements[elements.length - 1].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        }
    }, [DATA_USER.questionsResident]);

    return (
        <div className='container'>
            <div className='row d-flex justify-content-center align-items-start'>
                <div className='col-12 col-lg-8 p-lg-0'>
                    {
                        // 36 Do you own or rent your primary residency (house) in The Netherlands?
                        <QuestionPrice
                            question={QUESTIONS_REQUEST.find((q: any) => q.id === 9)}
                            answers={QUESTIONS_ANSWERS.filter((a: any) => [6, 7, 8].includes(a.id))}
                        />
                    }
                    {
                        // 37 Purchased or sold this year?
                        DATA_USER.questionsResident.find(
                            (q: any) => q.question.id === 9 && [7].includes(q.answer.id)
                        ) && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 12)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 38 Do you or your fiscal partners (if applicable) own investment real-estate located in The Netherlands?
                        // prettier-ignore
                        DATA_USER.questionsResident.find((q: any) => q.question.id === 8) &&
                        DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) &&
                        DATA_USER.questionsResident.find((q: any) => q.question.id === 9)
                        && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 13)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 39 How many addresses
                        DATA_USER.questionsResident.find(
                            (q: any) => q.question.id === 13 && [1].includes(q.answer.id)
                        ) && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 14)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 40 Do you or your fiscal partner have a substantial shareholding (“?1”) in a Dutch limited liability company
                        // prettier-ignore
                        (
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 14) ||
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 13 && [2].includes(q.answer.id)) ||
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 9 && [6, 8].includes(q.answer.id)) ||
                            (
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 12) &&
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 8 && [2].includes(q.answer.id))
                            ) ||
                            (
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 12) &&
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [2].includes(q.answer.id))
                            )
                        ) && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 15)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 41 Did you receive income (“?”) from this?
                        DATA_USER.questionsResident.find(
                            (q: any) => q.question.id === 15 && [1].includes(q.answer.id)
                        ) && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 16)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 42 Do you have a substantial shareholding* in a foreign limited liability company?
                        // prettier-ignore
                        DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [2, 3].includes(q.answer.id)) &&
                        DATA_USER.questionsResident.find((q: any) => q.question.id === 16 ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 15 && [2].includes(q.answer.id))
                        ) && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 7)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 43 Did you receive income from this?
                        DATA_USER.questionsResident.find(
                            (q: any) => q.question.id === 7 && [1].includes(q.answer.id)
                        ) && (
                            <QuestionPrice
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 21)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                </div>
                <CalculateCost />
                <div className='col-12 col-lg-3 p-lg-0 d-none d-lg-flex position-relative'></div>
            </div>
        </div>
    );
};

export default PriceQuestions;
