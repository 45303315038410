import React, { useState, FC } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';
import API from '../../../../helpers/api';
import Helpers from '../../../../helpers/functions';
import DocCollapsed from '../document/doc_collapsed';
import DocExpanded from '../document/doc_expanded';
import ModalRejectApprove from '../modal_reject_approve/modal_reject_approve';
import ModalFinishUpload from '../modal_finish_upload/modal_finish_upload';
import { useSelector } from 'react-redux';
import { generalSettings } from '../../../../redux/general';
import TextInput from '../../../../components/textinput/textinput';
import Loader from '../../../../components/loader/loader';
import { useHistory } from 'react-router-dom';

const Validators = Helpers.instance;
const APIManager = API.instance;

interface Props {
    form: any;
    user: any;
    deleteDocument: any;
    updateDocument: any;
    errorDelete: boolean;
    passportInfo: any;
    fileTypes: Array<any>;
    confirmationDeleteDocument: (id: number) => void;
}

const FormTax: FC<Props> = (props) => {
    const history = useHistory();
    const [notes, setNotes] = useState(props.form.notes);
    const [messageNotes, setMessageNotes] = useState('');
    const { text_form_approved_by_user } = useSelector(generalSettings);
    const [isVisibleModalRejectApprove, setIsVisibleModalRejectApprove] = useState(false);
    const [isVisibleModalFinishUpload, setIsVisibleModalFinishUpload] = useState(false);
    const [modeRejectApprove, setModeRejectApprove] = useState('');
    const [expandedItem, setExpandedItem] = useState(0);
    const [loaderUpdate, setLoaderUpdate] = useState(false);

    const openModalRejectApprove = (mode: string) => {
        setModeRejectApprove(mode);
        setIsVisibleModalRejectApprove(true);
    };

    const closeModalRejectApprove = () => {
        setModeRejectApprove('');
        setIsVisibleModalRejectApprove(false);
    };

    const expandItem = (id: number) => {
        if (expandedItem === id) {
            setExpandedItem(0);
        } else {
            setExpandedItem(id);
        }
    };

    const purchaseForm = () => {
        history.push(`/account/billing/${props.form.id}?utm_source=banner&utm_medium=cpc&utm_campaign=iamexpat`);
        // setLoaderPurchase(true)
        // APIManager.purchase(props.form.id, props.form.total_cost, props.form.year)
        //     .then((res) => {
        //         if (window.location.href) {
        //             window.location.href = res.data.url
        //         }
        //     })
        //     .catch((err) => {
        //         console.log(err)
        //     })
        //     .finally(() => setLoaderPurchase(false))
    };

    const getStatus = () => {
        let status = 'status-form';
        let content = '';

        if (props.form.form_status.id === 1) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_IN_PROGRESS');
        } else if (props.form.form_status.id === 2) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_DOCUMENTS_APPROVED');
        } else if (props.form.form_status.id === 3) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_PAYMENTS_RECEIVED', { year: props.form.year });
        } else if (props.form.form_status.id === 4) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_RETURN_COMPLETED');
        } else if (props.form.form_status.id === 5) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_RETURN_DECLINED');
        } else if (props.form.form_status.id === 6) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_RETURN_APPROVED');
        } else if (props.form.form_status.id === 7) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_RETURN_SUBMITTED');
        } else if (props.form.form_status.id === 8) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_PENDING_REVIEW');
        } else if (props.form.form_status.id === 9) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_PRICE_REVIEW');
        } else if (props.form.form_status.id === 10) {
            status = status + ' in-progress';
            content = I18n.t('ACCOUNT_FORM_TAX_PRICE_APPROVAL');
        }

        return <div className={status}>{content}</div>;
    };

    const getDocument = (id: number) => {
        const documents = props.form.documents.filter((d: any) => {
            if (d.documents_id === null) return;
            return d.documents_id.type.id === id;
        });
        return documents.length > 0 ? documents : undefined;
    };

    const updateNotes = () => {
        setMessageNotes('');
        APIManager.updateFormNotes(props.form.id, notes)
            .then((res) => {
                if (res.status === 200) {
                    setMessageNotes(I18n.t('FORM_SAVE_SUCCESSFULLY'));
                } else {
                    setMessageNotes(I18n.t('FORM_SAVE_ERROR'));
                }
            })
            .catch((err) => setMessageNotes(I18n.t('FORM_SAVE_ERROR')));
    };

    const validPassport = () => {
        const validated = props.passportInfo.validated;
        const expiryDate = props.passportInfo.expiryDate;

        if (!validated) return false;
        if (expiryDate === null) return false;
        if (new Date(expiryDate) < new Date()) return false;
        return true;
    };

    const updateToInProgress = () => {
        setLoaderUpdate(true);
        APIManager.updateFormStatus(props.form.id, 1)
            .then((res) => {
                if (res.status === 200) {
                    props.updateDocument();
                } else {
                    setMessageNotes(I18n.t('FORM_SAVE_ERROR'));
                }
            })
            .catch((err) => setMessageNotes(I18n.t('FORM_SAVE_ERROR')))
            .finally(() => setLoaderUpdate(false));
    };

    return (
        <div className={`form-tax ${props.form.form_status.status === 'paid' && 'form-approved'}`}>
            <div className='col-12 d-flex p-0'>{getStatus()}</div>
            <div className='container'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='title-block mb-4 mx-5'>
                            {I18n.t('TAX_REFUNDS_OF')} {props.form.year}
                            <p>
                                {I18n.t('FORM_ID')} {props.form.id}
                            </p>
                        </div>
                        {props.form.form_status.id === 6 && (
                            <p>
                                {Validators.computedStringQuestionaire(text_form_approved_by_user, props.form.year, 0)}
                            </p>
                        )}
                    </div>
                </div>
                {props.form.form_status.id === 2 && (
                    <div className='row'>
                        <div className='col-12'>
                            <div className='mb-4 mx-5'>
                                <h5>{I18n.t('MESSAGE_STATUS_PENDING_PAYMENT')}</h5>
                                <Button defaultStyle title={I18n.t('FORM_PURCHASE')} onPress={purchaseForm} />
                            </div>
                        </div>
                    </div>
                )}
                {props.form.form_status.id === 4 && props.form.filled_tax_form !== null && (
                    <div className='mb-4 mx-5'>
                        <ModalRejectApprove
                            key={props.form.id * Math.random()}
                            closeModalRejectApprove={closeModalRejectApprove}
                            visible={isVisibleModalRejectApprove}
                            form={props.form}
                            mode={modeRejectApprove}
                            updateDocument={props.updateDocument}
                        />
                        <h5>{I18n.t('MESSAGE_STATUS_PENDING_APPROVEMENT')}</h5>
                        <a
                            className='link'
                            href={`https://beheer.taxt.nl/assets/${props.form.filled_tax_form.filename_disk}?download`}
                            download={props.form.filled_tax_form.filename_download}
                            target='_blank'
                        >
                            {props.form.filled_tax_form.filename_download}
                        </a>
                        <div className='row mt-4'>
                            <div className='col-12 col-lg-6 mb-2'>
                                <Button
                                    secondaryStyle
                                    fullwidth
                                    title={I18n.t('ACCOUNT_REJECT')}
                                    onPress={() => openModalRejectApprove('reject')}
                                />
                            </div>
                            <div className='col-12 col-lg-6'>
                                <Button
                                    defaultStyle
                                    fullwidth
                                    title={I18n.t('ACCOUNT_APPROVE')}
                                    onPress={() => openModalRejectApprove('approve')}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {props.form.form_status.id === 9 && (
                    <div className='mb-4 mx-5'>
                        <h5>{I18n.t('MESSAGE_STATUS_PENDING_PRICE')}</h5>
                    </div>
                )}

                {props.form.form_status.id === 10 && (
                    <div className='mb-4 mx-5'>
                        <h5>
                            {I18n.t('MESSAGE_STATUS_PRICE_APPROVAL')} {props.form.total_cost}.-
                        </h5>
                        <div className='row mt-4'>
                            <div className='col-12 col-lg-6 mb-2'>
                                {loaderUpdate ? (
                                    <Loader show={loaderUpdate} />
                                ) : (
                                    <Button
                                        secondaryStyle
                                        fullwidth
                                        title={I18n.t('ACCOUNT_CONTINUE_FORM')}
                                        onPress={updateToInProgress}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {![9, 10].includes(props.form.form_status.id) && (
                <>
                    <div className='mb-3 mx-3'>
                        {props.form.documents_required.map((d: any) => {
                            if (d === 1 && validPassport()) {
                                return;
                            }
                            return d === expandedItem ? (
                                <DocExpanded
                                    form={props.form}
                                    user={props.user}
                                    documents={getDocument(d)}
                                    fileType={props.fileTypes.find((file) => file.id === d)}
                                    expandItem={expandItem}
                                    updateDocument={props.updateDocument}
                                    confirmationDeleteDocument={props.confirmationDeleteDocument}
                                />
                            ) : (
                                <DocCollapsed
                                    year={props.form.year}
                                    amountByYear={props.form.answers_form.amountByYear}
                                    documents={getDocument(d)}
                                    fileType={props.fileTypes.find((file) => file.id === d)}
                                    expandItem={expandItem}
                                />
                            );
                        })}
                    </div>
                    <div className='row mx-3 mb-3 d-flex justify-content-end'>
                        <div className='container-notes col-12 col-lg-6'>
                            <TextInput
                                label={I18n.t('FORM_NOTES')}
                                value={notes}
                                error={false}
                                errorText={''}
                                textArea
                                numberofRows={5}
                                onChange={(val) => setNotes(val.target.value)}
                            />
                            {messageNotes !== '' && <p>{messageNotes}</p>}
                            <Button
                                defaultStyle
                                fullwidth
                                title={I18n.t('ACCOUNT_UPDATE_NOTES')}
                                onPress={updateNotes}
                            />
                        </div>
                    </div>
                </>
            )}

            {props.form.form_status.id === 1 && (
                <div className='row mx-3 mb-3 d-flex justify-content-end'>
                    <ModalFinishUpload
                        form={props.form}
                        closeModalFinishUpload={() => setIsVisibleModalFinishUpload(false)}
                        visible={isVisibleModalFinishUpload}
                        updateDocument={props.updateDocument}
                    />
                    <div className='col-12 col-lg-6'>
                        <Button
                            defaultStyle
                            fullwidth
                            title={I18n.t('ACCOUNT_FINISH_UPLOAD')}
                            onPress={() => setIsVisibleModalFinishUpload(true)}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default FormTax;
