import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { faqpage_blocks, getFaqpageBlocks } from '../../redux/webblocks';
import I18n from "../../helpers/i18n";
import '../../style/style.scss';
import API from '../../helpers/api';
import { faqpage } from '../../redux/pages';
import Question from './components/questions/question';
import Wrapper from '../../components/wrapper/wrapper';
import { initialPage, interfacePage } from '../../models/page';
import useGaTracker from '../../navigation/useGaTracker';

const APIManager = API.instance;

interface Props {}

const FaqPage: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);
    const [expandedItem, setExpandedItem] = useState(0);
    const PAGEFAQ = useSelector(faqpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);

    const BLOCK = useSelector(faqpage_blocks);

    useEffect(() => {
        setLoader(true);
        dispatch(getFaqpageBlocks())
    }, []);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGEFAQ)
    }, [PAGEFAQ]);
    

    const expandItem = (id: number) => {
        if(expandedItem == id){
            setExpandedItem(0);
        } else {
            setExpandedItem(id);
        }
    }

    useGaTracker();

    return (
        <Wrapper
            loader={loader}
            activePage={I18n.t('NAV_FAQ')}
            has_subtitle_banner
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title} 
            seo_description={PAGE.seo_description}
        >
            <section className="faq">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-10">
                            {BLOCK && BLOCK.map((item: any) => {
                                if(item.status === 'published'){
                                return (
                                    <Question 
                                        data={item} 
                                        expanded={expandedItem == item.id}
                                        expandItem={(id: any) => expandItem(id)}
                                    />
                                )}
                            })}
                        </div>
                    </div>
                </div>
            </section>

        </Wrapper>
    );
};

export default FaqPage;