import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import I18n from "../../helpers/i18n";
import renderHTML from 'react-render-html';

interface Props {
    subtitle: string
}

const SubtitlePage : FC<Props> = (props) => {
    return (
        <section className="container subtitle-page">
            <h2 className="h2">{ props.subtitle }</h2>
        </section>
    );
}
export default SubtitlePage;