import React, { FC, useEffect } from 'react';
import crypto from 'crypto'
import API from '../../../../helpers/api';

const APIManager = API.instance;
interface Props {
}

const ChatwootWidget : FC<Props> = (props) => {
    useEffect(() => {
        // @ts-ignore
        window.chatwootSettings = {
            hideMessageBubble: false,
            position: 'right', // This can be left or right
            locale: 'en', // Language to be set
            type: 'expanded_bubble', // [standard, expanded_bubble]
        };
        const BASE_URL = "https://app.chatwoot.com";
        const SRC = "/packs/js/sdk.js";

        // Paste the script from inbox settings except the <script> tag
        (function(d,t) {
            var g = d.createElement(t),s=d.getElementsByTagName(t)[0];
            // @ts-ignore
            g.src = BASE_URL+SRC;
            // @ts-ignore
            g.defer = true;
            // @ts-ignore
            g.async = true;
            // @ts-ignore
            s.parentNode.insertBefore(g,s);
            g.onload = function(){
            // @ts-ignore
                window.chatwootSDK.run({
                    websiteToken: 'C8MVtvqKaHriM6Y2H44GjNCT',
                    baseUrl: BASE_URL
                })
            }
        })(document,"script");

        return () => {
            removejscssfile(BASE_URL+SRC);
            removeElementsByClass('woot-widget-holder');
            removeElementsByClass('woot--bubble-holder');
        }
    }, [])

    const removejscssfile = (filename: string) => {
        var tags = document.getElementsByTagName('script');
        for (var i = tags.length; i >= 0; i--){ //search backwards within nodelist for matching elements to remove
            // @ts-ignore
        if (tags[i] && tags[i].getAttribute('src') !== null && tags[i].getAttribute('src').indexOf(filename) !== -1)
        // @ts-ignore
            tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
        }
    }

    function removeElementsByClass(className: string){
        const elements = document.getElementsByClassName(className);
        for (let index = 0; index < elements.length; index++) {
            if (window.location.pathname.includes('account')) {
                elements[0].classList.remove('d-none');
            } else {
                elements[0].classList.add('d-none');
            }
            
        }
    }

    useEffect(() => {
        const key = 'RRQkAvdKZhi7tHicSQq8urN4';
        window.addEventListener('chatwoot:ready', function () {
            APIManager.getProfile()
                .then((res) => {
                    if (res.status === 200) {
                        const {
                            id,
                            email,
                            first_name,
                            last_name
                        } = res.data.data
                        const hmac = crypto.createHmac('sha256', key).update(email).digest('hex');
                        // @ts-ignore
                        window.$chatwoot.setUser(id, {
                            name: `${first_name} ${last_name}`, // Name of the user
                            avatar_url: '', // Avatar URL
                            identifier_hash: hmac,
                            email: email, // Email of the user
                            phone_number: '', // Phone Number of the user
                        })
            
                        // @ts-ignore
                        window.$chatwoot.setCustomAttributes({
                            name: `${first_name} ${last_name}`,
                            email: email,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        });
    }, [])

    return null ;
}
export default ChatwootWidget;