import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from "../../helpers/i18n";
import Button from '../../components/button/button';
import API from '../../helpers/api';
import { homepage } from '../../redux/pages';
import { homepage_blocks, getHomepageBlocks } from '../../redux/webblocks';
import renderHTML from 'react-render-html';
import { Link, useHistory } from "react-router-dom";
import IconBlock from './icon_block/icon_block';
import Arrow from '../../images/arrow-blue.svg';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
import useGaTracker from '../../navigation/useGaTracker';

interface Props {
}

const HomePage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGEHOME = useSelector(homepage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);
    const history = useHistory();

    const BLOCK = useSelector(homepage_blocks);
    const navTo = () => {
        history.push('/');
    }

    useEffect(() => {
        setLoader(true);
        dispatch(getHomepageBlocks())
    }, []);

    useEffect(() => {
        SET_PAGE(PAGEHOME)
        setLoader(false);
    }, [PAGEHOME]);

    useGaTracker();
    
    return (
        <Wrapper
            loader={loader}
            activePage={''}
            circles_is_home_page
            banner_button_arrow={true}
            banner_full
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title} 
            seo_description={PAGE.seo_description}
        >
            <section className="homepage">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-9 p-0 mb-5">
                            <Replay 
                                source={`${process.env.PUBLIC_URL}/assets/video/Taxt_presentation.mp4`}
                                options={{ 
                                    interactionDetector: { 
                                        inactivityDelay: .5 
                                    },
                                    controls: {
                                        includeControls: [
                                            'playPauseButton',
                                            'timeline',
                                            'volume',
                                            'fullscreenButton',
                                          ],
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-9 p-0">
                            {BLOCK.title_block_1 !== undefined &&
                                <h2 className="h1  text-center">{BLOCK.title_block_1}</h2>
                            }
                            {BLOCK.text_block_1 &&
                                <span className="paragraph"> 
                                    {renderHTML(BLOCK.text_block_1)}
                                </span>
                            }
                        </div>
                        <div className="col-12 col-lg-10 p-0 first-block">
                            {BLOCK.photo_block_1 ?
                                <div 
                                    className="block-photo"
                                    style={{backgroundImage: `url(https://beheer.taxt.nl/assets/${BLOCK.photo_block_1})`}}
                                />
                                :
                                <div className="mb-5"/>
                            }
                            {BLOCK.button_block_1_link &&
                                <div className="button-container d-flex justify-content-center d-lg-block">
                                    <Button
                                        defaultStyle 
                                        showArrow={true}
                                        title={BLOCK.button_block_1_text} 
                                        path={BLOCK.button_block_1_link}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="container second-block">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-9 text-center">
                            {BLOCK.title_block_2 &&
                                <h2 className="h1 title">{BLOCK.title_block_2}</h2>
                            }
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <IconBlock
                            iconImage={BLOCK.icon_1_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_1_image}`}
                            iconNumber={1}
                            title={BLOCK.icon_1_title && BLOCK.icon_1_title}
                            text={BLOCK.icon_1_text && BLOCK.icon_1_text}
                        />
                        <IconBlock
                            iconImage={BLOCK.icon_3_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_2_image}`}
                            iconNumber={2}
                            title={BLOCK.icon_2_title && BLOCK.icon_2_title}
                            text={BLOCK.icon_2_text && BLOCK.icon_2_text}
                        />
                        <IconBlock
                            iconImage={BLOCK.icon_3_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_3_image}`}
                            iconNumber={3}
                            title={BLOCK.icon_3_title && BLOCK.icon_3_title}
                            text={BLOCK.icon_3_text && BLOCK.icon_3_text}
                        />
                        <IconBlock
                            iconImage={BLOCK.icon_4_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_4_image}`}
                            iconNumber={4}
                            title={BLOCK.icon_4_title && BLOCK.icon_4_title}
                            text={BLOCK.icon_4_text && BLOCK.icon_4_text}
                        />
                    </div>
                </div>
                
                <div className="container third-block">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-lg-4 p-0">
                            {BLOCK.title_block_3 &&
                                <h2 className="title h1">{BLOCK.title_block_3}</h2>
                            }
                            {BLOCK.text_block_3 &&
                                <span className="text">
                                    {renderHTML(BLOCK.text_block_3.substring(0, 420) + '...')}
                                </span>
                            }

                            <Link to={{pathname: '/about-us', state: {}}} className="read-more-button">
                                {I18n.t('READ_MORE')}
                                <img src={Arrow} className="arrow" alt="Arrow"/>
                            </Link>
                        </div>
                        {BLOCK.block_3_photo&&
                            <div className="col-12 col-lg-5 p-0 image-container">
                                <div style={{backgroundImage: `url(https://beheer.taxt.nl/assets/${BLOCK.block_3_photo})`}} className="block-image"/>
                                {BLOCK.button_block_3_link &&
                                    <div className="button-container d-flex justify-content-center d-lg-block">
                                        <Button 
                                            defaultStyle 
                                            showArrow 
                                            title={BLOCK.button_block_3_text} 
                                            path={BLOCK.button_block_3_link}
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

export default HomePage;