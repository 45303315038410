import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import AccountInformationForm from '../../components/account_information_form/account_information_form';

interface Props {
    disabledNextStep: any;
}

const AccountCreation: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);

    return (
        <div className='container'>
            <div className='row d-flex justify-content-center align-items-center flex-column'>
                <div className='col-12 col-lg-8 p-lg-0'>
                    <AccountInformationForm disabledNextStep={props.disabledNextStep} />
                </div>
                <div className='col-12 col-lg-3 p-lg-0 d-none d-lg-flex position-relative'></div>
            </div>
        </div>
    );
};

export default AccountCreation;
