import React, { useState, FC, useEffect } from 'react';
import renderHTML from 'react-render-html';
import I18n from '../../../../helpers/i18n';
import '../../../../style/style.scss';
import InputAddress from '../input_address/input_address';
import ImageQuestion from '../../../../images/question.svg';
import ModalDescription from '../modal_description/modal_description';
import FUNCTIONS from '../../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, setQuestionsNonResident, setYear } from '../../../../redux/questionnaire';

const FunctionsManager = FUNCTIONS.instance;
interface Props {
    question: any;
    answers: any;
}

const calculateDefaultValue = (d: any) => {
    const question = d.questionsNonResident.find((q: any) => q.question.id === 14);
    if (question) {
        return question.answer.id;
    } else {
        return 0;
    }
};

const QuestionNonResident: FC<Props> = (props) => {
    const DATA_USER = useSelector(dataUser);
    const dispatch = useDispatch();
    const [defaultValue, setDefaultValue] = useState<number>(calculateDefaultValue(DATA_USER));
    const [visibleSelectYear, setVisibleSelect] = useState(true);
    const [isVisibleModalDescription, setIsVisibleModalDescription] = useState(false);
    const [coOwners, setCoOwners] = useState<Array<number>>([1]);

    useEffect(() => {
        if (DATA_USER.questionsNonResident.find((q: any) => q.question.id === 19)) {
            const owners = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 19).answer.answer;
            const arr = Array.from(owners.replace(/,/g, ''));
            setCoOwners(arr.map((a: any) => parseInt(a)));
        } else if (DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14)) {
            const quantity = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14).answer.id;
            setCoOwners([...Array(quantity)].fill(1));
        }
    }, []);

    const toggleCheck = (idAnswer: number, valueAnswer: string, amount1: number, amount2: number) => {
        const idQuestion = props.question.id;
        let amount = 0;

        // Exception if question answer is inserted
        const found = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14 && q.answer.id === idAnswer);
        if (found && found.question.id === idQuestion) {
            return;
        }

        if (idQuestion === 17) {
            if (idAnswer === 2) {
                const addresses = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14).answer.id;
                const addAddresses = addresses <= 2 ? 0 : addresses - 2;
                amount = amount1 * addAddresses;
            } else {
                amount = amount2;
            }
        }

        if (idQuestion === 19) {
            if (coOwners.length === 0) return;
            // const total = coOwners.reduce((a: number, b: number) => a + b);
            const sorted = [...coOwners].sort((a: number, b: number) => (a < b ? 1 : -1));
            const addresses = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14).answer.id;
            const addAddresses = addresses <= 2 ? 0 : addresses - 2;
            const totalCoOwners = sorted[0] > 1 ? sorted[0] : 2;
            amount = amount * addAddresses - amount1 + (totalCoOwners - 1) * (DATA_USER.basePrice - amount1);
        }

        const questionInsert = {
            question: {
                id: idQuestion,
                question: props.question.question,
                order: props.question.order,
            },
            answer: {
                id: idAnswer,
                answer: valueAnswer,
                amount: amount,
            },
        };

        const insert = DATA_USER.questionsNonResident.filter((q: any) => {
            return q.question.order < questionInsert.question.order;
        });

        dispatch(setQuestionsNonResident([...insert, questionInsert]));
    };

    const changeAddress = (quantity: number, focus: boolean) => {
        if (focus) {
            toggleCheck(quantity, `${quantity} addresses`, props.question.amount_1, props.question.amount_2);
        }
    };

    const changeYear = (e: any) => {
        setVisibleSelect(false);
        dispatch(setYear(parseInt(e.target.value)));
    };

    const selectAnswer = () => {
        if (props.question.id === 3) {
            return renderYears();
        } else if (props.question.id === 14) {
            return displayAddresses();
        } else if (props.question.id === 19) {
            return displayCoOwners();
        } else {
            return renderAnswers();
        }
    };

    const renderYears = () => {
        let years = [];
        const numberOfYears = 5;
        const startYear = new Date().getFullYear() - 1;
        for (let index = 0; index < numberOfYears; index++) {
            years.push(startYear - index);
        }

        return (
            <select id='filetype' value={DATA_USER.year} onChange={changeYear}>
                {visibleSelectYear && <option value={0}>{I18n.t('FORM_SELECT_YEAR')}</option>}
                {years.map((year: any) => {
                    return (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    );
                })}
                {/* <option key={1970} value={1970}>{`${I18n.t('FORM_BEFORE')} ${startYear - 4}`}</option> */}
            </select>
        );
    };

    const displayAddresses = () => {
        return (
            <div className='d-flex flex-column flex-lg-row gap-2 col-12 col-md-6 col-lg-12 m-auto mt-4'>
                <InputAddress onChange={changeAddress} defaultQuantity={defaultValue} />
            </div>
        );
    };

    const renderAnswers = () => {
        return props.answers.map((answer: any) => {
            const selected = DATA_USER.questionsNonResident.find((object: any) => {
                return object.question.id === props.question.id && object.answer.id === answer.id;
            });
            return (
                <span
                    className={`button ${selected ? 'blue' : 'secondary'}`}
                    key={answer.id}
                    onClick={() =>
                        toggleCheck(answer.id, answer.answer, props.question.amount_1, props.question.amount_2)
                    }
                >
                    {answer.answer}
                </span>
            );
        });
    };

    const displayCoOwners = () => {
        const quantity = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14).answer.id;
        return (
            <div>
                {[...Array(quantity)].map((e, i) => {
                    return (
                        <div className='input-co' key={i}>
                            <span>{`${I18n.t('ADDRESS_NUMBER')} ${i + 1}`}</span>
                            <InputAddress onChange={changeCoOwners} defaultQuantity={coOwners[i]} index={i} />
                        </div>
                    );
                })}
            </div>
        );
    };

    const changeCoOwners = (quantity: number, focus: boolean, key: number) => {
        if (focus) {
            let newCoOwners = [...coOwners];
            newCoOwners[key] = quantity;
            setCoOwners(newCoOwners);
        }
    };

    useEffect(() => {
        if (props.question.id === 19) {
            toggleCheck(19, coOwners.join(), props.question.amount_1, props.question.amount_2);
        }
    }, [coOwners]);

    const closeModalDescription = () => {
        setIsVisibleModalDescription(false);
    };

    return (
        <div className='question-container' id={`question-${props.question.id}`}>
            {typeof props.question.description === 'string' && props.question.description !== '' && (
                <ModalDescription
                    closeModalDescription={closeModalDescription}
                    visible={isVisibleModalDescription}
                    // @ts-ignore: Unreachable code error
                    text={renderHTML(
                        FunctionsManager.computedStringQuestionaire(
                            props.question.description,
                            DATA_USER.year,
                            +DATA_USER.amountByYear
                        )
                    )}
                />
            )}
            <span className='question'>
                {renderHTML(
                    FunctionsManager.computedStringQuestionaire(
                        props.question.question,
                        DATA_USER.year,
                        +DATA_USER.amountByYear
                    )
                )}
            </span>
            {typeof props.question.description === 'string' && props.question.description !== '' && (
                <img
                    className='button-question'
                    src={ImageQuestion}
                    alt='description-question'
                    onClick={() => setIsVisibleModalDescription(true)}
                />
            )}
            <div className='d-flex flex-column flex-lg-row gap-2 col-12 col-md-6 col-lg-12 m-auto mt-4'>
                {selectAnswer()}
            </div>
        </div>
    );
};

export default QuestionNonResident;
