import React, { FC } from 'react';
import DocumentMeta from 'react-document-meta';
import I18n from "../../helpers/i18n";
import CirclesBackground from '../circles_background/circles_background';
import Header from '../header/header';
import PageBanner from '../page_banner/page_banner';
import BottomBanner from '../bottom_banner/bottom_banner';
import Loader from '../loader/loader';
import SubtitlePage from '../subtitle_page/subtitle_page';
import Footer from '../footer/footer';
import { interfacePage } from '../../models/page';
import { useSelector } from 'react-redux';
import { login_status } from '../../redux/auth';

interface Props {
    loader: boolean,
    activePage?: string,
    page: interfacePage,
    has_title?: boolean, // Use for the pages without banner but with title in black
    banner_full?: boolean, // Size of the banner
    banner_small?: boolean, // Size of the banner
    banner_subtitle_big?: boolean, // In the homepage the subtitle is bigger than other pages
    banner_button_arrow?: boolean,
    has_subtitle_banner?: boolean, // Some pages have a subtitle in blue after the banner
    bottom_banner_button_arrow?: boolean,
    circles_is_request_page?: boolean,
    circles_is_home_page?: boolean,
    seo_title?: string,
    seo_description?: string
}

const Wrapper : FC<Props> = (props) => {
    const loginStatus = useSelector(login_status);

    const meta = {
        title: props.page.seo_title ? props.page.seo_title : I18n.t('DEFAULT_SEO_TITLE'),
        description: props.page.seo_description ? props.page.seo_description : I18n.t('DEFAULT_SEO_DESCRIPTION'),
        canonical: window.location.href,
        meta: {
            charset: 'utf-8',
        }
    };

    return (
        <DocumentMeta {...meta}>
            {props.loader ?
                <div className="container-loader">
                    <Loader show={props.loader} />
                </div>
            :
                <div>
                    {props.page.show_page_banner ? <Header float lightText activePage={props.activePage}/> : <Header activePage={props.activePage}/>}

                    <CirclesBackground
                        isRequestPage={props.circles_is_request_page}
                        isHomePage={props.circles_is_home_page}/>

                    {
                        props.page.show_page_banner &&
                            <PageBanner
                                full={props.banner_full}
                                small={props.banner_small}
                                photo={`https://beheer.taxt.nl/assets/${props.page.banner_photo.filename_disk}`}
                                title={props.page.banner_title}
                                subTitle={props.page.banner_subtitle}
                                subTitleBig={props.banner_subtitle_big}
                                buttonUrl={props.page.banner_button_link}
                                buttonText={props.page.banner_button_text}
                                buttonArrow={props.banner_button_arrow}
                                bottomText={props.page.banner_bottom_text}
                                />
                    }

                    {props.has_subtitle_banner  && props.page.title !== '' && <SubtitlePage subtitle={props.page.title} />}

                    {props.has_title && props.page.title !== '' && <PageBanner title={props.page.title} titleBlue small />}

                    {props.children}

                    {
                        props.page.show_bottom_banner &&
                            <BottomBanner
                                title={
                                    loginStatus === 'success' ? 
                                    props.page.bottom_banner_title : 
                                        props.page.bottom_banner_title_logged !== null ? 
                                        props.page.bottom_banner_title_logged : 
                                        props.page.bottom_banner_title
                                    }
                                buttonText={props.page.button_text}
                                buttonArrow={props.bottom_banner_button_arrow}
                                buttonUrl={props.page.button_link}
                            />
                    }
                    <Footer />
                </div> 
            }
        </DocumentMeta>
    );
}
export default Wrapper;