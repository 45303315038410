import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';
import { dataUser } from '../../../../redux/questionnaire';
interface Props {
    registerUser: any;
    activeStep: number;
    disableNextStep: boolean;
    setActiveStep: (val: number) => void;
}

const RequestFooter: FC<Props> = (props) => {
    const DATA_USER = useSelector(dataUser);
    const [loader, setLoader] = useState(false);

    const nextStep = () => {
        if (props.activeStep === 4) {
            if (DATA_USER.accountInfo.email !== '' && DATA_USER.accountInfo.password !== '') {
                props.registerUser(DATA_USER.accountInfo);
            }
        } else if (props.activeStep === 2 && DATA_USER.selectedWay === 2) {
            props.setActiveStep(4);
        } else {
            props.setActiveStep(props.activeStep + 1);
        }
    };

    const prevStep = () => {
        if (props.activeStep === 4 && DATA_USER.selectedWay === 2) {
            props.setActiveStep(2);
        } else {
            props.setActiveStep(props.activeStep - 1);
        }
    };

    return (
        <div className='container'>
            <div className='row d-flex flex-column justify-content-center align-items-center'>
                <div className='col-12 col-lg-8 p-lg-0'>
                    <div className='request-footer d-flex justify-content-between flex-column flex-lg-row gap-2'>
                        {props.activeStep === 1 ? (
                            <div></div>
                        ) : (
                            <div className='btn-container'>
                                <Button
                                    defaultStyle
                                    fullwidth
                                    reverseArrow
                                    title={'Previous'}
                                    onPress={() => prevStep()}
                                />
                            </div>
                        )}
                        <div className='btn-container'>
                            <Button
                                defaultStyle={props.activeStep !== 4}
                                greenStyle={props.activeStep === 4}
                                fullwidth
                                showArrow
                                disabled={props.disableNextStep}
                                title={props.activeStep === 4 ? 'Finish request' : 'Next'}
                                onPress={() => nextStep()}
                            />
                        </div>
                    </div>
                </div>

                <div className='col-12 col-lg-3 p-lg-0 d-none d-lg-flex'></div>
            </div>
        </div>
    );
};

export default RequestFooter;
