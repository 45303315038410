import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Helpers from '../../../../helpers/functions';
import TextInput from '../../../../components/textinput/textinput';
import Loader from '../../../../components/loader/loader';
import API from '../../../../helpers/api';
import useDebounce from '../../../../hooks/use-debounce';
import DateInput from '../../../../components/dateinput/dateinput';
import moment from 'moment';
import countryList from 'react-select-country-list';

const Validators = Helpers.instance;
const APIManager = API.instance;

interface Props {}

const PersonalInformationForm: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const [hasErrorForm, setHasErrorForm] = useState(false);

    const [id, setId] = useState(0);

    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');

    const [birthdate, setBirthdate] = useState('');
    const [birthdateError, setBirthdateError] = useState(false);
    const [birthdateErrorText, setBirthdateErrorText] = useState('');

    const [zipCode, setZipCode] = useState('');
    const [zipCodeError, setZipCodeError] = useState(false);
    const [zipCodeErrorText, setZipCodeErrorText] = useState('');

    const [houseNumber, setHouseNumber] = useState('');
    const [houseNumberError, setHouseNumberError] = useState(false);
    const [houseNumberErrorText, setHouseNumberErrorText] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [country, setCountry] = useState('');
    const countries = countryList().getData();

    const [messageUpdate, setMessageUpdate] = useState('');

    useEffect(() => {
        getProfile();
    }, []);

    const checkForm = () => {
        if (
            firstName !== '' &&
            !firstNameError &&
            lastName !== '' &&
            !lastNameError &&
            zipCode !== '' &&
            !zipCodeError &&
            houseNumber !== '' &&
            !houseNumberError &&
            address !== '' &&
            !addressError &&
            city !== '' &&
            !cityError
        ) {
            return true;
        }
    };

    useEffect(() => {
        checkForm() ? setHasErrorForm(true) : setHasErrorForm(false);
    }, [checkForm()]);

    const debouncedFirstNameTerm = useDebounce(firstName, 400);
    useEffect(() => {
        if (firstName !== '') {
            let valField = Validators.validateString(firstName);
            setFirstNameError(valField[0]);
            setFirstNameErrorText(valField[1]);
        }
    }, [debouncedFirstNameTerm]);

    const debouncedLastNameTerm = useDebounce(lastName, 400);
    useEffect(() => {
        if (lastName !== '') {
            let valField = Validators.validateString(lastName);
            setLastNameError(valField[0]);
            setLastNameErrorText(valField[1]);
        }
    }, [debouncedLastNameTerm]);

    const debouncedZipCodeTerm = useDebounce(zipCode, 1000);
    useEffect(() => {
        if (zipCode !== '') {
            let valField = Validators.validateZipCode(zipCode);
            setZipCodeError(valField[0]);
            setZipCodeErrorText(valField[1]);
            // checkFullDirection();
        }
    }, [debouncedZipCodeTerm]);

    const debouncedHouseNumberTerm = useDebounce(houseNumber, 1000);
    useEffect(() => {
        if (houseNumber !== '') {
            let valField = Validators.validateHouseNumber(houseNumber);
            setHouseNumberError(valField[0]);
            setHouseNumberErrorText(valField[1]);
            checkFullDirection();
        }
    }, [debouncedHouseNumberTerm]);

    const checkFullDirection = async () => {
        if (country !== 'NL') {
            setAddressError(false);
            setZipCodeError(false);
            setHouseNumberError(false);
            return;
        }
        if (!Validators.validateZipCode(zipCode)[0] && !Validators.validateHouseNumber(houseNumber)[0]) {
            setLoader(true);
            await APIManager.getAddressFromPostcode(zipCode, houseNumber)
                .then((res) => {
                    if (res.status === 200) {
                        setAddressError(false);
                        setZipCodeError(false);
                        setHouseNumberError(false);
                        setAddress(res.data.street);
                        setCity(res.data.city);
                    } else {
                        setZipCodeError(true);
                        setHouseNumberError(true);
                        setZipCodeErrorText(I18n.t('FORM_ERROR_ADDRESS_NOT_FOUND'));
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    setZipCodeError(true);
                    setHouseNumberError(true);
                    setZipCodeErrorText(I18n.t('FORM_ERROR_ADDRESS_NOT_FOUND'));
                    setLoader(false);
                });
        }
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (
            firstName !== '' &&
            !firstNameError &&
            lastName !== '' &&
            !lastNameError &&
            birthdate !== '' &&
            !birthdateError &&
            zipCode !== '' &&
            !zipCodeError &&
            houseNumber !== '' &&
            !houseNumberError &&
            address !== '' &&
            !addressError &&
            city !== '' &&
            !cityError
        ) {
            updateProfile();
        }
    };

    const updateProfile = () => {
        APIManager.updateProfile(id, firstName, lastName, birthdate, zipCode, houseNumber, address, city, country)
            .then((res) => {
                if (res === 200) {
                    setMessageUpdate(I18n.t('FORM_SAVE_SUCCESSFULLY'));
                } else {
                    setMessageUpdate(I18n.t('FORM_SAVE_ERROR'));
                }
            })
            .catch((err) => {
                setMessageUpdate(I18n.t('FORM_SAVE_ERROR'));
            });
    };

    const getProfile = () => {
        APIManager.getProfile()
            .then((res) => {
                if (res.status === 200) {
                    const {
                        id,
                        first_name,
                        last_name,
                        zipcode,
                        house_number,
                        address,
                        location,
                        date_of_birth,
                        country,
                    } = res.data.data;
                    setId(id);
                    setFirstName(first_name);
                    setLastName(last_name);
                    setBirthdate(date_of_birth);
                    setZipCode(zipcode);
                    setHouseNumber(house_number);
                    setAddress(address);
                    setCity(location);
                    setCountry(country);
                }
            })
            .catch((err) => {
                setMessageUpdate(I18n.t('FORM_SAVE_ERROR'));
            });
    };

    return (
        <div className='personal-information-form'>
            <span className='title'>{I18n.t('PERSONAL_INFORMATION_TITLE')}</span>
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <TextInput
                            label={`${I18n.t('FORM_FIRSTNAME')}`}
                            value={firstName}
                            error={firstNameError}
                            errorText={firstNameErrorText}
                            onChange={(val) => setFirstName(val.target.value)}
                            border
                        />
                    </div>
                    <div className='col-12 col-md-6'>
                        <TextInput
                            label={`${I18n.t('FORM_LASTNAME')}`}
                            value={lastName}
                            error={lastNameError}
                            errorText={lastNameErrorText}
                            onChange={(val) => setLastName(val.target.value)}
                            border
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4'>
                        <DateInput
                            label={`${I18n.t('ACCOUNT_BIRTHDAY')}`}
                            value={birthdate}
                            error={birthdateError}
                            errorText={birthdateErrorText}
                            onChange={(val) => setBirthdate(val.target.value)}
                            border
                            maxDate={moment().format('YYYY-MM-DD')}
                        />
                    </div>
                    <div className='col-12 col-md-4'>
                        <TextInput
                            label={`${I18n.t('FORM_ZIP')}`}
                            value={zipCode}
                            error={zipCodeError}
                            errorText={zipCodeErrorText}
                            onChange={(val) => setZipCode(val.target.value)}
                            border
                        />
                    </div>
                    <div className='col-12 col-md-4'>
                        <TextInput
                            label={`${I18n.t('FORM_HOUSE_NUMBER')}`}
                            value={houseNumber}
                            error={houseNumberError}
                            errorText={houseNumberErrorText}
                            onChange={(val) => setHouseNumber(val.target.value)}
                            border
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 container-loader-input-personal'>
                        <Loader show={loader} />
                        <TextInput
                            label={`${I18n.t('FORM_ADDRESS')}`}
                            value={address}
                            error={addressError}
                            errorText={addressErrorText}
                            onChange={(val) => setAddress(val.target.value)}
                            border
                        />
                    </div>
                    <div className='col-12 container-loader-input-personal'>
                        <Loader show={loader} />
                        <TextInput
                            label={`${I18n.t('FORM_CITY')}`}
                            value={city}
                            error={cityError}
                            errorText={cityErrorText}
                            onChange={(val) => setCity(val.target.value)}
                            border
                        />
                    </div>
                    {country !== null && country !== 'NL' && country !== '' && (
                        <div className='col-12'>
                            <select
                                disabled
                                className='country-selector'
                                id='country'
                                value={country}
                                onChange={(e: any) => {
                                    setCountry(e.target.value);
                                }}
                            >
                                {countries.map((country: any) => {
                                    return (
                                        <option key={country.value} value={country.value}>
                                            {country.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                    <div className='container-save'>
                        <button disabled={!hasErrorForm}>{I18n.t('FORM_SAVE_INFORMATION')}</button>
                    </div>
                    <div className='container-message'>{messageUpdate !== '' && <p>{messageUpdate}</p>}</div>
                </div>
            </form>
        </div>
    );
};

export default PersonalInformationForm;
