import React, { useState, FC, useEffect } from 'react';
import { Link } from "react-router-dom";
import '../../style/style.scss';
import MobileMenu from './menu/menu';
import API from '../../helpers/api';
import I18n from '../../helpers/i18n';
import Button from '../../components/button/button';
import AccountIconLight from '../../images/account_light.png';
import AccountIconDark from '../../images/account_dark.png';
import LogoLight from '../../images/taxt-white.svg'
import LogoDark from '../../images/taxt-black.svg'
import ModalLogOut from './modal_logout/modal_logout';
import { useSelector } from 'react-redux';
import { login_status } from '../../redux/auth';

const APIManager = API.instance;

interface Props {
    searchbar?: boolean,
    noBar?: boolean,
    triggerSearch?: () => void,
    experiencePage?: boolean,
    activePage?: string,

    lightText?: boolean, // text color light, if not will use default dark text
    float?: boolean //overlay content of not
}

const Header : FC <Props> = (props) => {  
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [isVisibleModalLogout, setIsVisibleModalLogout] = useState(false);
    const loginStatus = useSelector(login_status);
    
    useEffect(() => {
        let isMounted = true; 
        APIManager.isUserLoggedIn().then( (loggedIn) => {
            isMounted && setLoggedIn(loggedIn);
        });
        return () => { isMounted = false };
    }, [loginStatus])

    const menuItems = [
        {
            title: I18n.t('NAV_REQUEST'),
            url: '/request'
        },
        {
            title: I18n.t('NAV_FAQ'),
            url: '/faq'
        },
        {
            title: I18n.t('NAV_ABOUT'),
            url: '/about-us'
        }
    ]

    const openModalLogOut = () => {
        setIsVisibleModalLogout(true);
    }

    const closeModalLogOut = () => {
        setIsVisibleModalLogout(false);
    }

    const getSideNavigation = () => {
        return (
            <div className="navigation-account">
                <ModalLogOut
                    closeModalLogOut={closeModalLogOut}
                    visible={isVisibleModalLogout} />
                {/* desktop */}
                <div className="d-none d-lg-flex justify-content-end align-items-center">
                    {loggedIn ?
                        <div>
                            <a className={'menu-item-container link-router'} onClick={openModalLogOut}>
                                <div className="menu-item">
                                    {I18n.t('ACCOUNT_LOGOUT')}
                                </div>
                            </a>
                            <Link to={{pathname: '/account', state: {}}} className="menu-item-container link-router">
                                <div className="menu-item m-0">
                                    {I18n.t('NAV_ACCOUNT')}
                                </div>
                                {(props.activePage && props.activePage === I18n.t('NAV_ACCOUNT')) &&
                                    <span className="active-indicator indicator-my-account"/>}
                            </Link>
                        </div>
                    :
                        <Link to={{pathname: '/sign-in', state: {}}} className="d-flex align-items-center link-router">
                            <div className="menu-item">
                                {I18n.t('NAV_SIGNIN')}
                            </div>
                            <Button defaultStyle title={I18n.t('NAV_SIGNUP')} path="/request"/>
                        </Link>
                    }
                </div>
                {/* mobile */}
                <div className="d-flex justify-content-end align-items-center d-lg-none">
                    <Link to={{pathname: '/account', state: {}}} className="link-router">
                        <div className="mobile-item">
                            <img src={props.lightText ? AccountIconLight : AccountIconDark} alt={I18n.t('NAV_ACCOUNT')} className="account-icon"/>
                        </div>
                    </Link>
                    <MobileMenu
                        items={menuItems}
                        activePage={props.activePage}
                        light={props.lightText} 
                        openModalLogOut={openModalLogOut} />
                </div>
            </div>
        )
    }
    
    const getStyle = () => {
        var style = "";

        if(props.float){
            style = style + " float";
        }
        if(props.lightText){
            style = style + " light";
        }
        return style;
    }

    return (
        <header className={getStyle()}>
            <div className="container p-0">
                <div className="row navigation">
                    <div className="col-7 col-lg-8 d-flex align-items-center p-0">
                        <Link to={{ pathname: '/', state: {} }} className="logo-text link-router">
                            {
                                props.lightText ?
                                <img src={LogoLight} alt={I18n.t('SITENAME')} /> :
                                <img src={LogoDark} alt={I18n.t('SITENAME')} />
                            }
                        </Link>
                        <div className="d-none d-lg-block">
                            {menuItems.map((item, key) => {
                                return (
                                    <Link
                                        to={{ pathname: item.url, state: {} }}
                                        className="menu-item-container link-router"
                                        key={item.title}>
                                        <div className={(props.activePage && props.activePage === item.title) ? "menu-item active" : "menu-item"}>
                                            {item.title}
                                        </div>
                                        {(props.activePage && props.activePage === item.title) &&
                                            <span className="active-indicator"/>
                                        }
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                    <div className="col-5 col-lg-4 p-0">
                        {getSideNavigation()}
                    </div>
                </div>
            </div>
        </header>
    );
}
export default Header;