import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../button/button';
import { doLogout } from '../../../redux/auth';

interface Props {
    closeModalLogOut: any;
    visible: boolean;
}

const ModalLogOut: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const doLogoutConfirmation = () => {
        dispatch(doLogout());
        if (window.location.href === 'http://localhost:3000/' || window.location.href === 'https://taxt.nl') {
            window.location.reload();
        } else {
            history.push('/');
        }
    };

    return (
        <div className={`modal-logout ${props.visible ? 'visible-modal' : ''}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <h3>{I18n.t('ACCOUNT_MODAL_CONFIRMATION')}</h3>
                    </div>
                    <div className="modal-footer">
                        <div onClick={props.closeModalLogOut}>
                            <Button isButton title={I18n.t('ACCOUNT_MODAL_CLOSE')} />
                        </div>
                        <div onClick={doLogoutConfirmation}>
                            <Button isButton defaultStyle title={I18n.t('ACCOUNT_LOGOUT')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ModalLogOut;
