import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Helpers from '../../../../helpers/functions';
import TextInput from '../../../../components/textinput/textinput';
import Loader from '../../../../components/loader/loader';
import CheckBox from '../../../../components/checkbox/checkbox';
import useDebounce from '../../../../hooks/use-debounce';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, setAccountInfo } from '../../../../redux/questionnaire';
import Tooltip from '../../../../components/tooltip/tooltip';
import API from '../../../../helpers/api';
import DateInput from '../../../../components/dateinput/dateinput';
import moment from 'moment';
import countryList from 'react-select-country-list';

const Validators = Helpers.instance;
const APIManager = API.instance;

interface Props {
    disabledNextStep: any;
}

const AccountInformationForm: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const DATA_USER = useSelector(dataUser);
    const [loader, setLoader] = useState(false);

    const [firstName, setFirstName] = useState(DATA_USER.accountInfo.firstName);
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');

    const [lastName, setLastName] = useState(DATA_USER.accountInfo.lastName);
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');

    const [birthdate, setBirthdate] = useState(DATA_USER.accountInfo.birthdate);

    const [zipCode, setZipCode] = useState(DATA_USER.accountInfo.zipCode);
    const [zipCodeError, setZipCodeError] = useState(false);
    const [zipCodeErrorText, setZipCodeErrorText] = useState('');

    const [houseNumber, setHouseNumber] = useState(DATA_USER.accountInfo.houseNumber);
    const [houseNumberError, setHouseNumberError] = useState(false);
    const [houseNumberErrorText, setHouseNumberErrorText] = useState('');

    const [address, setAddress] = useState(DATA_USER.accountInfo.address);
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [city, setCity] = useState(DATA_USER.accountInfo.city);
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [email, setEmail] = useState(DATA_USER.accountInfo.email);
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState(DATA_USER.accountInfo.password);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState(DATA_USER.accountInfo.confirmPassword);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [acceptedTermsError, setAcceptedTermsError] = useState(false);
    const [acceptedTermsErrorText, setAcceptedTermsErrorText] = useState('');

    const [livingOutside, setLivingOutside] = useState(false);
    const [country, setCountry] = useState(DATA_USER.accountInfo.country);
    const countries = countryList().getData();

    const checkForm = () => {
        if (
            firstName !== '' &&
            !firstNameError &&
            lastName !== '' &&
            !lastNameError &&
            birthdate !== '' &&
            zipCode !== '' &&
            !zipCodeError &&
            houseNumber !== '' &&
            !houseNumberError &&
            address !== '' &&
            !addressError &&
            city !== '' &&
            !cityError &&
            email !== '' &&
            !emailError &&
            password !== '' &&
            !passwordError &&
            confirmPassword !== '' &&
            !confirmPasswordError &&
            acceptedTerms
        ) {
            return true;
        }
    };

    useEffect(() => {
        if (checkForm()) {
            props.disabledNextStep(false);
        } else {
            props.disabledNextStep(true);
        }
    }, [
        checkForm(),
        firstName,
        lastName,
        birthdate,
        zipCode,
        houseNumber,
        address,
        city,
        email,
        password,
        confirmPassword,
        acceptedTerms,
    ]);

    useEffect(() => {
        const user = {
            firstName: firstName,
            lastName: lastName,
            birthdate: birthdate,
            zipCode: zipCode,
            houseNumber: houseNumber,
            address: address,
            city: city,
            email: email,
            password: password,
            confirmPassword: confirmPassword,
            acceptedTerms: acceptedTerms,
            country: country,
        };
        dispatch(setAccountInfo(user));
    }, [
        firstName,
        lastName,
        birthdate,
        zipCode,
        houseNumber,
        address,
        city,
        email,
        password,
        confirmPassword,
        acceptedTerms,
    ]);

    useEffect(() => {
        props.disabledNextStep(true);
    }, []);

    const debouncedFirstNameTerm = useDebounce(firstName, 400);
    useEffect(() => {
        if (firstName !== '') {
            let valField = Validators.validateString(firstName);
            setFirstNameError(valField[0]);
            setFirstNameErrorText(valField[1]);
        }
    }, [debouncedFirstNameTerm]);

    const debouncedLastNameTerm = useDebounce(lastName, 400);
    useEffect(() => {
        if (lastName !== '') {
            let valField = Validators.validateString(lastName);
            setLastNameError(valField[0]);
            setLastNameErrorText(valField[1]);
        }
    }, [debouncedLastNameTerm]);

    const debouncedZipCodeTerm = useDebounce(zipCode, 400);
    useEffect(() => {
        if (zipCode !== '' && !livingOutside) {
            let valField = Validators.validateZipCode(zipCode);
            setZipCodeError(valField[0]);
            setZipCodeErrorText(valField[1]);
            if (!valField[0]) {
                //checkFullDirection();
            }
        }
    }, [debouncedZipCodeTerm]);

    const debouncedHouseNumberTerm = useDebounce(houseNumber, 400);
    useEffect(() => {
        if (houseNumber !== '') {
            let valField = Validators.validateHouseNumber(houseNumber);
            setHouseNumberError(valField[0]);
            setHouseNumberErrorText(valField[1]);
            if (!valField[0]) {
                checkFullDirection();
            }
        }
    }, [debouncedHouseNumberTerm]);

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    const debouncedPasswordTerm = useDebounce(password, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            let valField = Validators.validateRegisterPassword(password);
            setPasswordError(valField[0]);
            setPasswordErrorText(valField[1]);

            if (confirmPassword !== '' && password !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorText(I18n.t('FORM_ERROR_PASSWORD_NOT_SAME'));
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordErrorText('');
            }
        }
    }, [debouncedPasswordTerm]);

    const debouncedConfirmPasswordTerm = useDebounce(confirmPassword, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');

            if (password !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorText(I18n.t('FORM_ERROR_PASSWORD_NOT_SAME'));
            }
        }
    }, [debouncedConfirmPasswordTerm]);

    const handleTerms = (checked: boolean) => {
        if (checked) {
            setAcceptedTerms(checked);
            setAcceptedTermsError(false);
            setAcceptedTermsErrorText('');
        } else {
            setAcceptedTerms(checked);
            setAcceptedTermsError(true);
            setAcceptedTermsErrorText(I18n.t('FORM_ERROR_TERMS_AND_CONDITIONS'));
        }
    };

    const handleLivingOutside = (checked: boolean) => {
        setLivingOutside(checked);
        if (checked == true) {
            setAddressError(false);
            setZipCodeError(false);
            setZipCodeErrorText('');
            setLoader(false);
            setHouseNumberError(false);
        }
    };

    const checkFullDirection = async () => {
        if (livingOutside) {
            setAddressError(false);
            setZipCodeError(false);
            setZipCodeErrorText('');
            setLoader(false);
            setHouseNumberError(false);
            return;
        }
        if (!Validators.validateZipCode(zipCode)[0] && !Validators.validateHouseNumber(houseNumber)[0]) {
            setLoader(true);
            await APIManager.getAddressFromPostcode(zipCode, houseNumber)
                .then((res) => {
                    if (res.status === 200) {
                        setAddressError(false);
                        setZipCodeError(false);
                        setHouseNumberError(false);
                        setAddress(res.data.street);
                        setCity(res.data.city);
                    } else {
                        setZipCodeError(true);
                        setHouseNumberError(true);
                        setZipCodeErrorText(I18n.t('FORM_ERROR_ADDRESS_NOT_FOUND'));
                    }
                    setLoader(false);
                })
                .catch((error) => {
                    setZipCodeError(true);
                    setHouseNumberError(true);
                    setZipCodeErrorText(I18n.t('FORM_ERROR_ADDRESS_NOT_FOUND'));
                    setLoader(false);
                });
        }
    };

    return (
        <div className='account-information-form'>
            <span className='title'>{I18n.t('ACCOUNT_INFORMATION')}</span>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <TextInput
                        label={`${I18n.t('FORM_FIRSTNAME')}*`}
                        value={firstName}
                        error={firstNameError}
                        errorText={firstNameErrorText}
                        onChange={(val) => setFirstName(val.target.value)}
                        border
                    />
                </div>
                <div className='col-12 col-md-6'>
                    <TextInput
                        label={`${I18n.t('FORM_LASTNAME')}*`}
                        value={lastName}
                        error={lastNameError}
                        errorText={lastNameErrorText}
                        onChange={(val) => setLastName(val.target.value)}
                        border
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-4'>
                    <DateInput
                        label={`${I18n.t('ACCOUNT_BIRTHDAY')}`}
                        value={birthdate}
                        error={false}
                        errorText={''}
                        onChange={(val) => setBirthdate(val.target.value)}
                        border
                        maxDate={moment().format('YYYY-MM-DD')}
                    />
                </div>
                <div className='row'>
                    <div className='col-12 col-checkbox'>
                        <CheckBox
                            label={I18n.t('FORM_LIVING_OUTSIDE')}
                            checked={livingOutside}
                            answerID={99}
                            questionID={99}
                            group={'"' + 99 + '"'}
                            toggleCheck={(e: any) => handleLivingOutside(e.target.checked)}
                        />
                    </div>
                    {livingOutside && (
                        <div className='col-12'>
                            <select
                                className='country-selector'
                                id='country'
                                value={country}
                                onChange={(e: any) => {
                                    setCountry(e.target.value);
                                }}
                            >
                                {countries.map((country: any) => {
                                    return (
                                        <option key={country.value} value={country.value}>
                                            {country.label}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    )}
                </div>
                <div className='col-12 col-md-4'>
                    <TextInput
                        label={`${I18n.t('FORM_ZIP')}*`}
                        value={zipCode}
                        error={zipCodeError}
                        errorText={zipCodeErrorText}
                        onChange={(val) => setZipCode(val.target.value)}
                        border
                    />
                </div>
                <div className='col-12 col-md-4'>
                    <TextInput
                        label={`${I18n.t('FORM_HOUSE_NUMBER')}*`}
                        value={houseNumber}
                        error={houseNumberError}
                        errorText={houseNumberErrorText}
                        onChange={(val) => setHouseNumber(val.target.value)}
                        border
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col-12 container-loader-input-account'>
                    <Loader show={loader} />
                    <TextInput
                        label={`${I18n.t('FORM_ADDRESS')}*`}
                        value={address}
                        error={addressError}
                        errorText={addressErrorText}
                        onChange={(val) => setAddress(val.target.value)}
                        // disabled
                        border
                    />
                </div>
                <div className='col-12 container-loader-input-account'>
                    <Loader show={loader} />
                    <TextInput
                        label={`${I18n.t('FORM_CITY')}*`}
                        value={city}
                        error={cityError}
                        errorText={cityErrorText}
                        onChange={(val) => setCity(val.target.value)}
                        // disabled
                        border
                    />
                </div>
                <div className='col-12'>
                    <TextInput
                        label={`${I18n.t('FORM_EMAIL')}*`}
                        value={email}
                        error={emailError}
                        errorText={emailErrorText}
                        onChange={(val) => setEmail(val.target.value)}
                        border
                    />
                </div>
                <div className='col-12'>
                    <Tooltip delay={0} direction={'top'} content={I18n.t('FORM_PASSWORDS_INVALID')}>
                        <TextInput
                            label={`${I18n.t('FORM_PASSWORD')}*`}
                            value={password}
                            error={passwordError}
                            errorText={passwordErrorText}
                            onChange={(val) => setPassword(val.target.value)}
                            hiddenText
                            border
                        />
                        <span className='d-md-none explain-pass'>{I18n.t('FORM_PASSWORDS_INVALID')}</span>
                    </Tooltip>
                </div>
                <div className='col-12'>
                    <TextInput
                        label={`${I18n.t('FORM_CONFIRM_PASSWORD')}*`}
                        value={confirmPassword}
                        error={confirmPasswordError}
                        errorText={confirmPasswordErrorText}
                        onChange={(val) => setConfirmPassword(val.target.value)}
                        hiddenText
                        border
                    />
                </div>
                <div className='col-12 col-checkbox'>
                    <CheckBox
                        label={I18n.t('FORM_AGREE')}
                        labelUnderline={I18n.t('FORM_TERMS_AND_CONDITIONS')}
                        checked={acceptedTerms}
                        answerID={1}
                        questionID={1}
                        group={'"' + 1 + '"'}
                        toggleCheck={(e: any) => handleTerms(e.target.checked)}
                    />
                </div>
                <div className='col-12'>
                    {acceptedTermsError && <span className='error-text'>{acceptedTermsErrorText}</span>}
                </div>
            </div>
        </div>
    );
};

export default AccountInformationForm;
