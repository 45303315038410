import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import { newpasswordpage } from '../../redux/pages';
import TextInput from '../../components/textinput/textinput';
import Button from '../../components/button/button';
import { clearLoginStatus, doLogin, login_status } from '../../redux/auth';
import { Link, useHistory, useParams } from 'react-router-dom';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import useDebounce from '../../hooks/use-debounce';
import Loader from '../../components/loader/loader';
import Tooltip from '../../components/tooltip/tooltip';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const NewPasswordPage: FC<Props> = (props) => {
    const { token } = useParams<{ token: string }>();

    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [loaderForm, setLoaderForm] = useState(false);
    const dispatch = useDispatch();
    const PAGENEWPASSWORD = useSelector(newpasswordpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [confirmPasswordErrorText, setConfirmPasswordErrorText] = useState('');

    const [formError, setFormError] = useState(false);

    const loginStatus = useSelector(login_status);

    useEffect(() => {
        setLoader(true);
    }, []);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGENEWPASSWORD);
    }, [PAGENEWPASSWORD]);

    useEffect(() => {
        if (loginStatus === 'success') {
            dispatch(clearLoginStatus());
            setEmailError(false);
            history.push('/email');
        } else if (loginStatus !== '') {
            setEmailError(true);
            setEmailErrorText(loginStatus);
        }
        setLoaderForm(false);
    }, [loginStatus]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (
            email !== '' &&
            !emailError &&
            password !== '' &&
            !passwordError &&
            confirmPassword !== '' &&
            !confirmPasswordError
        ) {
            sendForm();
        }
    };

    const sendForm = () => {
        setLoaderForm(true);
        APIManager.createNewPassword(token, email, password)
            .then((res) => {
                if (res.status === 200) {
                    history.push('/sign-in');
                } else {
                    setFormError(true);
                }
            })
            .catch((err) => console.log(err))
            .finally(() => {
                setLoaderForm(false);
            });
    };

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    const debouncedPasswordTerm = useDebounce(password, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            let valField = Validators.validateRegisterPassword(password);
            setPasswordError(valField[0]);
            setPasswordErrorText(valField[1]);

            if (confirmPassword !== '' && password !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorText(I18n.t('FORM_ERROR_PASSWORD_NOT_SAME'));
            } else {
                setConfirmPasswordError(false);
                setConfirmPasswordErrorText('');
            }
        }
    }, [debouncedPasswordTerm]);

    const debouncedConfirmPasswordTerm = useDebounce(confirmPassword, 400);
    useEffect(() => {
        if (confirmPassword !== '') {
            setConfirmPasswordError(false);
            setConfirmPasswordErrorText('');

            if (password !== confirmPassword) {
                setConfirmPasswordError(true);
                setConfirmPasswordErrorText(I18n.t('FORM_ERROR_PASSWORD_NOT_SAME'));
            }
        }
    }, [debouncedConfirmPasswordTerm]);

    return (
        <Wrapper loader={loader} activePage={I18n.t('NAV_SIGNIN')} has_title bottom_banner_button_arrow page={PAGE}>
            <section className='login'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-login'>
                            {loaderForm ? (
                                <div className='container-loader'>
                                    <Loader show={loaderForm} />
                                </div>
                            ) : formError ? (
                                <p>{I18n.t('VALIDATE_INVALID')}</p>
                            ) : (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <p>{I18n.t('FORM_FORGOT_EXPLANATION')}</p>
                                        <TextInput
                                            label={I18n.t('FORM_EMAIL_OR_USERNAME')}
                                            value={email}
                                            error={emailError}
                                            errorText={emailErrorText}
                                            onChange={(val: any) => setEmail(val.target.value)}
                                            border
                                        />

                                        <Tooltip delay={0} direction={'top'} content={I18n.t('FORM_PASSWORDS_INVALID')}>
                                            <TextInput
                                                label={`${I18n.t('FORM_PASSWORD')}*`}
                                                value={password}
                                                error={passwordError}
                                                errorText={passwordErrorText}
                                                onChange={(val) => setPassword(val.target.value)}
                                                hiddenText
                                                border
                                            />
                                            <span className='d-md-none explain-pass'>
                                                {I18n.t('FORM_PASSWORDS_INVALID')}
                                            </span>
                                        </Tooltip>
                                        <div className='col-12'>
                                            <TextInput
                                                label={`${I18n.t('FORM_CONFIRM_PASSWORD')}*`}
                                                value={confirmPassword}
                                                error={confirmPasswordError}
                                                errorText={confirmPasswordErrorText}
                                                onChange={(val) => setConfirmPassword(val.target.value)}
                                                hiddenText
                                                border
                                            />
                                        </div>
                                        <div className='container-button'>
                                            <Button
                                                isButton
                                                defaultStyle
                                                showArrow
                                                title={I18n.t('FORM_FORGOT_PASSWORD_BUTTON')}
                                            />
                                        </div>
                                    </form>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default NewPasswordPage;
