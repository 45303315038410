import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';
import I18n from '../../helpers/i18n';
import { initReactI18next } from 'react-i18next';

const APIManager = API.instance;

interface thisState {
    strings: any;
    general_settings: any;
}

const initialState: thisState = {
    strings: {},
    general_settings: []
};

export const thisSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setStrings: (state, action: PayloadAction<[]>) => {
            const resources = {
                en: {
                  translation: action.payload
                }
              };
              
              I18n
                .use(initReactI18next as any) // passes i18n down to react-i18next
                .init({
                  resources,
                  lng: "en",
              
                  keySeparator: false, // we do not use keys in form messages.welcome
              
                  interpolation: {
                    escapeValue: false // react already safes from xss
                  }
            });
        },
        setGeneralSettings:  (state, action: PayloadAction<[]>) => {
            state.general_settings = action.payload
        }
    },
});
export const {
    setStrings,
    setGeneralSettings,
} = thisSlice.actions;

export const getLangStrings = (): AppThunk => dispatch => {
    APIManager.get('/items/language_strings?fields=*.*&limit=-1', '').then(data => {
        if (data !== undefined && data.length > 0) {
            var translations: any = {};
            for(let translation of data) {
                let key = translation.key;
                let value_en = "";
                for(let t of translation.translations) {
                    if (t.languages_code === 'en-US') {
                        value_en = t.value;
                    }
                }
                translations[key] = value_en;
                dispatch(setStrings(translations));
            }
        }
    });
};

export const getGeneralSettings = (): AppThunk => dispatch => {
    APIManager.get('/items/general_site_settings?fields=*.*', '').then(data => {
        dispatch(setGeneralSettings(data));
    });
};

export const generalSettings = (state: RootState) => state.general.general_settings;

export default thisSlice.reducer;
