import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import renderHTML from 'react-render-html';
import I18n from '../../../../helpers/i18n';
import FUNCTIONS from '../../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, questionsAnswers, questionsRequest, setFinished } from '../../../../redux/questionnaire';
import QuestionNonResident from '../../components/question/question_non_resident';
import CalculateCost from '../../components/calculate_cost/calculate_cost';

const FunctionsManager = FUNCTIONS.instance;

interface Props {
    disabledNextStep: any;
    denniedResponse: string;
}

const NonResidentQuestions: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [dennied, setDennied] = useState(false);
    const QUESTIONS_REQUEST = useSelector(questionsRequest);
    const QUESTIONS_ANSWERS = useSelector(questionsAnswers);
    const DATA_USER = useSelector(dataUser);

    const [totalCost, setTotalCost] = useState(0);

    useEffect(() => {
        props.disabledNextStep(true);
    }, []);

    useEffect(() => {
        const basePrice = DATA_USER.basePrice;
        const totalQuestions = DATA_USER.questionsNonResident
            .map((q: any) => {
                return q.answer.amount;
            })
            .reduce((prev: number, curr: number) => prev + curr, 0);
        setTotalCost(basePrice + totalQuestions);
    }, [DATA_USER]);

    useEffect(() => {
        const condition1 = DATA_USER.questionsNonResident.find((q: any) => q.question.id === 19);
        const condition2 = DATA_USER.questionsNonResident.find(
            (q: any) => q.question.id === 18 && [2].includes(q.answer.id)
        );
        const condition3 = DATA_USER.questionsNonResident.find(
            (q: any) => q.question.id === 17 && [2].includes(q.answer.id)
        );

        if (condition1 || condition2 || condition3) {
            dispatch(setFinished(true));
            props.disabledNextStep(false);
        } else {
            dispatch(setFinished(false));
            props.disabledNextStep(true);
        }
    }, [DATA_USER.questionsNonResident]);

    // Scroll to active question
    useEffect(() => {
        const className = `question-container`;
        const elements = document.getElementsByClassName(className);
        if (elements) {
            elements[elements.length - 1].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        }
    }, [DATA_USER.questionsNonResident]);

    return (
        <div className='container'>
            <div className='row d-flex justify-content-center align-items-center flex-column'>
                <div className='col-12 col-lg-8 p-lg-0'>
                    {/* What year... */}
                    <QuestionNonResident question={QUESTIONS_REQUEST.find((q: any) => q.id === 3)} answers={[]} />
                    {
                        // 44 How many addresses*?
                        DATA_USER.year !== 0 && DATA_USER.year !== 1970 && (
                            <QuestionNonResident
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 14)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [0].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 45 Does one or more of the properties have multiple-owners (this can also be your spouse)?
                        DATA_USER.questionsNonResident.find((q: any) => q.question.id === 14) && (
                            <QuestionNonResident
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 17)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 46 Please note that each owner has to file a tax return...
                        DATA_USER.questionsNonResident.find((q: any) => q.question.id === 17 && q.answer.id === 1) && (
                            <QuestionNonResident
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 18)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 47 How many ownners does the real-state have?
                        DATA_USER.questionsNonResident.find((q: any) => q.question.id === 18 && q.answer.id === 1) && (
                            <QuestionNonResident
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 19)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {dennied ? (
                        <div className={'questionaire-status col-12 col-lg-10 p-lg-0'}>
                            {renderHTML(props.denniedResponse)}
                        </div>
                    ) : (
                        <div className='row d-flex justify-content-center align-items-start'>
                            <div className='col-12 col-lg-8 p-lg-0 total-amount'>
                                {FunctionsManager.computedStringQuestionaire(
                                    I18n.t('TOTAL_PRICE'),
                                    DATA_USER.year,
                                    DATA_USER.amountByYear
                                )}{' '}
                                <span className='price'>{totalCost}.-</span>
                            </div>
                        </div>
                    )}
                </div>
                <div className='col-12 col-lg-3 p-lg-0 d-none d-lg-flex position-relative'></div>
            </div>
        </div>
    );
};

export default NonResidentQuestions;
