import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from "../../helpers/i18n";
import { useState } from 'react';

interface Props {
    questionID: number,
    answerID: any,
    label: string,
    labelUnderline?: string
    checked: boolean,
    value?: string,
    group: string,
    toggleCheck: any
}

const CheckBox : FC<Props> = (props) => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState(false);
    
    useEffect(() => {
        setChecked(props.checked)
    }, [props.checked])

    return (
        <div className="checkbox-container">
            <label className="d-flex align-items-start">
                <input
                    name={props.group}
                    checked={checked}
                    type="checkbox"
                    id={props.answerID}
                    value={props.answerID}
                    className="checkbox-input"
                    onClick={() => setChecked(!checked)}
                    onChange={props.toggleCheck}
                />
                <span className="checkbox-label">
                    {props.label}
                    {props.labelUnderline ? <u>{` ${props.labelUnderline}`}</u> : null}
                </span>
            </label>
        </div>
    );
}
export default CheckBox;