export interface interfacePage {
    id: number,
    status: string,
    cms_name: string,
    seo_title: string,
    seo_description: string,
    title: string,
    text: string,
    show_page_banner: boolean,
    banner_title: string,
    banner_subtitle: string,
    banner_photo: { filename_disk: string },
    banner_button_text: string,
    banner_button_link: string,
    show_bottom_banner: boolean,
    bottom_banner_title: string,
    bottom_banner_title_logged: string,
    banner_bottom_text: string,
    button_link: string,
    button_text: string,
}

export const initialPage = {
    id: 0,
    status: '',
    cms_name: '',
    seo_title: '',
    seo_description: '',
    title: '',
    text: '',
    show_page_banner: false,
    banner_title: '',
    banner_subtitle: '',
    banner_photo: { filename_disk: '' },
    banner_button_text: '',
    banner_button_link: '',
    show_bottom_banner: false,
    bottom_banner_title: '',
    bottom_banner_title_logged: '',
    banner_bottom_text: '',
    button_link: '',
    button_text: '',
}