import React, { FC, useState, useEffect  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import I18n from "../../helpers/i18n";
import renderHTML from 'react-render-html';
import lockIcon from '../../images/lock.png';
import eyeIcon from '../../images/eye.png';
interface Props {
    label: string,
    value: string,
    error: boolean,
    errorText: string,
    maxLength?: number,
    border?: boolean,

    hiddenText?: boolean, // shows eye/lock icon and hides text
    disabled?: boolean, // allow editing or not
    onBlur?: (val: any) => void, // fires when leaving the field
    onFocus?: () => void, // fires when entering the field
    onChange: (val: any) => void // fires when typing in the field

    textArea?: boolean,
    numberofRows?: number, // number of rows for the props.textArea only
}

const TextInput : FC<Props> = (props) => {
    const [hideText, setHideText] = useState(false);

    useEffect(() => {
        if (props.hiddenText) {
            setHideText(true);
        } 
    }, [])

    const inputStyle = () => {
        var style = "input";

        if(props.disabled){
            style = style + " disabled";
        }
        if(props.hiddenText){
            style = style + " extra-padding";
        }
        if(props.border){
            style = style + " border";
        }

        return style;
    }

    const onChange = (event: any) => {
        if(props.onChange){
            props.onChange(event);
        }
    }

    const onBlur = (event: any) => {
        if(props.onBlur){
            props.onBlur(event);
        }
    }

    return (
        <div>
            <div className={props.error ? "input-wrapper error" : "input-wrapper"}>
                {props.textArea ?
                    <textarea 
                        rows={props.numberofRows ? props.numberofRows : 10} cols={1}
                        className={`textarea 
                                ${props.disabled ? "disabled" : ""} 
                                ${props.border ? "border" : ""}`
                            }
                        placeholder=" "
                        onBlur={onBlur}
                        onChange={onChange}
                        maxLength={props.maxLength ? props.maxLength : 750}
                        disabled={props.disabled}
                        value={props.value}
                    >
                        {props.value}
                    </textarea>
                :
                    <input 
                        type={hideText ? "password" : "text"}
                        value={props.value}
                        className={inputStyle()}
                        placeholder=" "
                        onFocus={props.onFocus}
                        onBlur={onBlur}
                        onChange={onChange}
                        maxLength={props.maxLength ? props.maxLength : 500}
                        disabled={props.disabled}
                    />
                }
                <label>{props.label}</label>
                {props.hiddenText &&
                    <div className="secure-icon-container" onClick={() => setHideText(!hideText)}>
                        <img src={hideText ? eyeIcon : lockIcon} className="secure-icon"/>
                    </div>
                }
            </div>
            {props.error &&
                <span className="error-text">{props.errorText}</span>
            }
        </div>
    );
}
export default TextInput;