export const amountQuestion = (
    idQuestion: number,
    idAnswer: number,
    valueAnswer: string,
    amount1: number,
    amount2: number,
    fiscalPartner: boolean
) => {
    if (idQuestion === 8 && idAnswer === 1) {
        return amount1;
    }

    if (idQuestion === 7 && idAnswer === 1) {
        return amount1;
    }

    if (idQuestion === 21 && idAnswer === 1) {
        return amount1;
    }

    if ([22, 23, 24, 25, 26, 27, 28, 29].includes(idQuestion) && idAnswer === 1) {
        return amount1;
    }

    if (valueAnswer.includes('_howMany')) {
        const assets = +valueAnswer.split('_')[0];

        if (idQuestion === 31 && assets > 4) {
            const multiply = fiscalPartner ? 2 : 1;
            const assetsToMultiply = Math.ceil((assets - 4) / 3);
            return assetsToMultiply * amount1 * multiply;
        }

        if (assets < 3 && (idQuestion === 33 || idQuestion === 43)) {
            return amount1;
        }

        if (assets >= 3 && (idQuestion === 33 || idQuestion === 43)) {
            const assetsToMultiply = Math.ceil(assets / 3);
            return assetsToMultiply * amount1;
        }

        if (
            assets < 2 &&
            (idQuestion === 35 || idQuestion === 39 || idQuestion === 41 || idQuestion === 45 || idQuestion === 50)
        ) {
            return amount1;
        }

        if (
            assets >= 2 &&
            (idQuestion === 35 || idQuestion === 39 || idQuestion === 41 || idQuestion === 45 || idQuestion === 50)
        ) {
            const assetsToMultiply = Math.ceil(assets / 2);
            return assetsToMultiply * amount1;
        }

        if (idQuestion === 37 || idQuestion === 47) {
            return assets * amount1;
        }
    }

    if (idQuestion === 12 && idAnswer === 1) {
        return amount1;
    }

    if (idQuestion === 12 && idAnswer === 2) {
        return amount2;
    }

    if (idQuestion === 14) {
        return Math.round(idAnswer / 2) * amount1;
    }

    if (idQuestion === 16 && idAnswer === 1) {
        return amount1;
    }

    if (idQuestion === 16 && idAnswer === 2) {
        return amount2;
    }

    return 0;
};
