import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../style/style.scss';
import I18n from '../../../helpers/i18n';
import API from '../../../helpers/api';
import Helpers from '../../../helpers/functions';
import { getPages } from '../../../redux/pages';

import TextInput from '../../../components/textinput/textinput';
import Button from '../../../components/button/button';
import useDebounce from '../../../hooks/use-debounce';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const ContactForm: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorText, setPhoneErrorText] = useState('');

    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [messageErrorText, setMessageErrorText] = useState('');

    const [successfulMessage, setSuccessfulMessage] = useState(false);
    const [errorSendMessage, setErrorSendMessage] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        let valFirstName = Validators.validateString(firstName);
        let valLastName = Validators.validateString(lastName);
        let valEmail = Validators.validateEmail(email);
        let valPhone = Validators.validatePhoneNumber(phone);
        let valMessage = Validators.validateString(message);

        if (firstName === '') {
            setFirstNameError(true);
            setFirstNameErrorText(I18n.t('FORM_ERROR_REQUIRED'));
            return;
        }

        if (lastName === '') {
            setFirstNameError(true);
            setFirstNameErrorText(I18n.t('FORM_ERROR_REQUIRED'));
            return;
        }

        if (email === '') {
            setFirstNameError(true);
            setFirstNameErrorText(I18n.t('FORM_ERROR_REQUIRED'));
            return;
        }

        if (phone === '') {
            setFirstNameError(true);
            setFirstNameErrorText(I18n.t('FORM_ERROR_REQUIRED'));
            return;
        }

        if (message === '') {
            setFirstNameError(true);
            setFirstNameErrorText(I18n.t('FORM_ERROR_REQUIRED'));
            return;
        }

        if (
            firstName !== '' &&
            !valFirstName[0] &&
            lastName !== '' &&
            !valLastName[0] &&
            email !== '' &&
            !valEmail[0] &&
            phone !== '' &&
            !valPhone[0] &&
            message !== '' &&
            !valMessage[0]
        ) {
            sendForm();
        }
    };

    const sendForm = () => {
        APIManager.sendContactForm(firstName, lastName, email, phone, message)
            .then((res) => {
                if (res.status === 204) {
                    setErrorSendMessage(false);
                    setSuccessfulMessage(true);
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhone('');
                    setMessage('');
                } else {
                    setSuccessfulMessage(false);
                    setErrorSendMessage(true);
                }
            })
            .catch((err) => {
                setSuccessfulMessage(false);
                setErrorSendMessage(true);
            });
    };

    const debouncedFirstNameTerm = useDebounce(firstName, 400);
    useEffect(() => {
        if (firstName !== '') {
            let valField = Validators.validateString(firstName);
            setFirstNameError(valField[0]);
            setFirstNameErrorText(valField[1]);
        }
    }, [debouncedFirstNameTerm]);

    const debouncedLastNameTerm = useDebounce(lastName, 400);
    useEffect(() => {
        if (lastName !== '') {
            let valField = Validators.validateString(lastName);
            setLastNameError(valField[0]);
            setLastNameErrorText(valField[1]);
        }
    }, [debouncedLastNameTerm]);

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    const debouncedPhoneTerm = useDebounce(phone, 400);
    useEffect(() => {
        if (phone !== '') {
            let valField = Validators.validatePhoneNumber(phone);
            setPhoneError(valField[0]);
            setPhoneErrorText(valField[1]);
        }
    }, [debouncedPhoneTerm]);

    const debouncedMessageTerm = useDebounce(message, 400);
    useEffect(() => {
        if (message !== '') {
            let valField = Validators.validateString(message);
            setMessageError(valField[0]);
            setMessageErrorText(valField[1]);
        }
    }, [debouncedMessageTerm]);

    return (
        <form onSubmit={handleSubmit}>
            <TextInput
                label={I18n.t('FORM_FIRSTNAME')}
                value={firstName}
                error={firstNameError}
                errorText={firstNameErrorText}
                onChange={(val) => setFirstName(val.target.value)}
                border
            />
            <TextInput
                label={I18n.t('FORM_LASTNAME')}
                value={lastName}
                error={lastNameError}
                errorText={lastNameErrorText}
                onChange={(val) => setLastName(val.target.value)}
                border
            />
            <TextInput
                label={I18n.t('FORM_EMAIL')}
                value={email}
                error={emailError}
                errorText={emailErrorText}
                onChange={(val) => setEmail(val.target.value)}
                border
            />
            <TextInput
                label={I18n.t('FORM_PHONE')}
                value={phone}
                error={phoneError}
                errorText={phoneErrorText}
                onChange={(val) => setPhone(val.target.value)}
                border
            />
            <TextInput
                label={I18n.t('FORM_MESSAGE')}
                value={message}
                error={messageError}
                errorText={messageErrorText}
                onChange={(val) => setMessage(val.target.value)}
                border
                textArea
                numberofRows={5}
            />
            <div className='container-button'>
                <Button isButton defaultStyle showArrow title='Send message' />
            </div>
            <div className={'container-response'}>
                {successfulMessage && <p>{I18n.t('FORM_SUCCESSFUL')}</p>}
                {errorSendMessage && <p>{I18n.t('FORM_SAVE_ERROR')}</p>}
            </div>
        </form>
    );
};

export default ContactForm;
