import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import '../../style/style.scss';
import Button from '../../components/button/button';

interface Props {
    full?: boolean, // bigger sized or normal sized
    small?: boolean,
    title?: string,
    titleBlue?: boolean,
    subTitle?: string,
    subTitleBig?: boolean,
    photo?: string,
    buttonText?: string,
    buttonUrl?: string,
    buttonArrow?: boolean,
    bottomText?: string
}

const Banner : FC<Props> = (props) => {
    const dispatch = useDispatch();

    const getStyle = () => {
        var style = "page-banner";

        if(props.full){
            style = style + " full";
        } else if (props.small) {
            style = style + " small";
        } else {
            style = style + " mid";
        }

        return style;
    }

    return (
        <section className={getStyle()} style={props.photo ? {backgroundImage: `linear-gradient(rgba(30, 63, 162, 0.4), rgba(30, 63, 162, 0.4)), url(${props.photo})`} : {}}>
            <div className="container h-100 w-100 d-flex align-items-center justify-content-center">
                <div className="row h-100 w-100 d-flex align-items-center justify-content-center">
                    <div className="col-12 content d-flex align-items-center justify-content-center flex-column">
                        {props.title &&
                            <h1 className={`title ${props.titleBlue ? 'title-blue' : '' }`}>
                                {props.title}
                            </h1>
                        }
                        {props.subTitle &&
                            props.subTitle.includes('<br>') ?
                                props.subTitle.split('<br>').map((line, i) => {
                                    return <span className={`h2 
                                                            subtitle
                                                            ${!props.subTitleBig && 'small-subtitle'}
                                                            subtitle-front-page
                                                            ${props.subTitle && props.subTitle.split('<br>').length - 1 === i && 'last-item'}`}>
                                                {line}
                                            </span>
                                    })
                            :
                                <span className={`h2 subtitle ${!props.subTitleBig && 'small-subtitle' }`}>
                                    {props.subTitle}
                                </span>
                        }
                        {(props.buttonText && props.buttonUrl) &&
                            <div className="container-button d-flex justify-content-center">
                                <Button defaultStyle showArrow={props.buttonArrow} title={props.buttonText} path={props.buttonUrl}/>
                            </div>
                        }
                        {props.bottomText &&
                            <span className="bottom-text page-banner-bottom">{props.bottomText}</span>
                        }
                    </div>
                </div>
            </div>
            {props.small ? '' : <div className="overlay"/>}
        </section>
    );
}
export default Banner;