import React, { FC } from 'react';
import '../../style/style.scss';

interface Props {
    label: string,
    value: string,
    error: boolean,
    errorText: string,
    border?: boolean,
    maxDate?: string,

    disabled?: boolean, // allow editing or not
    onBlur?: (val: any) => void, // fires when leaving the field
    onFocus?: () => void, // fires when entering the field
    onChange: (val: any) => void // fires when typing in the field
}

const DateInput : FC<Props> = (props) => {
    const inputStyle = () => {
        var style = "input";

        if(props.disabled){
            style = style + " disabled";
        }
        if(props.border){
            style = style + " border";
        }

        return style;
    }

    const onChange = (event: any) => {
        if(props.onChange){
            props.onChange(event);
        }
    }

    const onBlur = (event: any) => {
        if(props.onBlur){
            props.onBlur(event);
        }
    }

    return (
        <div>
            <div className={props.error ? "input-wrapper error" : "input-wrapper"}>
                <input 
                    type='date'
                    value={props.value}
                    className={inputStyle()}
                    placeholder=" "
                    onFocus={props.onFocus}
                    onBlur={onBlur}
                    onChange={onChange}
                    disabled={props.disabled}
                    max={props.maxDate}
                />
                <label>{props.label}</label>
            </div>
            {props.error &&
                <span className="error-text">{props.errorText}</span>
            }
        </div>
    );
}
export default DateInput;