import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import countryList from 'react-select-country-list';
import Button from '../../../../components/button/button';
import Loader from '../../../../components/loader/loader';
import TextInput from '../../../../components/textinput/textinput';
import Wrapper from '../../../../components/wrapper/wrapper';
import API from '../../../../helpers/api';
import Helpers from '../../../../helpers/functions';
import I18n from '../../../../helpers/i18n';
import useDebounce from '../../../../hooks/use-debounce';
import { initialPage, interfacePage } from '../../../../models/page';
import { doLogout } from '../../../../redux/auth';
import { billingpage } from '../../../../redux/pages';
import Menu from '../../components/menu/menu';

const Validators = Helpers.instance;
const APIManager = API.instance;

const Billing = () => {
    const PAGEBILLING = useSelector(billingpage);
    const dispatch = useDispatch();
    const history = useHistory();
    const { idForm } = useParams<{ idForm: string }>();
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);
    const [loader, setLoader] = useState(false);

    const [form, setForm] = useState<any>(undefined);
    const [user, setUser] = useState<any>(undefined);

    const [firstName, setFirstName] = useState('');
    const [firstNameError, setFirstNameError] = useState(false);
    const [firstNameErrorText, setFirstNameErrorText] = useState('');

    const [lastName, setLastName] = useState('');
    const [lastNameError, setLastNameError] = useState(false);
    const [lastNameErrorText, setLastNameErrorText] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [zipCode, setZipCode] = useState('');
    const [zipCodeError, setZipCodeError] = useState(false);
    const [zipCodeErrorText, setZipCodeErrorText] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [country, setCountry] = useState('NL');
    const countries = countryList().getData();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState(false);
    const [phoneErrorText, setPhoneErrorText] = useState('');

    const [promoCode, setPromoCode] = useState('');
    const [idPromoCode, setIdPromoCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [promoCodeError, setPromoCodeError] = useState(false);

    const [disabledButton, setDisabledButton] = useState(true);
    const [loaderButton, setLoaderButton] = useState(false);

    const checkForm = () => {
        if (
            firstName !== '' &&
            lastName !== '' &&
            address !== '' &&
            zipCode !== '' &&
            city !== '' &&
            email !== '' &&
            !emailError
        ) {
            return true;
        }
    };

    useEffect(() => {
        checkForm() ? setDisabledButton(false) : setDisabledButton(true);
    }, [checkForm()]);

    useEffect(() => {
        APIManager.isUserLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn === false) {
                dispatch(doLogout());
                history.push('/sign-in');
            }
        });
    }, []);

    useEffect(() => {
        if (idForm === undefined) history.push('/sign-in');
    }, []);

    useEffect(() => {
        getProfile();
        getForm();
    }, []);

    useEffect(() => {
        SET_PAGE(PAGEBILLING);
    }, [PAGEBILLING]);

    const debouncedEmailTerm = useDebounce(email, 400);
    useEffect(() => {
        if (email !== '') {
            let valField = Validators.validateEmail(email);
            setEmailError(valField[0]);
            setEmailErrorText(valField[1]);
        }
    }, [debouncedEmailTerm]);

    const debouncedPromoCodeTerm = useDebounce(promoCode, 400);
    useEffect(() => {
        setPromoCodeError(false);
        setIdPromoCode('');
        if (promoCode !== '') {
            checkPromoCode(promoCode);
        }
    }, [debouncedPromoCodeTerm]);

    const copyDataFromAccount = () => {
        setFirstName(user.first_name);
        setLastName(user.last_name);
        setAddress(`${user.address}, ${user.house_number}, `);
        setZipCode(user.zipcode);
        setCity(user.location);
        setCountry(user.country);
        setEmail(user.email);
    };

    const handleSubmit = () => {
        setLoaderButton(true);
        APIManager.purchase(
            form.total_cost,
            form.year,
            +idForm,
            `${firstName} ${lastName}`,
            email,
            city,
            country,
            zipCode,
            address,
            idPromoCode
        )
            .then((res) => {
                if (res.status === 303) {
                    window.location.href = res.data.url;
                }
            })
            .catch((err) => console.log(err))
            .then(() => setLoaderButton(false));
    };

    const getProfile = () => {
        APIManager.getProfile()
            .then((res) => {
                if (res.status === 200) {
                    setUser(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getForm = () => {
        APIManager.getForm(idForm)
            .then((res) => {
                if (res.status === 200) {
                    setForm(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const checkPromoCode = (promoCode: string) => {
        APIManager.checkPromoCode(promoCode)
            .then((res) => {
                if (res.status === 200) {
                    setIdPromoCode(res.data.id);
                    if (res.data.amount_off !== null) {
                        setDiscount(res.data.amount_off / 100);
                    } else if (res.data.percent_off !== null) {
                        setDiscount((form.total_cost * res.data.percent_off) / 100);
                    }
                } else {
                    setDiscount(0);
                    setIdPromoCode('');
                    setPromoCodeError(true);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Wrapper
            loader={loader}
            activePage={I18n.t('NAV_ACCOUNT')}
            circles_is_request_page
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title}
            seo_description={PAGE.seo_description}
        >
            <Menu activeTab={I18n.t('ACCOUNT_DASHBOARD')} />
            <section className='personal-info billing'>
                <div className='container'>
                    <div className='row d-flex justify-content-center align-items-center m-0'>
                        <div className='col-12 col-lg-8 p-0'>
                            <div className='personal-information-form'>
                                <span className='title'>{I18n.t('BILLING_DETAILS')}</span>
                                <div className='container-copy mt-5 mb-3'>
                                    <Button
                                        title={I18n.t('BILLING_COPY_INFO')}
                                        onPress={copyDataFromAccount}
                                        secondaryStyle
                                    />
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className='row justify-content-end'>
                                        <div className='col-12 col-md-6'>
                                            <TextInput
                                                label={`${I18n.t('FORM_FIRSTNAME')}`}
                                                value={firstName}
                                                error={firstNameError}
                                                errorText={firstNameErrorText}
                                                onChange={(val) => setFirstName(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <TextInput
                                                label={`${I18n.t('FORM_LASTNAME')}`}
                                                value={lastName}
                                                error={lastNameError}
                                                errorText={lastNameErrorText}
                                                onChange={(val) => setLastName(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <TextInput
                                                label={`${I18n.t('FORM_ADDRESS')}`}
                                                value={address}
                                                error={addressError}
                                                errorText={addressErrorText}
                                                onChange={(val) => setAddress(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <TextInput
                                                label={`${I18n.t('FORM_ZIP')}`}
                                                value={zipCode}
                                                error={zipCodeError}
                                                errorText={zipCodeErrorText}
                                                onChange={(val) => setZipCode(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <TextInput
                                                label={`${I18n.t('FORM_CITY')}`}
                                                value={city}
                                                error={cityError}
                                                errorText={cityErrorText}
                                                onChange={(val) => setCity(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <select
                                                className='country-selector'
                                                id='country'
                                                value={country}
                                                onChange={(e: any) => {
                                                    setCountry(e.target.value);
                                                }}
                                            >
                                                {countries.map((country: any) => {
                                                    return (
                                                        <option key={country.value} value={country.value}>
                                                            {country.label}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                        <div className='col-12 col-md-6'>
                                            <TextInput
                                                label={I18n.t('FORM_PHONE')}
                                                value={phone}
                                                error={phoneError}
                                                errorText={phoneErrorText}
                                                onChange={(val) => setPhone(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12'>
                                            <TextInput
                                                label={I18n.t('FORM_EMAIL')}
                                                value={email}
                                                error={emailError}
                                                errorText={emailErrorText}
                                                onChange={(val) => setEmail(val.target.value)}
                                                border
                                            />
                                        </div>
                                        <div className='col-12 col-md-6'></div>
                                        <div className='col-12 col-md-6 promotion'>
                                            <p>{I18n.t('HAVE_PROMO_CODE')}</p>
                                            <TextInput
                                                label={I18n.t('FORM_PROMO_CODE')}
                                                value={promoCode}
                                                error={promoCodeError}
                                                errorText={I18n.t('PROMO_INVALID')}
                                                onChange={(val) => setPromoCode(val.target.value)}
                                                border
                                            />
                                        </div>
                                        {form && (
                                            <>
                                                <div className='col-12 col-md-6'></div>
                                                <div className='col-12 col-md-6 total-cost'>
                                                    {discount !== 0 && (
                                                        <div>
                                                            <p className='subtotal'>
                                                                {I18n.t('BILLING_SUBTOTAL')}: € {form.total_cost}.-
                                                            </p>
                                                            <p className='discount'>
                                                                {I18n.t('BILLING_DISCOUNT')}: € {discount}.-
                                                            </p>
                                                        </div>
                                                    )}
                                                    <p className='total'>
                                                        {I18n.t('BILLING_TOTAL')}: € {form.total_cost - discount}.-
                                                    </p>
                                                </div>
                                            </>
                                        )}
                                        <div className='container-submit mt-4 d-flex justify-content-end'>
                                            {loaderButton ? (
                                                <Loader show={loaderButton} />
                                            ) : (
                                                <Button
                                                    title={I18n.t('FORM_PURCHASE')}
                                                    onPress={handleSubmit}
                                                    defaultStyle
                                                    disabled={disabledButton}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default Billing;
