import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import renderHTML from 'react-render-html';

interface Props {
    data: any;
    expanded: boolean;
    expandItem: (id: number) => void
}

const QuestExpanded : FC<Props> = (props) => {
    const dispatch = useDispatch();

    const item = props.data;

    return (
        <div 
            className="content-block my-5"
            onClick={() => props.expandItem(item.id)}
        >
            <div className="col-12 justify-content-center">
                <span className="question">
                    {item.question}
                </span>
                {props.expanded &&
                    <span className="text">
                        {renderHTML(item.answer)}
                    </span>
                }
            </div>
            <div className="arrow-container-faq d-flex justify-content-center">
                <div className={`plus ${props.expanded && 'expanded'}`}/>
            </div>
        </div>
    );
}
export default QuestExpanded;