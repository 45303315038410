import React, { useState, FC, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import renderHTML from 'react-render-html';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import InputAddress from '../input_address/input_address';
import ImageQuestion from '../../../../images/question.svg';
import ModalDescription from '../modal_description/modal_description';
import FUNCTIONS from '../../../../helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, setQuestionsResident } from '../../../../redux/questionnaire';
import { amountQuestion } from '../../../../helpers/amountQuestion';

const FunctionsManager = FUNCTIONS.instance;
interface Props {
    question: any;
    answers: any;
}

const calculateDefaultValue = (d: any) => {
    const question = d.questionsResident.find((q: any) => q.question.id === 14);
    if (question) {
        return question.answer.id;
    } else {
        return 0;
    }
};

const QuestionPrice: FC<Props> = (props) => {
    const [firstTime, setFirstTime] = useState(true);
    const DATA_USER = useSelector(dataUser);
    const dispatch = useDispatch();
    const [defaultValue, setDefaultValue] = useState<number>(calculateDefaultValue(DATA_USER));
    const [isVisibleModalDescription, setIsVisibleModalDescription] = useState(false);

    const toggleCheck = (idAnswer: number, valueAnswer: string, amount1: number, amount2: number) => {
        // Exception if question answer is inserted
        const found = DATA_USER.questionsResident.find((q: any) => q.question.id === 14 && q.answer.id);
        if (props.question.id === 14 && found && !firstTime) {
            setFirstTime(false);
            return;
        }

        const idQuestion = props.question.id;
        const fiscalPartner = DATA_USER.questionsResident.find(
            (q: any) => q.question.id === 8 && [1].includes(q.answer.id)
        );
        const amount = amountQuestion(idQuestion, idAnswer, valueAnswer, amount1, amount2, fiscalPartner);

        const questionInsert = {
            question: {
                id: idQuestion,
                question: props.question.question,
                order: props.question.order,
            },
            answer: {
                id: idAnswer,
                answer: valueAnswer,
                amount: amount,
            },
        };

        const insert = DATA_USER.questionsResident.filter((q: any) => {
            if (q.question.id === 12 && idQuestion === 13) return true;
            return q.question.order < questionInsert.question.order;
        });

        dispatch(setQuestionsResident([...insert, questionInsert]));
    };

    const changeAddress = (quantity: number) => {
        toggleCheck(quantity, `${quantity} addresses`, props.question.amount_1, props.question.amount_2);
    };

    const displayAnswers = () => {
        if (props.question.id === 14) {
            return displayAddresses();
        } else {
            return displayNormalAnswers();
        }
    };

    const displayAddresses = () => {
        return (
            <div className='d-flex flex-column flex-lg-row gap-2 col-12 col-md-6 col-lg-12 m-auto mt-4'>
                <InputAddress onChange={changeAddress} defaultQuantity={defaultValue} />
            </div>
        );
    };

    const displayNormalAnswers = () => {
        return (
            <div className='d-flex flex-column flex-lg-row gap-2 col-12 col-md-6 col-lg-12 m-auto mt-4'>
                {props.answers.map((answer: any) => {
                    const selected = DATA_USER.questionsResident.find((object: any) => {
                        return object.question.id === props.question.id && object.answer.id === answer.id;
                    });
                    return (
                        <span
                            className={`button ${selected ? 'blue' : 'secondary'} ${answer.id === 7 && 'order-first'}`}
                            key={answer.id}
                            onClick={() =>
                                toggleCheck(answer.id, answer.answer, props.question.amount_1, props.question.amount_2)
                            }
                        >
                            {answer.answer}
                        </span>
                    );
                })}
            </div>
        );
    };

    const closeModalDescription = () => {
        setIsVisibleModalDescription(false);
    };

    return (
        <div className='question-container' id={`question-${props.question.id}`}>
            {typeof props.question.description === 'string' && props.question.description !== '' && (
                <ModalDescription
                    closeModalDescription={closeModalDescription}
                    visible={isVisibleModalDescription}
                    // @ts-ignore: Unreachable code error
                    text={renderHTML(
                        FunctionsManager.computedStringQuestionaire(
                            props.question.description,
                            DATA_USER.year,
                            DATA_USER.amountByYear
                        )
                    )}
                />
            )}
            {/* prettier-ignore */}
            <span
                className={`question \
                ${DATA_USER.questionsResident.find((q: any) => q.question.id === 8 && q.answer.id === 2) && 'no-fiscal-partner'}`}
            >
                {renderHTML(
                    FunctionsManager.computedStringQuestionaire(
                        props.question.question,
                        DATA_USER.year,
                        DATA_USER.amountByYear
                    )
                )}
            </span>
            {typeof props.question.description === 'string' && props.question.description !== '' && (
                <img
                    className='button-question'
                    src={ImageQuestion}
                    alt='description-question'
                    onClick={() => setIsVisibleModalDescription(true)}
                />
            )}
            {displayAnswers()}
        </div>
    );
};

export default QuestionPrice;
