
import React, { FC, useEffect, useState } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';

interface Props {
    document: any
    closeModalDelete: any,
    confirmationDeleteDocument: any,
    visible: boolean
}

const ModalDelete: FC<Props> = (props) => {

    const handleDelete = () => {
        props.confirmationDeleteDocument();
    }

    const closeModal = (e: any) => {
        e.preventDefault();
        props.closeModalDelete();
    }

    return (
        <div className={`modal-delete ${props.visible ? 'visible-modal' : ''}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <h4>{I18n.t('CONFIRMATION_DELETE_DOCUMENT')}</h4>
                        <div>
                            {
                                props.document && 
                                <span>{props.document.documents_id.file.filename_download}</span>
                            }
                        </div>
                        <div className="modal-footer">
                            <div onClick={closeModal}>
                                <Button isButton defaultStyle title={I18n.t('ACCOUNT_MODAL_CLOSE')} />
                            </div>
                            <div onClick={handleDelete}>
                                <Button isButton defaultStyle title={I18n.t('DELETE_DOCUMENT')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ModalDelete;