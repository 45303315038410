import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';
import { initialPage, interfacePage } from '../../models/page';
const APIManager = API.instance;

interface thisState {
    all_pages: [];
    homepage: interfacePage;
    requestpage: interfacePage;
    contactpage: interfacePage;
    accountpage: interfacePage;
    loginpage: interfacePage;
    billingpage: interfacePage;
    faqpage: interfacePage;
    validateacountpage: interfacePage;
    forgotpage: interfacePage;
    newpasswordpage: interfacePage;
}

const initialState: thisState = {
    all_pages: [],
    homepage: initialPage,
    requestpage: initialPage,
    contactpage: initialPage,
    accountpage: initialPage,
    loginpage: initialPage,
    billingpage: initialPage,
    faqpage: initialPage,
    validateacountpage: initialPage,
    forgotpage: initialPage,
    newpasswordpage: initialPage,
};

export const thisSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        setAllPages: (state, action: PayloadAction<[]>) => {
            state.all_pages = action.payload;
        },
        setHomePage: (state, action: PayloadAction<interfacePage>) => {
            state.homepage = action.payload;
        },
        setRequestPage: (state, action: PayloadAction<interfacePage>) => {
            state.requestpage = action.payload;
        },
        setContactPage: (state, action: PayloadAction<interfacePage>) => {
            state.contactpage = action.payload;
        },
        setFaqPage: (state, action: PayloadAction<interfacePage>) => {
            state.faqpage = action.payload;
        },
        setAccountPage: (state, action: PayloadAction<interfacePage>) => {
            state.accountpage = action.payload;
        },
        setLoginPage: (state, action: PayloadAction<interfacePage>) => {
            state.loginpage = action.payload;
        },
        setValidateAccountPage: (state, action: PayloadAction<interfacePage>) => {
            state.validateacountpage = action.payload;
        },
        setBillingPage: (state, action: PayloadAction<interfacePage>) => {
            state.billingpage = action.payload;
        },
        setForgotPage: (state, action: PayloadAction<interfacePage>) => {
            state.forgotpage = action.payload;
        },
        setNewPasswordPage: (state, action: PayloadAction<interfacePage>) => {
            state.newpasswordpage = action.payload;
        },
    },
});
export const {
    setAllPages,
    setHomePage,
    setContactPage,
    setRequestPage,
    setAccountPage,
    setLoginPage,
    setFaqPage,
    setValidateAccountPage,
    setBillingPage,
    setForgotPage,
    setNewPasswordPage,
} = thisSlice.actions;

export const getPages = (): AppThunk => (dispatch) => {
    APIManager.get('/items/pages?fields=*.*', '').then((data) => {
        dispatch(setAllPages(data));
        if (data !== undefined && data.length > 0) {
            data.forEach((page: interfacePage) => {
                if (page['cms_name'] === 'Homepage') {
                    dispatch(setHomePage(page));
                } else if (page['cms_name'] === 'Contact') {
                    dispatch(setContactPage(page));
                } else if (page['cms_name'] === 'Request') {
                    dispatch(setRequestPage(page));
                } else if (page['cms_name'] === 'Account') {
                    dispatch(setAccountPage(page));
                } else if (page['cms_name'] === 'Login') {
                    dispatch(setLoginPage(page));
                } else if (page['cms_name'] === 'FAQ') {
                    dispatch(setFaqPage(page));
                } else if (page['cms_name'] === 'Billing') {
                    dispatch(setBillingPage(page));
                } else if (page['cms_name'] === 'Forgot password') {
                    dispatch(setForgotPage(page));
                } else if (page['cms_name'] === 'New password') {
                    dispatch(setNewPasswordPage(page));
                }
            });
        }
    });
};

export const all_pages = (state: RootState) => state.pages.all_pages;
export const homepage = (state: RootState) => state.pages.homepage;
export const requestpage = (state: RootState) => state.pages.requestpage;
export const contactpage = (state: RootState) => state.pages.contactpage;
export const faqpage = (state: RootState) => state.pages.faqpage;
export const accountpage = (state: RootState) => state.pages.accountpage;
export const loginpage = (state: RootState) => state.pages.loginpage;
export const validateacountpage = (state: RootState) => state.pages.validateacountpage;
export const billingpage = (state: RootState) => state.pages.billingpage;
export const forgotpage = (state: RootState) => state.pages.forgotpage;
export const newpasswordpage = (state: RootState) => state.pages.newpasswordpage;

export default thisSlice.reducer;
