import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';
const APIManager = API.instance;

interface thisState {
    homepage_blocks: any;
    requestpage_blocks: any;
    faqpage_blocks: any;
}

const initialState: thisState = {
    homepage_blocks: '',
    requestpage_blocks: '',
    faqpage_blocks: '',
};

export const thisSlice = createSlice({
    name: 'webblocks',
    initialState,
    reducers: {
        setHomePageBlocks: (state, action: PayloadAction<[]>) => {
            state.homepage_blocks = action.payload;
        },
        setRequestPageBlocks: (state, action: PayloadAction<[]>) => {
            state.requestpage_blocks = action.payload;
        },
        setFaqPageBlocks: (state, action: PayloadAction<[]>) => {
            state.faqpage_blocks = action.payload;
        },
    },
});

export const { setHomePageBlocks, setRequestPageBlocks, setFaqPageBlocks } = thisSlice.actions;


export const getHomepageBlocks = (): AppThunk => dispatch => {
    APIManager.get('/items/homepage_textblocks?fields=*,photo_block_1.data.full_url.*', '').then(data => {
        dispatch(setHomePageBlocks(data));
    });
};

export const getRequestBlocks = (): AppThunk => dispatch => {
    APIManager.get('/items/request_textblocks?fields=*.*', '').then(data => {
        dispatch(setRequestPageBlocks(data));
    });
};

export const getFaqpageBlocks = (): AppThunk => dispatch => {
    APIManager.get('/items/faq?fields=*.*', '').then(data => {
        dispatch(setFaqPageBlocks(data));
    });
};

export const homepage_blocks = (state: RootState) => state.webblocks.homepage_blocks;
export const requestpage_blocks = (state: RootState) => state.webblocks.requestpage_blocks;
export const faqpage_blocks = (state: RootState) => state.webblocks.faqpage_blocks;

export default thisSlice.reducer;
