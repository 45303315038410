import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import generalReducer from '../general';
import pagesReducer from '../pages';
import authReducer from '../auth';
import webblocksReducer from '../webblocks';
import questionnaireReducer from '../questionnaire' 

export const store = configureStore({
    reducer: {
        general: generalReducer,
        pages: pagesReducer,
        webblocks: webblocksReducer,
        authenticate: authReducer,
        questionnaire: questionnaireReducer
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
