import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';

import Button from '../../../../components/button/button';

interface Props {
    data: any;
}

const DocCompleted : FC<Props> = (props) => {
    const dispatch = useDispatch();

    const item = props.data;

    const downloadPDF = () => {
        window.open(`https://beheer.taxt.nl/assets/${item.filled_tax_form.filename_disk}?download`,"_blank")
    }

    return (
        <div className="col-12 col-lg-5 offset-lg-1">
            <div className="col-12 d-flex p-0">
                <div className="status-form completed">{I18n.t('ACCOUNT_FORM_COMPLETED')}</div>
            </div>
            <div className="completed-block">
                <span className="title">
                    {I18n.t('TAX_REFUNDS_OF')} {item.year}
                </span>
                <span className="text">
                    {item.notes}
                </span>
                <div className="d-flex flex-column justify-content-center">
                    <div className="button-container">
                        <Button
                            defaultStyle
                            fullwidth
                            title={I18n.t("DOWNLOAD_TAX_REFUND")}
                            onPress={() => downloadPDF()}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DocCompleted;