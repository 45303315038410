import React, { FC } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import { useSelector } from 'react-redux';
import { generalSettings } from '../../../../redux/general';

interface Props {}

const ContactUs: FC<Props> = (props) => {
    const GENERAL_SETTINGS = useSelector(generalSettings);

    return (
        <div className='contact-us'>
            <p>
                {I18n.t('REQUEST_CONTACT')} <a href={`mailto:${GENERAL_SETTINGS.email}`}>{GENERAL_SETTINGS.email}</a>
            </p>
        </div>
    );
};

export default ContactUs;
