import React, { FC, useState, useEffect } from 'react';
import '../../../style/style.scss';
import I18n from "../../../helpers/i18n";
import { Link } from "react-router-dom";

interface Props {
    title: string,
    items: Array<{title: string, url:string}>,
}

const MenuFooter: FC<Props> = (props) => {

    return <nav className="menu-footer">
        <h5>{props.title}</h5>
        {props.items.map((item, key) => {
            return (
                <Link to={{ pathname: item.url, state: {} }}
                    className="menu-item-container"
                    key={item.title}>
                    <div className="menu-item">
                        {item.title}
                    </div>
                </Link>
            )
        })}
    </nav>;
}

export default MenuFooter;