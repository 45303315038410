import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import renderHTML from 'react-render-html';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Cookie from '../../helpers/cookie';
import CryptoJS from '../../helpers/crypto-js';
import { requestpage } from '../../redux/pages';
import { getRequestBlocks, requestpage_blocks } from '../../redux/webblocks';
import { useHistory } from 'react-router-dom';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import Loader from '../../components/loader/loader';
import { generalSettings } from '../../redux/general';
import {
    questionsAnswers,
    questionsRequest,
    dataUser,
    setSelectedWay,
    setYear,
    setBasePrice,
    setAmountByYear,
    getQuestionsAnswers,
    getQuestionsRequest,
    setQuestionsResident,
    setAccountInfo,
    setQuestionsNonResident,
    setFinished,
} from '../../redux/questionnaire';
import RequestHeader from './components/header/header';
import RequestFooter from './components/footer/footer';
import ErrorForm from './components/error_form/error_form';
import UserCreated from './components/user_created/user_created';
import PreviousQuestions from './pages/previous_questions/previous_questions';
import ResidentQuestions from './pages/resident_questions/resident_questions';
import PriceQuestions from './pages/price_questions/price_questions';
import AccountCreation from './pages/account_creation/account_creation';
import NonResidentQuestions from './pages/non_resident_questions/non_resident_questions';
import { login_status } from '../../redux/auth';
import useGaTracker from '../../navigation/useGaTracker';
import { cookieQuestionaire } from '../../constants/constants';
import ContactUs from './components/contact_us/contact_us';

const APIManager = API.instance;
const CookieManager = Cookie.instance;
const CryptoJSManager = CryptoJS.instance;
interface Props {}

const RequestPage: FC<Props> = (props) => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [loaderCreate, setLoaderCreate] = useState(false);
    const dispatch = useDispatch();
    const PAGEREQUEST = useSelector(requestpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);
    const GENERAL_SETTINGS = useSelector(generalSettings);
    const BLOCK = useSelector(requestpage_blocks);
    const QUESTIONS_ANSWERS = useSelector(questionsAnswers);
    const QUESTIONS_REQUEST = useSelector(questionsRequest);
    const DATA_USER = useSelector(dataUser);

    const [activeStep, setActiveStep] = useState(1); // 1, 2, 3, 4
    const [maxStep, setMaxStep] = useState(4);
    const [disabledNextStep, setDisabledNextStep] = useState(true);

    const [loadFromOtherForm, setLoadFromOtherForm] = useState(false);
    const [oldForms, setOldForms] = useState([]);
    const [userCreated, setUserCreated] = useState(false);
    const loginStatus = useSelector(login_status);
    /*
        errorForm:
            0 => No error
            1 => Email already exists
            2 => Error send form
            3 => Error login
    */
    const [errorForm, setErrorForm] = useState(0);

    useEffect(() => {
        setLoader(true);
        dispatch(getRequestBlocks());
        dispatch(getQuestionsAnswers());
        dispatch(getQuestionsRequest());
    }, []);

    useEffect(() => {
        SET_PAGE(PAGEREQUEST);
    }, [PAGEREQUEST]);

    useEffect(() => {
        if (
            PAGEREQUEST !== undefined &&
            GENERAL_SETTINGS !== undefined &&
            QUESTIONS_ANSWERS.length !== 0 &&
            QUESTIONS_REQUEST.length !== 0
        ) {
            setLoader(false);
        }
    }, [PAGEREQUEST, GENERAL_SETTINGS, QUESTIONS_ANSWERS, QUESTIONS_REQUEST]);

    useEffect(() => {
        dispatch(setBasePrice(parseFloat(GENERAL_SETTINGS.base_price)));
    }, [GENERAL_SETTINGS]);

    useEffect(() => {
        APIManager.isUserLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn) {
                setMaxStep(3);
            }
        });
    }, []);

    useEffect(() => {
        if (DATA_USER.selectedWay === 1) {
            setMaxStep(4);
        } else {
            setMaxStep(3);
        }
    }, [DATA_USER.selectedWay]);

    useEffect(() => {
        if (activeStep === 4 && loginStatus === 'success') {
            history.push('account');
        }
    }, [activeStep]);

    useEffect(() => {
        if (GENERAL_SETTINGS.amount_by_year === undefined) return;
        const amount: any = GENERAL_SETTINGS.amount_by_year.find(
            (a: { year: number; amount: number }) => a.year === DATA_USER.year
        );
        amount !== undefined && dispatch(setAmountByYear(amount.amount));
    }, [DATA_USER.year]);

    useEffect(() => {
        const cookie = CookieManager.getLocalStorage(cookieQuestionaire);
        if (cookie !== null) {
            const decryptInfo = CryptoJSManager.decrypt(cookie);
            const userCookie = JSON.parse(decryptInfo);

            dispatch(setYear(userCookie.year));
            dispatch(setSelectedWay(userCookie.selectedWay));
            dispatch(setBasePrice(userCookie.basePrice));
            dispatch(setAmountByYear(userCookie.amountByYear));
            dispatch(setAccountInfo(userCookie.accountInfo));
            dispatch(setQuestionsResident(userCookie.questionsResident));
            dispatch(setQuestionsNonResident(userCookie.questionsNonResident));
            dispatch(setFinished(userCookie.finished));
        } else if (loginStatus === 'success') {
            APIManager.getForms()
                .then((res) => {
                    const forms = res.data.data;
                    if (forms.length === 0) return;
                    const sorted = forms.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
                    setOldForms(sorted);
                    setLoadFromOtherForm(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [loginStatus]);

    useEffect(() => {
        if (DATA_USER.selectedWay !== 0) {
            const info = {
                selectedWay: DATA_USER.selectedWay,
                year: DATA_USER.year,
                basePrice: DATA_USER.basePrice,
                amountByYear: DATA_USER.amountByYear,
                accountInfo: DATA_USER.accountInfo,
                questionsResident: DATA_USER.questionsResident,
                questionsNonResident: DATA_USER.questionsNonResident,
                finished: DATA_USER.finished,
            };
            const infoString = JSON.stringify(info);
            const secureInfo = CryptoJSManager.encrypt(infoString);
            CookieManager.setLocalStorage(cookieQuestionaire, secureInfo);
        }
    }, [DATA_USER]);

    const handleDisabledNextStep = (state: boolean) => {
        setDisabledNextStep(state);
    };

    const registerUser = (accountInfo: any) => {
        setLoaderCreate(true);
        APIManager.register(
            accountInfo.email,
            accountInfo.password,
            accountInfo.firstName,
            accountInfo.lastName,
            accountInfo.birthdate,
            accountInfo.zipCode,
            accountInfo.houseNumber,
            accountInfo.address,
            accountInfo.city,
            accountInfo.acceptedTerms,
            accountInfo.country
        )
            .then((res: number) => {
                setLoaderCreate(false);
                if (res === 204) {
                    dispatch(setFinished(true));
                    setErrorForm(0);
                    setUserCreated(true);
                } else if (res === 400) {
                    setErrorForm(1);
                } else {
                    setErrorForm(2);
                }
            })
            .catch((err) => {
                setErrorForm(2);
                setLoaderCreate(false);
            });
    };

    useGaTracker();

    return (
        <Wrapper
            loader={loader}
            activePage={I18n.t('NAV_REQUEST')}
            circles_is_request_page
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title}
            seo_description={PAGE.seo_description}
        >
            <section className='request'>
                {loaderCreate ? (
                    <div className='container-loader-create'>
                        <Loader show={loaderCreate} />
                    </div>
                ) : (
                    <div className='container-request'>
                        {!userCreated && (
                            <RequestHeader
                                data={BLOCK}
                                activeStep={activeStep}
                                maxStep={maxStep}
                                oldForms={oldForms}
                                loadFromOtherForm={loadFromOtherForm}
                                setLoadFromOtherForm={(state) => setLoadFromOtherForm(state)}
                            />
                        )}
                        {activeStep === 1 && <PreviousQuestions disabledNextStep={handleDisabledNextStep} />}
                        {activeStep === 2 && DATA_USER.selectedWay === 1 && (
                            <ResidentQuestions
                                disabledNextStep={handleDisabledNextStep}
                                denniedText={BLOCK.text_questionaire_dennied}
                            />
                        )}
                        {activeStep === 2 && DATA_USER.selectedWay === 2 && (
                            <NonResidentQuestions
                                disabledNextStep={handleDisabledNextStep}
                                denniedResponse={BLOCK.text_questionaire_dennied}
                            />
                        )}
                        {activeStep === 3 && DATA_USER.selectedWay === 1 && (
                            <PriceQuestions
                                disabledNextStep={handleDisabledNextStep}
                                denniedText={BLOCK.text_questionaire_dennied}
                            />
                        )}
                        {activeStep === 4 && !userCreated && (
                            <AccountCreation disabledNextStep={handleDisabledNextStep} />
                        )}
                        {activeStep === 4 && userCreated && <UserCreated content={BLOCK.user_created} />}
                        {errorForm !== 0 && <ErrorForm errorForm={errorForm} />}
                        {!userCreated && (
                            <RequestFooter
                                registerUser={registerUser}
                                activeStep={activeStep}
                                disableNextStep={disabledNextStep}
                                setActiveStep={(val: number) => setActiveStep(val)}
                            />
                        )}
                        <ContactUs />
                    </div>
                )}
            </section>
        </Wrapper>
    );
};

export default RequestPage;
