import '../style/style.scss';
import I18n from './i18n';

export default class Validators {
    static instance: Validators = new Validators();

    validateString(text: string): [boolean, string] {
        const only_whitespaces = /^\s+$/;
        if (only_whitespaces.test(text)) {
            return [true, I18n.t('FORM_ERROR_REQUIRED')];
        } else {
            return [false, ''];
        }
    }

    validateZipCode(text: string): [boolean, string] {
        const zipCode = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (!zipCode.test(text)) {
            return [true, I18n.t('FORM_ERROR_ZIP')];
        } else {
            return [false, ''];
        }
    }

    validateHouseNumber(text: string): [boolean, string] {
        if (text !== '') {
            return [false, ''];
        } else {
            return [true, I18n.t('FORM_ERROR_HOUSE_NUMBER')];
        }
    }

    validateRegisterPassword(password: string): [boolean, string] {
        const number_check = /\d/;
        const special_char_check = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (password.length >= 8 && number_check.test(password) && special_char_check.test(password)) {
            return [false, ''];
        } else if (password.length < 8 || !number_check.test(password) || !special_char_check.test(password)) {
            return [true, I18n.t('FORM_PASSWORDS_INVALID')];
        } else {
            return [true, I18n.t('FORM_PASSWORDS_INVALID')];
        }
    }

    validateCreateNewPassword(newPassword: string) {
        const number_check = /\d/;
        const special_char_check = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

        if (newPassword !== '') {
            if (newPassword.length >= 8 && number_check.test(newPassword) && special_char_check.test(newPassword)) {
                return [false, '', true];
            } else {
                return [true, I18n.t('FORM_PASSWORDS_INVALID'), false];
            }
        }

        return [false, '', false];
    }

    validateConfirmNewPassword(newPassword: string, repeatNewPassword: string) {
        if (newPassword === repeatNewPassword) {
            return [false, '', repeatNewPassword !== '' ? true : false];
        }

        return [true, I18n.t('FORM_PASSWORDS_NOMATCH'), false];
    }

    validateEmail(email: string): [boolean, string] {
        var errorMessage = '';
        var errorState = false;

        if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            var emailValid = reg.test(email) === true;

            errorState = emailValid ? false : true;
            errorMessage = I18n.t('FORM_ERROR_EMAIL_INVALID');
        }

        return [errorState, errorMessage];
    }

    validateCCNumber(ccNumber: string): [boolean, string, string] {
        var errorMessage = '';
        var errorState = false;
        var formattedText = ccNumber;

        if (ccNumber.length < 16) {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_CCNUMBER_INVALID');
        }

        var v = ccNumber.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        var matches = v.match(/\d{4,17}/g);
        var match = (matches && matches[0]) || '';
        var parts = [];
        var len = 16;
        var i;
        for (i = 0, len = match.length; i < len; i += 4) {
            parts.push(match.substring(i, i + 4));
        }
        if (parts.length) {
            formattedText = parts.join(' ');
        } else {
            formattedText = v;
        }

        return [errorState, errorMessage, formattedText];
    }

    validateCCDate(ccDate: string): [boolean, string, string] {
        var errorMessage = '';
        var errorState = false;
        var formattedText = ccDate;

        var v = '';
        if (ccDate.charAt(2) === '/' && ccDate.length === 3) {
            v = ccDate;
            formattedText = v.toString().replace(/\s+/g, '');
        } else {
            v = ccDate.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
            formattedText = v.toString().replace(/\d{2}(?=.)/g, '$&/');
        }

        return [errorState, errorMessage, formattedText];
    }

    validateCVV(CVV: string): [boolean, string, string] {
        var errorMessage = '';
        var errorState = false;
        var formattedText = CVV;

        if (CVV.length < 3) {
            errorState = true;
            errorMessage = I18n.t('FORM_ERROR_CVV_INVALID');
        }
        var v = CVV.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
        formattedText = v;

        return [errorState, errorMessage, formattedText];
    }

    validateBirthdayInput(dob: string) {
        if (dob === '') {
            return [true, I18n.t('FORM_ERROR_REQUIRED')];
        } else {
            var parts = dob.split('/');
            var day = parseInt(parts[0], 10);
            var month = parseInt(parts[1], 10);
            var year = parseInt(parts[2], 10);

            var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
            if (year % 400 === 0 || (year % 100 != 0 && year % 4 === 0)) {
                monthLength[1] = 29;
            }

            var validDay = day > 0 && day < monthLength[month - 1] + 1;
            var validYear = month > 0 && month < 13;
            var validMonth = year >= 1900 && year <= 3000;

            if (validDay && validMonth && validYear) {
                return [false, ''];
            } else {
                return [true, I18n.t('FORM_DOB_INVALID')];
            }
        }
    }

    validatePhoneNumber(phone: any): [boolean, string] {
        var errorMessage = '';
        var errorState = false;
        var reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

        if (!reg.test(phone)) {
            errorState = true;
            errorMessage = I18n.t('FORM_PHONE_NOT_VALID');
        }

        return [errorState, errorMessage];
    }

    computedStringQuestionaire(question: string, year: number, amount: number): string {
        return question
            .replaceAll('{year}', year.toString())
            .replaceAll('{amount-by-year}', new Intl.NumberFormat('de-DE').format(amount));
    }
}
