import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import renderHTML from 'react-render-html';
import IconBlock from '../../../home/icon_block/icon_block';
import { useDispatch, useSelector } from 'react-redux';
import { getHomepageBlocks, homepage_blocks } from '../../../../redux/webblocks';

interface Props {
    content: string;
}

const UserCreated: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const BLOCK = useSelector(homepage_blocks);

    useEffect(() => {
        dispatch(getHomepageBlocks());
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='user-created container'>
            <div className='row d-flex justify-content-center align-items-center'>
                <div className='col-12 d-flex justify-content-center align-items-center description'>
                    {renderHTML(props.content)}
                </div>
            </div>
            <div className='container second-block'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-12 col-lg-9 text-center'>
                        {BLOCK.title_block_2 && <h2 className='h1 title'>{BLOCK.title_block_2}</h2>}
                    </div>
                </div>
                <div className='row d-flex justify-content-center'>
                    <IconBlock
                        iconImage={BLOCK.icon_1_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_1_image}`}
                        iconNumber={1}
                        title={BLOCK.icon_1_title && BLOCK.icon_1_title}
                        text={BLOCK.icon_1_text && BLOCK.icon_1_text}
                    />
                    <IconBlock
                        iconImage={BLOCK.icon_3_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_2_image}`}
                        iconNumber={2}
                        title={BLOCK.icon_2_title && BLOCK.icon_2_title}
                        text={BLOCK.icon_2_text && BLOCK.icon_2_text}
                    />
                    <IconBlock
                        iconImage={BLOCK.icon_3_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_3_image}`}
                        iconNumber={3}
                        title={BLOCK.icon_3_title && BLOCK.icon_3_title}
                        text={BLOCK.icon_3_text && BLOCK.icon_3_text}
                    />
                    <IconBlock
                        iconImage={BLOCK.icon_4_image && `https://beheer.taxt.nl/assets/${BLOCK.icon_4_image}`}
                        iconNumber={4}
                        title={BLOCK.icon_4_title && BLOCK.icon_4_title}
                        text={BLOCK.icon_4_text && BLOCK.icon_4_text}
                    />
                </div>
            </div>
        </div>
    );
};

export default UserCreated;
