import React, { useState, FC, useEffect } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import FUNCTIONS from '../../../../helpers/functions';
import { useSelector } from 'react-redux';
import { dataUser } from '../../../../redux/questionnaire';

const FunctionsManager = FUNCTIONS.instance;

interface Props {}

const CalculateCost: FC<Props> = (props) => {
    const DATA_USER = useSelector(dataUser);
    const [totalAmount, setTotalAmount] = useState(0);

    useEffect(() => {
        const basePrice = DATA_USER.basePrice;
        if (DATA_USER.selectedWay === 1) {
            const totalQuestions = DATA_USER.questionsResident
                .map((q: any) => {
                    return q.answer.amount;
                })
                .reduce((prev: number, curr: number) => prev + curr, 0);
            setTotalAmount(basePrice + totalQuestions);
        } else if (DATA_USER.selectedWay === 2) {
            const totalQuestions = DATA_USER.questionsNonResident
                .map((q: any) => {
                    return q.answer.amount;
                })
                .reduce((prev: number, curr: number) => prev + curr, 0);
            setTotalAmount(basePrice + totalQuestions);
        }
    }, [DATA_USER]);

    return (
        <div className='row d-flex justify-content-center align-items-start'>
            <div className='col-12 col-lg-8 p-lg-0 total-amount'>
                {FunctionsManager.computedStringQuestionaire(
                    I18n.t('TOTAL_PRICE'),
                    DATA_USER.year,
                    DATA_USER.amountByYear
                )}
                &nbsp;
                <span className='price'>{totalAmount}.-</span>
            </div>
        </div>
    );
};

export default CalculateCost;
