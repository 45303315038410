import React, { FC, useEffect } from 'react';
import '../../../../style/style.scss';
import Button from '../../../../components/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { dataUser, questionsRequest, setSelectedWay } from '../../../../redux/questionnaire';

interface Props {
    disabledNextStep: any;
}

const PreviousQuestions: FC<Props> = (props) => {
    const dispatch = useDispatch();
    const QUESTIONS_REQUEST = useSelector(questionsRequest);
    const DATA_USER = useSelector(dataUser);

    useEffect(() => {
        props.disabledNextStep(true);
    }, []);

    useEffect(() => {
        if (DATA_USER.selectedWay !== 0) {
            props.disabledNextStep(false);
        }
    }, [DATA_USER.selectedWay]);

    return (
        <div className='container previous-questions'>
            <div className='row d-flex flex-column justify-content-center align-items-start'>
                <div className='col-12 col-lg-8 p-lg-0 d-flex flex-row align-self-center'>
                    <div className='d-flex justify-content-between flex-column flex-lg-row gap-4'>
                        {QUESTIONS_REQUEST.length !== 0 && (
                            <>
                                <Button
                                    defaultStyle={DATA_USER.selectedWay === 1}
                                    fullwidth
                                    title={QUESTIONS_REQUEST.find((q: any) => q.id === 1).question}
                                    onPress={() => dispatch(setSelectedWay(1))}
                                />
                                <Button
                                    defaultStyle={DATA_USER.selectedWay === 2}
                                    fullwidth
                                    title={QUESTIONS_REQUEST.find((q: any) => q.id === 2).question}
                                    onPress={() => dispatch(setSelectedWay(2))}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className='col-12 col-lg-3 p-lg-0 d-none d-lg-flex position-relative'></div>
            </div>
        </div>
    );
};

export default PreviousQuestions;
