import React, { useState, FC, useEffect } from 'react';
import axios from 'axios';
import DirectusSDK from '@directus/sdk-js';
import localforage from 'localforage';
import { setupCache } from 'axios-cache-adapter';
import jwt_decode from 'jwt-decode';
import Cookie from './cookie';
import { cookieRefresh, cookieToken } from '../constants/constants';

const cookie = new Cookie();

const originUrl = 'https://';
const apiUrl = 'https://beheer.taxt.nl';
//const apiUrl = 'https://taxt-dev.tecdam.io';
const apiUser = '';
const apiPassword = '';

const cache = setupCache({
    maxAge: 5 * 60 * 1000,
    exclude: { query: false },
});

const api = axios.create({
    adapter: cache.adapter,
});

api.interceptors.request.use(
    (config) => {
        const token = cookie.getCookie(cookieToken);

        if (config !== undefined) {
            if (token !== null) {
                config.headers.common['Authorization'] = 'Bearer ' + token;
            }
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// api.defaults.headers.get[ 'Access-Control-Allow-Origin' ] = '*';
api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const originalRequest = error.config;

        if (originalRequest.url.includes('auth/login')) {
            return;
        }

        if ((error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            cookie.deleteCookie(cookieToken);

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject });
                })
                    .then((token) => {
                        originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        return axios(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = cookie.getCookie(cookieRefresh);

            return new Promise(function (resolve, reject) {
                if (refreshToken !== null || refreshToken !== undefined) {
                    axios
                        .post(`${apiUrl}/auth/refresh`, { refresh_token: refreshToken })
                        .then((response) => {
                            const token = response.data.data.access_token;
                            const refreshToken = response.data.data.refresh_token;
                            cookie.setCookie(cookieToken, token, 30);
                            cookie.setCookie(cookieRefresh, refreshToken, 30);
                            api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                            originalRequest.headers['Authorization'] = 'Bearer ' + token;
                            API.instance.storedJwt = token;

                            processQueue(null, token);
                            resolve(axios(originalRequest));
                        })
                        .catch((err) => {
                            const credentials = { email: apiUser, password: apiPassword };

                            axios
                                .post(`${apiUrl}/auth/login`, credentials)
                                .then((resAuth) => {
                                    const token = resAuth.data.data.access_token;
                                    const refreshToken = resAuth.data.data.refresh_token;
                                    cookie.setCookie(cookieToken, token, 30);
                                    cookie.setCookie(cookieRefresh, refreshToken, 30);

                                    processQueue(null, token);
                                    resolve(axios(originalRequest));
                                })
                                .catch((err) => {
                                    isRefreshing = false;
                                })
                                .finally(() => {
                                    isRefreshing = false;
                                });
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                }
            });
        }

        return error.response;
    }
);

export default class API {
    static instance: API = new API();

    directus = new DirectusSDK(apiUrl, { auth: { storage: localforage, mode: 'json' } });
    storedJwt = cookie.getCookie(cookieToken);
    jwt = null;
    fetchError = null;
    loggedIn = false;
    gettingToken = false;
    tokenPromise = new Promise<string>((resolve, reject) => {});

    isUserLoggedIn() {
        return new Promise<boolean>((resolve) => {
            try {
                let token: string = cookie.getCookie(cookieToken) ?? '';
                let decodedBody: any = jwt_decode(token, { header: false });
                if (token) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } catch (err) {
                resolve(false);
            }
        });
    }

    getJwt = async () => {
        if (this.gettingToken) {
            return;
        }
        this.gettingToken = true;
        const credentials = { email: apiUser, password: apiPassword };

        const { data } = await api.post(`${apiUrl}/auth/authenticate`, credentials);
        cookie.setCookie(cookieToken, data.data.token, 30);
        this.gettingToken = false;
        this.jwt = data.data.token;
        this.storedJwt = data.data.token;

        return data.data.token;
    };

    refreshJwt = async () => {
        try {
            if (this.storedJwt === null) {
                API.instance.getJwt();
                return;
            }
            const { data } = await api.post(`${apiUrl}/auth/refresh`, { token: this.storedJwt });
            cookie.setCookie(cookieToken, data.data.token, 30);
            this.storedJwt = data.data.token;
            this.jwt = data.data.token;
            return data.data.token;
        } catch (err) {
            this.storedJwt = null;
            cookie.deleteCookie(cookieToken);
            return null;
        }
    };

    updateJwt() {
        if (this.gettingToken) {
            return this.tokenPromise;
        }
        this.gettingToken = true;
        this.tokenPromise = new Promise<string>((resolve) => {
            if (this.storedJwt === null) {
                return this.apiUserAuthenticate();
            }
            api.post(`${apiUrl}/auth/refresh`, { token: this.storedJwt })
                .then((res) => {
                    if (res === undefined || res.status === 404) {
                        return this.apiUserAuthenticate();
                    } else {
                        this.gettingToken = false;
                        cookie.setCookie(cookieToken, res.data.data.token, 30);
                        this.storedJwt = res.data.data.token;
                        resolve(res.data.data.token);
                    }
                })
                .catch((err) => {
                    return this.apiUserAuthenticate();
                });
        });

        return this.tokenPromise;
    }

    apiUserAuthenticate() {
        if (this.gettingToken) {
            return this.tokenPromise;
        }
        this.gettingToken = true;
        this.tokenPromise = new Promise<string>((resolve) => {
            cookie.deleteCookie(cookieToken);

            const credentials = { email: apiUser, password: apiPassword };
            api.post(`${apiUrl}/auth/authenticate`, credentials)
                .then((resAuth) => {
                    this.gettingToken = false;
                    if (resAuth.status === 404) {
                        resolve('');
                    } else {
                        var token = resAuth.data.data.token;
                        cookie.setCookie(cookieToken, token, 30);
                        this.storedJwt = token;
                        resolve(token);
                    }
                })
                .catch((err) => {
                    this.gettingToken = false;
                    resolve('');
                });
        });

        return this.tokenPromise;
    }

    post = async (path: string, body?: any, urlencoded?: boolean) => {
        try {
            var parsedBody = {};
            if (body != null && body != undefined) {
                if (urlencoded) {
                    parsedBody = new URLSearchParams(body);
                    api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
                } else {
                    parsedBody = JSON.stringify(body);
                    api.defaults.headers.post['Content-Type'] = 'application/json';
                }
            }
            const { data } = await api.post(`${apiUrl}` + path, parsedBody);
            this.fetchError = null;
            if (data === undefined || data === '' || data === null) {
                return { success: true };
            }
            return data;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    patch = async (path: string, body?: any, urlencoded?: boolean) => {
        try {
            var parsedBody = {};
            if (body != null && body != undefined) {
                if (urlencoded) {
                    parsedBody = new URLSearchParams(body);
                } else {
                    parsedBody = JSON.stringify(body);
                }
            }
            const { data } = await api.patch(`${apiUrl}` + path, parsedBody);
            this.fetchError = null;
            return data;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    get = async (path: string, data: any, meta?: true) => {
        try {
            const { data } = await api.get(`${apiUrl}` + path);
            this.fetchError = null;
            return meta ? data : data.data;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    getFile = async (fileId: string) => {
        if (fileId == undefined) {
            return;
        }
        try {
            const { data } = await api.get(`${apiUrl}` + '/files/' + fileId + '?fields=data.full_url');
            this.fetchError = null;
            return apiUrl + data.data.data.asset_url;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    };

    login = async (email: string, pwd: string) => {
        return new Promise<boolean>((resolve) => {
            const credentials = { email: email, password: pwd };
            api.post(`${apiUrl}/auth/login`, credentials)
                .then((resAuth) => {
                    this.gettingToken = false;
                    if (resAuth.status !== 200) {
                        resolve(false);
                    } else {
                        var token = resAuth.data.data.access_token;
                        cookie.setCookie(cookieToken, token, 30);
                        this.storedJwt = token;
                        resolve(true);
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                    this.gettingToken = false;
                    resolve(false);
                });
        });
    };

    register = async (
        email: string,
        pwd: string,
        firstName: string,
        lastName: string,
        birthdate: string,
        zipCode: string,
        houseNumber: string,
        address: string,
        city: string,
        acceptedTerms: boolean,
        country: string
    ) => {
        return new Promise<number>((resolve) => {
            const role = '5fa52ee9-222f-491c-9089-d78a2f2b279a';
            const params = {
                email: email,
                password: pwd,
                role: role,
                first_name: firstName,
                last_name: lastName,
                date_of_birth: birthdate,
                zipcode: zipCode,
                house_number: houseNumber,
                address: address,
                location: city,
                accepted_terms: acceptedTerms,
                country: country,
            };
            api.post(`${apiUrl}/users`, params)
                .then((resAuth) => {
                    resolve(resAuth.status);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getProfile = async () => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/users/me?fields=*.*`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    validateAccount = async (salt: string) => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/check-user?salt=${salt}`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    purchase = async (
        amount: number,
        year: number,
        id: number,
        user: string,
        email: string,
        city: string,
        country: string,
        postalCode: string,
        address: string,
        idPromoCode: string
    ) => {
        const params = {
            amount: amount,
            year: year,
            id_form: id,
            user: user,
            email: email,
            city: city,
            country: country,
            postal_code: postalCode,
            address: address,
            ...(idPromoCode !== '' && { promo_code: idPromoCode }),
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/checkout`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateProfile = async (
        id: number,
        firstName: string,
        lastName: string,
        birthdate: string,
        zipCode: string,
        houseNumber: string,
        address: string,
        city: string,
        country: string
    ) => {
        const data = {
            first_name: firstName,
            last_name: lastName,
            date_of_birth: birthdate,
            zipcode: zipCode,
            house_number: houseNumber,
            address: address,
            location: city,
            country: country,
        };
        return new Promise<number>((resolve) => {
            api.patch(`${apiUrl}/users/${id}`, data)
                .then((resAuth) => {
                    resolve(resAuth.status);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getFileTypes = async () => {
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/items/file_types`)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    uploadFile = async (file: any) => {
        var bodyFormData = new FormData();
        bodyFormData.append('name', file.name);
        bodyFormData.append('file', file);

        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/files`, bodyFormData)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getTypeFiles = async () => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };

        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/items/file_types`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getForms = async () => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };

        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/items/forms?fields=*.*&fields=documents.*.*.*`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getForm = async (id: string) => {
        const params = {
            cache: {
                exclude: { query: true },
            },
        };

        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/items/forms/${id}?fields=*.*&fields=documents.*.*.*`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    createForm = async (year: number, cost: number, answers: any, formStatus: number) => {
        const form = {
            year: year,
            total_cost: cost,
            answers_form: answers,
            form_status: formStatus,
        };

        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/items/forms`, form)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateForm = async (idForm: number, documents: Array<{ documents_id: number }>) => {
        const params = {
            documents: documents,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/forms/${idForm}`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateChilds = async (idForm: number, children: any) => {
        const params = {
            children: children,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/forms/${idForm}`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateFormStatus = async (idForm: number, status: number, reasonReject?: string) => {
        const params = {
            form_status: status,
            ...(reasonReject && { rejected_reason: reasonReject }),
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/forms/${idForm}`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateFormNotes = async (idForm: number, notes: number) => {
        const params = {
            notes: notes,
        };
        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/forms/${idForm}`, params)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    uploadDocument = async (
        idUser: string,
        idForm: number,
        fileId: string,
        fileType: number,
        note: string,
        userPassport: boolean
    ) => {
        const params = {
            idUser,
            idForm,
            fileId,
            fileType,
            note,
            userPassport,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/upload-documents`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    updateDocument = async (idDocument: number, type: string, fileId: string) => {
        const document = {
            type: type,
            file: fileId,
        };

        return new Promise<any>((resolve) => {
            api.patch(`${apiUrl}/items/documents/${idDocument}`, document)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    deleteDocument = async (idDocument: number) => {
        return new Promise<any>((resolve) => {
            api.delete(`${apiUrl}/items/documents/${idDocument}`)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    sendContactForm = async (firstName: string, lastName: string, email: string, phone: String, message: string) => {
        const contactForm = {
            name: firstName,
            last_name: lastName,
            email: email,
            phone: phone,
            message: message,
        };

        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/items/contact_form`, contactForm)
                .then((resAuth) => {
                    resolve(resAuth);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    getAddressFromPostcode = async (zipcode: string, houseNumber: string) => {
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/postcode?zipcode=${zipcode}&houseNumber=${houseNumber}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    checkPromoCode = async (code: string) => {
        return new Promise<any>((resolve) => {
            api.get(`${apiUrl}/check-promo-code?code=${code}`)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    forgotPassword = async (email: string) => {
        const params = {
            email: email,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/forgot-password/forgot`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };

    createNewPassword = async (token: string, email: string, password: string) => {
        const params = {
            email: email,
            password: password,
            token: token,
        };
        return new Promise<any>((resolve) => {
            api.post(`${apiUrl}/forgot-password/reset`, params)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    resolve(404);
                });
        });
    };
}
