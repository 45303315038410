import React, { useState, FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../../style/style.scss';
import { all_pages } from '../../redux/pages';
import renderHTML from 'react-render-html';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import Button from '../../components/button/button';
import I18n from "../../helpers/i18n";
import API from '../../helpers/api';
import { generalSettings } from '../../redux/general';
import useGaTracker from '../../navigation/useGaTracker';

const APIManager = API.instance;
interface Props {
}

const TextPage : FC <Props> = (props) => {
    const ALL_PAGES = useSelector(all_pages);
    const GENERAL_SETTINGS = useSelector(generalSettings);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);
    const [loader, setLoader] = useState(true);
    const [email, setEmail] = useState('');

    useEffect(() => {
        setLoader(true);
    }, []);

    useEffect(() => {
        const location = window.location.pathname;
        const path = location.split('/')[location.split('/').length - 1];
        if (Array.isArray(ALL_PAGES)) {
            ALL_PAGES.forEach((page: interfacePage) => {
                if (sanityzeNamePage(page.cms_name) === path) {
                    SET_PAGE(page);
                    setLoader(false);
                    window.scrollTo(0, 0);
                }
            }); 
        }
    }, [ALL_PAGES, window.location.pathname]);

    useEffect(() => {
        setEmail(GENERAL_SETTINGS.email);
    }, [GENERAL_SETTINGS])

    const sanityzeNamePage = (cmsName: string) => {
        return cmsName.toLowerCase().replaceAll(' ', '-');
    }

    const mailTo = () => {
        window.location.href = `mailto:${email}`;
    }

    useGaTracker();

    return (
        <Wrapper
            loader={loader}
            has_title
            activePage={PAGE.cms_name}
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title} 
            seo_description={PAGE.seo_description}
        >
            <div className={`container text-page ${PAGE.show_page_banner && 'page-with-header'}`}>
                <div className="row">
                    <div className="col-12">
                        {renderHTML(PAGE.text)}
                    </div>
                </div>
            </div>
            {
                sanityzeNamePage(PAGE.cms_name) === 'about-us' &&
                    <div className="container text-page container-contact">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-end">
                                <Button defaultStyle showArrow={true} title={I18n.t('NAV_CONTACT')} onPress={mailTo}/>
                            </div>
                        </div>
                    </div>
            }
        </Wrapper>
    );
}

export default TextPage;