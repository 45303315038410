import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import Circle from '../../images/circle_bottom.png';
import Button from '../../components/button/button';

interface Props {
    title?: string,
    buttonText?: string,
    buttonArrow?: boolean,
    buttonUrl?: string
}

const Footer : FC<Props> = (props) => {
    const dispatch = useDispatch();

    return (
        <div className="container-md mx-md-auto mx-0 px-md-auto px-0">
            <div className="row m-0">
                <div className="col-12 banner-container p-0">
                    <div className="bottom-banner d-lg-flex justify-content-between">
                        <h3>{props.title}</h3>
                        <div className="d-flex justify-content-center d-lg-block">
                            <Button defaultStyle showArrow={props.buttonArrow} title={props.buttonText ? props.buttonText : ''} path={props.buttonUrl}/>
                        </div>
                    </div>
                    <div className="image-container d-flex justify-content-center align-items-center d-lg-block">
                        <img src={Circle} className="circle"/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Footer;