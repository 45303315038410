import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import '../../style/style.scss';
import MenuFooter from './menu/menuFooter';
import CookieConsent from 'react-cookie-consent';
import I18n from '../../helpers/i18n';
import renderHTML from 'react-render-html';
import { generalSettings } from '../../redux/general';
import maestro from '../../images/maestro.png';
import visa from '../../images/visa.png';
import mastercard from '../../images/mastercard.png';
import paypal from '../../images/paypal.png';
import ideal from '../../images/ideal.png';

var parse = require('html-react-parser');

const Footer: FC = (props) => {
    const { footer_text } = useSelector(generalSettings);

    const menuSecurity = [
        {
            title: I18n.t('NAV_TERMS_AND_CONDITIONS'),
            url: '/terms-and-conditions',
        },
        {
            title: I18n.t('NAV_COPYRIGHT_NOTICE'),
            url: '/copyright-notice',
        },
        {
            title: I18n.t('NAV_COOKIE_POLICY'),
            url: '/cookie-policy',
        },
        {
            title: I18n.t('NAV_PRIVACY_STATEMENT'),
            url: '/privacy-statement',
        },
    ];

    const menuCustomer = [
        {
            title: I18n.t('NAV_ABOUT'),
            url: '/about-us',
        },
        {
            title: I18n.t('NAV_FAQ'),
            url: '/faq',
        },
        {
            title: I18n.t('NAV_CONTACT'),
            url: '/contact',
        },
    ];

    return (
        <footer>
            <CookieConsent
                location='bottom'
                buttonText={I18n.t('COOKIE_BAR_BUTTON')}
                cookieName='taxt-consent'
                expires={150}
                debug={false}
            >
                {I18n.t('COOKIE_BAR_TEXT')}
            </CookieConsent>
            <div className='container'>
                <div className='row row-sup'>
                    <div className='col-12 col-lg-6 p-0'>
                        <h4>{I18n.t('SITENAME')}</h4>
                        {footer_text && renderHTML(footer_text)}
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 p-0'>
                        <MenuFooter title={I18n.t('FOOTER_MENU_SECURITY')} items={menuSecurity} />
                    </div>
                    <div className='col-12 col-md-6 col-lg-3 p-0'>
                        <MenuFooter title={I18n.t('FOOTER_MENU_CUSTOMER')} items={menuCustomer} />
                    </div>
                </div>
                <div className='row row-bottom'>
                    <div className='col-12 col-sm-6 order-2 order-sm-1 p-0'>
                        <span>
                            ©{new Date().getFullYear()} {I18n.t('SITENAME')} B.V.
                        </span>
                    </div>
                    <div className='col-12 col-sm-6 order-1 order-sm-2 p-0'>
                        <div className='col-12 col-payments mb-3 d-flex justify-content-sm-end justify-content-start'>
                            <img src={ideal} alt='Ideal' />
                            <img src={paypal} alt='Paypal' />
                            <img src={mastercard} alt='Mastercard' />
                            <img src={visa} alt='Visa' />
                            <img src={maestro} alt='Maestro' />
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
export default Footer;
