import React, { FC, useEffect, useState } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import TextInput from '../../../../components/textinput/textinput';
import Button from '../../../../components/button/button';
import API from '../../../../helpers/api';

const APIManager = API.instance;

interface Props {
    updateChilds: any
    form: any
}

const Children : FC<Props> = (props) => {
    const [children, setChildren] = useState([{name: '', birthday: ''}]);

    useEffect(() => {
        if (props.form.children) {
            setChildren(props.form.children)
        }
    }, [])

    const updateChildren = (type: string, val: string, index: number) => {
        let childs = [...children];
        let update = {
            ...childs[index],
            [type]: val
        }
        childs[index] = update;
        setChildren(childs)
    }

    const addChild = () => {
        setChildren([
            ...children,
            {name: '', birthday: ''}
        ])
    }

    const saveChildren = () => {
        APIManager.updateChilds(props.form.id, children)
            .then(res => {
                if(res.status === 200) {
                    props.updateChilds()
                }
            })
            .catch(err => console.log(err))
    }

    return (
        <div 
            className="children"
            onClick={(e) => e.stopPropagation()} >
                {
                    children.map((child, index) => {
                        return (
                            <div className="row">
                                <h5>{`${I18n.t('ACCOUNT_CHILD')} ${index + 1}`}</h5>
                                <div className="col-12 col-lg-6">
                                    <TextInput 
                                        label={I18n.t('ACCOUNT_NAME')}
                                        value={child.name}
                                        error={false}
                                        errorText={''}
                                        onChange={(val) => updateChildren('name', val.target.value, index)} />
                                </div>
                                <div className="col-12 col-lg-6">
                                    <TextInput 
                                        label={I18n.t('ACCOUNT_BIRTHDAY')}
                                        value={child.birthday}
                                        error={false}
                                        errorText={''}
                                        onChange={(val) => updateChildren('birthday', val.target.value, index)} />
                                </div>
                            </div>
                        )
                    })
                }
                <div className="row">
                    <div className="col-12 col-lg-6 mb-2">
                        <Button 
                            title={I18n.t('ACCOUNT_ADD_CHILD')} 
                            defaultStyle 
                            fullwidth
                            onPress={addChild} />
                    </div>
                    <div className="col-12 col-lg-6">
                        <Button 
                            title={I18n.t('ACCOUNT_SAVE_CHILDREN')} 
                            defaultStyle 
                            fullwidth
                            onPress={saveChildren} />
                    </div>
                </div>
        </div>
    );
}
export default Children;