import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import { loginpage } from '../../redux/pages';
import TextInput from '../../components/textinput/textinput';
import Button from '../../components/button/button';
import { clearLoginStatus, doLogin, login_status } from '../../redux/auth';
import { Link, useHistory } from 'react-router-dom';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import useDebounce from '../../hooks/use-debounce';
import Loader from '../../components/loader/loader';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const LoginPage: FC<Props> = (props) => {
    const history = useHistory();

    const [loader, setLoader] = useState(false);
    const [loaderForm, setLoaderForm] = useState(false);
    const dispatch = useDispatch();
    const PAGELOGIN = useSelector(loginpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);

    const [account, setAccount] = useState('');
    const [accountError, setAccountError] = useState(false);
    const [accountErrorText, setAccountErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    const loginStatus = useSelector(login_status);

    useEffect(() => {
        setLoader(true);
    }, []);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGELOGIN);
    }, [PAGELOGIN]);

    useEffect(() => {
        if (loginStatus === 'success') {
            dispatch(clearLoginStatus());
            setAccountError(false);
            setPasswordError(false);
            history.push('/account');
        } else if (loginStatus !== '') {
            setAccountError(true);
            setAccountErrorText(loginStatus);
        }
        setLoaderForm(false);
    }, [loginStatus]);

    const handleSubmit = (e: any) => {
        e.preventDefault();

        let valAccount = Validators.validateString(account);
        let valPassword = Validators.validateString(password);

        if (!valAccount[0]) {
            setAccountError(valAccount[0]);
            setAccountErrorText(valAccount[1]);
        } else {
            return;
        }

        if (!valPassword[0]) {
            setPasswordError(valPassword[0]);
            setPasswordErrorText(valPassword[1]);
        } else {
            return;
        }

        sendForm();
    };

    const sendForm = () => {
        setLoaderForm(true);
        dispatch(doLogin(account, password));
    };

    const debouncedAccountTerm = useDebounce(account, 400);
    useEffect(() => {
        if (account !== '') {
            let valField = Validators.validateString(account);
            setAccountError(valField[0]);
            setAccountErrorText(valField[1]);
        }
    }, [debouncedAccountTerm]);

    const debouncedPasswordTerm = useDebounce(password, 400);
    useEffect(() => {
        if (password !== '') {
            let valField = Validators.validateString(password);
            setPasswordError(valField[0]);
            setPasswordErrorText(valField[1]);
        }
    }, [debouncedPasswordTerm]);

    return (
        <Wrapper loader={loader} activePage={I18n.t('NAV_SIGNIN')} has_title bottom_banner_button_arrow page={PAGE}>
            <section className='login'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-login'>
                            {loaderForm ? (
                                <div className='container-loader'>
                                    <Loader show={loaderForm} />
                                </div>
                            ) : (
                                <>
                                    <form onSubmit={handleSubmit}>
                                        <TextInput
                                            label={I18n.t('FORM_EMAIL_OR_USERNAME')}
                                            value={account}
                                            error={accountError}
                                            errorText={accountErrorText}
                                            onChange={(val: any) => setAccount(val.target.value)}
                                            border
                                        />
                                        <TextInput
                                            label={I18n.t('FORM_PASSWORD')}
                                            value={password}
                                            error={passwordError}
                                            errorText={passwordErrorText}
                                            onChange={(val: any) => setPassword(val.target.value)}
                                            border
                                            hiddenText
                                        />
                                        <div className='container-button'>
                                            <Button isButton defaultStyle showArrow title='Login' />
                                        </div>
                                    </form>
                                    <div className='container-actions'>
                                        <span>
                                            <Link
                                                to={{
                                                    pathname: '/forgot-password',
                                                    state: {},
                                                }}
                                                className='login-forgot'
                                            >
                                                {I18n.t('LOGIN_FORGOT')}
                                            </Link>
                                        </span>
                                        <span>
                                            {I18n.t('LOGIN_NO_ACCOUNT')}&nbsp;
                                            <Link to={{ pathname: '/request', state: {} }}>
                                                {I18n.t('LOGIN_REQUEST_SERVICES')}
                                            </Link>
                                        </span>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};

export default LoginPage;
