import React, { useState, FC, useEffect } from 'react';
import renderHTML from 'react-render-html';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../style/style.scss';
import Question from '../../components/question/question';
import { dataUser, questionsAnswers, questionsRequest, setAmountByYear } from '../../../../redux/questionnaire';
import CalculateCost from '../../components/calculate_cost/calculate_cost';
import { generalSettings } from '../../../../redux/general';

interface Props {
    disabledNextStep: any;
    denniedText: any;
}

const ResidentQuestions: FC<Props> = (props) => {
    const [accepted, setAccepted] = useState(false);
    const [dennied, setDennied] = useState(false);
    const dispatch = useDispatch();
    const GENERAL_SETTINGS = useSelector(generalSettings);
    const QUESTIONS_REQUEST = useSelector(questionsRequest);
    const QUESTIONS_ANSWERS = useSelector(questionsAnswers);
    const DATA_USER = useSelector(dataUser);

    useEffect(() => {
        props.disabledNextStep(true);
    }, []);

    useEffect(() => {
        setDennied(false);
        props.disabledNextStep(true);
        if (DATA_USER.year === 1970) {
            setDennied(true);
            return;
        }

        const question5 = DATA_USER.questionsResident.find((q: any) => q.question.id === 5);
        if (question5 && question5.answer.id === 5) {
            setDennied(true);
            return;
        }

        const question8 = DATA_USER.questionsResident.find((q: any) => q.question.id === 8);
        const amount: any = GENERAL_SETTINGS.amount_by_year.find(
            (a: { year: number; amount: number }) => a.year === DATA_USER.year
        );
        if (!amount || !amount.amount) return;
        if (question8 && question8.answer.id === 2) {
            dispatch(setAmountByYear(amount.amount));
        } else if (question8 && question8.answer.id === 1) {
            dispatch(setAmountByYear(amount.amount * 2));
        }
        // const question6 = DATA_USER.questionsResident.find((q: any) => q.question.id === 6);
        // if (question6 && question6.answer.id === 1) {
        //     setDennied(true);
        //     return;
        // }

        // const question7 = DATA_USER.questionsResident.find((q: any) => q.question.id === 7);
        // if (question7 && question7.answer.id === 1) {
        //     setDennied(true);
        //     return;
        // }

        const question20 = DATA_USER.questionsResident.find(
            (q: any) => q.question.id === 20 && [2].includes(q.answer.id)
        );
        const question29 = DATA_USER.questionsResident.find((q: any) => q.question.id === 29);
        if (question20 || question29) {
            props.disabledNextStep(false);
        }
    }, [DATA_USER]);

    // Scroll to active question
    useEffect(() => {
        const className = `question-container`;
        const elements = document.getElementsByClassName(className);
        if (elements && elements[elements.length - 2] && elements[elements.length - 2].id !== 'question-47') {
            elements[elements.length - 1].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center',
            });
        }
    }, [DATA_USER.questionsResident]);

    return (
        <div className='container'>
            <div className='row d-flex justify-content-center align-items-center flex-column'>
                <div className='col-12 col-lg-8 p-lg-0'>
                    {/* 1 What year... */}
                    <Question question={QUESTIONS_REQUEST.find((q: any) => q.id === 3)} answers={[]} />
                    {
                        // 2 Do you have a fiscal partner* with whom you want to file jointly?
                        DATA_USER.year !== 0 && DATA_USER.year !== 1970 && (
                            <Question
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 8)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 3 Did you have an active 30% ruling in 2020?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 8) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 4)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2, 3].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 4 Received income from a Dutch-based employer in {year}?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 4 && [2, 3].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 5)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 4, 5].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 5 Net Box 3 assets exceeding € {amount-by-year} per 1/1/{year}?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 5 && [1, 4].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 6)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 6 Please specify the Box 3 assets for you and your fiscal partner per 1/1/{year} via the questions below.
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 6 && [1].includes(q.answer.id)
                            ) && <Question question={QUESTIONS_REQUEST.find((q: any) => q.id === 48)} answers={[]} />
                    }
                    {
                        // 7 Funds on Dutch bank accounts (current and saving accounts) (if applicable for partner)
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 6 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 30)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 8 How many different bank accounts? (each IBAN is one account)
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 30 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 31)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 9 Funds on foreign bank accounts (current and saving accounts);
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 30 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 31)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 32)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 10 How many different bank accounts? each IBAN or account number is one account
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 32 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 33)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 11 Investment accounts (including stock portfolios);
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 32 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 33)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 34)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 12 How many different investment accounts?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 34 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 35)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 13 Cryptocurrencies;
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 34 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 35)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 36)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 14 How many crypto wallets? (each Wallet or account number is one account)
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 36 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 37)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 15 Investment real-estate;
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 36 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 37)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 38)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 16 How many properties?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 38 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 39)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 17 Second or holiday houses;
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 38 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 39)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 40)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 18 How many properties?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 40 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 41)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 19 Loans to third persons
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 40 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 41)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 42)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 20 How many different loans did you provide?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 42 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 43)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 21 Value of future tax-free receivables from capital insurances;
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 42 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 43)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 44)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 22 How many different capital insurance accounts (each account number is one account)?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 44 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 45)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 23 Debts (excluding mortgage loans for a primary residency)?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 44 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 45)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 49)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 24 Please specify your number of debts:
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 49 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 50)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 25 Other (please specify)
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 49 && [2].includes(q.answer.id)
                            ) ||
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 50)) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 46)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 26 Please specify your other assets:
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 46 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 47)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 27 Multiquestion
                        // prettier-ignore
                        DATA_USER.year !== 0 &&
                        DATA_USER.year !== 1970 &&
                        (
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) ||
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 6 && [2].includes(q.answer.id)) ||
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 46 && [2].includes(q.answer.id)) ||
                            DATA_USER.questionsResident.find((q: any) => q.question.id === 47 )
                        ) && 
                        (
                            <Question
                                question={QUESTIONS_REQUEST.find((q: any) => q.id === 20)}
                                answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                            />
                        )
                    }
                    {
                        // 28 Multiquestion - Paid disability insurance premiums?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 20 && [1].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 22)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 29 Multiquestion - Paid annuity insurance premiums (private pension plan)?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 22 && [1, 2].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 23)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 30 Multiquestion - Paid or received partner alimony? (paid child alimony is not relevant)
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 23 && [1, 2].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 24)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 31 Multiquestion - Paid games of chance tax (kansspelbelasting)?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 24 && [1, 2].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 25)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 32 Multiquestion - Paid dividend taxes?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 25 && [1, 2].includes(q.answer.id)
                            ) &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 4 && [2, 3].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 26)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 33 Multiquestion - Paid study costs exceeding € 250?
                        // prettier-ignore
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.year < 2022 &&
                            (DATA_USER.questionsResident.find((q: any) => q.question.id === 26) ||
                                (DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) &&
                                DATA_USER.questionsResident.find((q: any) => q.question.id === 25))
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 27)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 34 Multiquestion - Donations > 1% of your income to an ANBI charity?
                        // prettier-ignore
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            (DATA_USER.questionsResident.find((q: any) => q.question.id === 27) ||
                            DATA_USER.year >= 2022 && DATA_USER.questionsResident.find((q: any) => q.question.id === 26) ||
                            (DATA_USER.year >= 2022 && DATA_USER.questionsResident.find((q: any) => q.question.id === 26) && DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) ||
                            DATA_USER.year >= 2022 && DATA_USER.questionsResident.find((q: any) => q.question.id === 25))
                                // (DATA_USER.year >= 2022 && DATA_USER.questionsResident.find((q: any) => q.question.id === 26)) ||
                                // (DATA_USER.year >= 2022 && DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [2, 3].includes(q.answer.id))) && DATA_USER.questionsResident.find((q: any) => q.question.id === 27)||
                                // (!DATA_USER.questionsResident.find((q: any) => q.question.id === 4 && [1].includes(q.answer.id)) &&  DATA_USER.questionsResident.find((q: any) => q.question.id === 25))
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 28)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }
                    {
                        // 35 Multiquestion - Healthcare costs (excluding insurance premiums and own  risk) > 1,65% of your income, not covered by insurance?
                        DATA_USER.year !== 0 &&
                            DATA_USER.year !== 1970 &&
                            DATA_USER.questionsResident.find(
                                (q: any) => q.question.id === 28 && [1, 2].includes(q.answer.id)
                            ) && (
                                <Question
                                    question={QUESTIONS_REQUEST.find((q: any) => q.id === 29)}
                                    answers={QUESTIONS_ANSWERS.filter((a: any) => [1, 2].includes(a.id))}
                                />
                            )
                    }

                    {dennied && (
                        <div className={'questionaire-status col-12 col-lg-10 p-lg-0'}>
                            {renderHTML(props.denniedText)}
                        </div>
                    )}
                </div>
                {DATA_USER.year !== 0 && DATA_USER.year !== 1979 && !dennied && <CalculateCost />}
                <div className='col-12 col-lg-3 p-lg-0 d-none d-lg-flex position-relative'></div>
            </div>
        </div>
    );
};

export default ResidentQuestions;
