import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';
import I18n from '../../helpers/i18n';
import Cookie from '../../helpers/cookie';
import { cookieToken } from '../../constants/constants';

const APIManager = API.instance;
const CookieManager = Cookie.instance;

interface thisState {
    user: any;
    request_status: any;
    login_status: any;
}

const initialState: thisState = {
    user: {},
    request_status: '',
    login_status: '',
};

export const thisSlice = createSlice({
    name: 'authenticate',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setRequestMembershipStatus: (state, action: PayloadAction<any>) => {
            state.request_status = action.payload;
        },
        setLoginStatus: (state, action: PayloadAction<any>) => {
            state.login_status = action.payload;
        },
    },
});
export const { setUser, setRequestMembershipStatus, setLoginStatus } = thisSlice.actions;

export const doLogin =
    (email: string, password: string): AppThunk =>
    (dispatch) => {
        dispatch(setLoginStatus(''));
        APIManager.login(email, password)
            .then((loggedIn) => {
                if (loggedIn) {
                    dispatch(setLoginStatus('success'));
                } else {
                    dispatch(setLoginStatus(I18n.t('LOGIN_ERROR_NOTFOUND')));
                }
            })
            .catch((error) => {
                dispatch(setLoginStatus(I18n.t('LOGIN_ERROR_NOTFOUND')));
            });
    };

export const clearLoginStatus = (): AppThunk => (dispatch) => {
    dispatch(setLoginStatus(''));
};

export const doLogout = (): AppThunk => (dispatch) => {
    CookieManager.deleteCookie(cookieToken);
    dispatch(setLoginStatus(''));
};

export const request_status = (state: RootState) => state.authenticate.request_status;
export const user = (state: RootState) => state.authenticate.user;
export const login_status = (state: RootState) => state.authenticate.login_status;

export default thisSlice.reducer;
