import React, { FC, useEffect, useState } from 'react';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';
import API from '../../../../helpers/api';
import DeleteIcon from '../../../../images/close_red.png';
import Loader from '../../../../components/loader/loader';
import TextInput from '../../../../components/textinput/textinput';
import CheckBox from '../../../../components/checkbox/checkbox';
import FUNCTIONS from '../../../../helpers/functions';
import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import { generalSettings } from '../../../../redux/general';
import renderHTML from 'react-render-html';

const FunctionsManager = FUNCTIONS.instance;
const APIManager = API.instance;

interface Props {
    closeModalUpload: any;
    updateDocument: any;
    visible: boolean;
    form: any;
    user: any;
    selectedFileType: any;
}

const ModalUpload: FC<Props> = (props) => {
    const { email, text_error_upload } = useSelector(generalSettings);
    const [loader, setLoader] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File>();
    const [selectedFileError, setSelectedFileError] = useState(false);
    const [noteText, setNoteText] = useState('');
    const [errorSendData, setErrorSendData] = useState(false);
    const [ownPassport, setOwnPassport] = useState(false);
    const idRand = Math.random();

    const onFileChange = (e: any) => {
        const value = e.target.files[0];
        setSelectedFile(value);

        if (value === undefined) {
            setSelectedFileError(true);
        } else {
            setSelectedFileError(false);
        }
    };

    const onTextAreaChange = (e: any) => {
        const value = e.target.value;
        setNoteText(value);
    };

    const handleOwnPassport = () => {
        setOwnPassport(!ownPassport);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        let selectedFileErrorTemp = false;
        let selectedFileTypeErrorTemp = false;

        if (selectedFile === undefined) {
            selectedFileErrorTemp = true;
        }

        setSelectedFileError(selectedFileErrorTemp);

        if (!selectedFileErrorTemp && !selectedFileTypeErrorTemp) {
            setErrorSendData(false);
            setLoader(true);
            uploadFile();
        }
    };

    const uploadFile = () => {
        APIManager.uploadFile(selectedFile)
            .then((res) => {
                if (res.status === 200) {
                    const fileId = res.data.data.id;
                    createDocument(fileId);
                } else {
                    Sentry.captureException(res);
                    setLoader(false);
                    setErrorSendData(true);
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                setLoader(false);
                setErrorSendData(true);
            });
    };

    const createDocument = (fileId: string) => {
        const idUser = props.user.id;
        const idForm = props.form.id;
        const fileType = props.selectedFileType.id;

        APIManager.uploadDocument(idUser, idForm, fileId, fileType, noteText, ownPassport)
            .then((res) => {
                if (res.status === 200) {
                    setLoader(false);
                    props.updateDocument();
                    props.closeModalUpload();
                } else {
                    Sentry.captureException(res);
                    setLoader(false);
                    setErrorSendData(true);
                }
            })
            .catch((err) => {
                Sentry.captureException(err);
                setLoader(false);
                setErrorSendData(true);
            });
    };

    const closeModal = (e: any) => {
        e.preventDefault();
        props.closeModalUpload();
    };

    const parseTextError = (text: string) => {
        const emailString = `<a href="mailto:${email}">${email}</a>`;
        return text.replace('{email}', emailString).replace('{formId}', props.form.id);
    };

    return (
        <div className={`modal-upload ${props.visible ? 'visible-modal' : ''}`}>
            <div className='modal-dialog'>
                <div className='modal-content'>
                    <form>
                        <div className='modal-body'>
                            <h3>{I18n.t('ACCOUNT_MODAL_UPLOAD')}</h3>
                            {props.selectedFileType && (
                                <h4>{`${I18n.t('FORM_LABEL_FILE_TYPE')}: ${FunctionsManager.computedStringQuestionaire(
                                    props.selectedFileType.type,
                                    props.form.year,
                                    props.form.answers_form.amountByYear
                                )}`}</h4>
                            )}
                            {loader ? (
                                <Loader show={loader} />
                            ) : (
                                <>
                                    <div className='container-input'>
                                        <label
                                            htmlFor={`file-${idRand}`}
                                            className={`custom-file-upload 
                                            ${selectedFileError && 'error-input'}
                                            ${props.selectedFileType && ' new-form-input'}`}
                                        >
                                            {I18n.t('FORM_LABEL_FILE')}
                                        </label>

                                        <input id={`file-${idRand}`} type='file' onChange={onFileChange} />
                                    </div>
                                    {selectedFile && (
                                        <span className='file-selected'>
                                            {`${I18n.t('FORM_LABEL_FILE_ADDED')}: ${selectedFile.name}`}
                                            <button
                                                className='button-delete-file'
                                                onClick={(e) => setSelectedFile(undefined)}
                                            >
                                                <img src={DeleteIcon} alt='Delete' />
                                            </button>
                                        </span>
                                    )}
                                    {props.selectedFileType.id === 1 && (
                                        <CheckBox
                                            questionID={0}
                                            answerID={undefined}
                                            label={I18n.t('ACCOUNT_OWN_PASSPORT')}
                                            checked={false}
                                            group={''}
                                            toggleCheck={handleOwnPassport}
                                        />
                                    )}
                                    {
                                        <div className='container-note-text'>
                                            <TextInput
                                                label={I18n.t('FORM_SET_NOTE')}
                                                value={noteText}
                                                onChange={onTextAreaChange}
                                                border
                                                textArea
                                                numberofRows={5}
                                                error={false}
                                                errorText={''}
                                            />
                                        </div>
                                    }
                                    {selectedFileError ? (
                                        <span className='error-field'>* {I18n.t('FORM_ERROR_REQUIRED')}</span>
                                    ) : null}
                                    {errorSendData && (
                                        <>
                                            <span className='error-field'>* {I18n.t('FORM_SAVE_ERROR')}</span>
                                            <p>{renderHTML(parseTextError(text_error_upload))}</p>
                                        </>
                                    )}
                                    <div className='modal-footer'>
                                        <div onClick={closeModal}>
                                            <Button isButton defaultStyle title={I18n.t('ACCOUNT_MODAL_CLOSE')} />
                                        </div>
                                        <div onClick={handleSubmit}>
                                            <Button isButton defaultStyle title={I18n.t('FORM_UPLOAD')} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default ModalUpload;
