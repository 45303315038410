import React, { FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from '../../../../helpers/i18n';
import Button from '../../../../components/button/button';

interface Props {
    closeModalDescription: any,
    visible: boolean,
    text: string
}

const ModalDescription : FC<Props> = (props) => {
    return (
        <div className={`modal-description ${props.visible ? 'visible-modal' : ''}`}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        {props.text}
                    </div>
                    <div className="modal-footer">
                        <div onClick={props.closeModalDescription}>
                            <Button isButton title={I18n.t('ACCOUNT_MODAL_CLOSE')} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ModalDescription;