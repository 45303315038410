import React, { useState, FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import I18n from "../../helpers/i18n";
import '../../style/style.scss';
import { contactpage } from '../../redux/pages';
import renderHTML from 'react-render-html';
import ContactForm from './contact_form/contact_form';
import { initialPage, interfacePage } from '../../models/page';
import Wrapper from '../../components/wrapper/wrapper';
import useGaTracker from '../../navigation/useGaTracker';

interface Props { }

const ContactPage: FC<Props> = (props) => {
    const PAGECONTACT = useSelector(contactpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true);
    }, []);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGECONTACT)
    }, [PAGECONTACT]);

    useGaTracker();

    return (
        <Wrapper
            loader={loader}
            activePage={I18n.t('NAV_CONTACT')}
            has_subtitle_banner
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title} 
            seo_description={PAGE.seo_description}
        >
            <div>
                <section className="contact-form">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 col-info">
                                <div className="container-info">
                                    {renderHTML(PAGE.text)}
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-form">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Wrapper>
    );
};

export default ContactPage;
