import React, { useState, FC, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../style/style.scss';
import I18n from '../../helpers/i18n';
import API from '../../helpers/api';
import Cookie from '../../helpers/cookie';
import CryptoJS from '../../helpers/crypto-js';
import { useHistory } from 'react-router-dom';
import Menu from './components/menu/menu';
import { doLogout, login_status, user } from '../../redux/auth';
import DocCompleted from './components/document/doc_completed';
import { initialPage, interfacePage } from '../../models/page';
import { accountpage } from '../../redux/pages';
import Wrapper from '../../components/wrapper/wrapper';
import FormTax from './components/form_tax/form_tax';
import NewForm from './components/new_form/new_form';
import Loader from '../../components/loader/loader';
import { getQuestionsAnswers, getQuestionsRequest } from '../../redux/questionnaire';
import ChatwootWidget from './components/chatWootWidget/chatWootWidget';
import { cookieQuestionaire } from '../../constants/constants';

const APIManager = API.instance;
const CookieManager = Cookie.instance;
const CryptoJSManager = CryptoJS.instance;

interface Props {}

interface form {
    id: number;
    status: string;
    date_created: string;
    date_updated: string;
    year: number;
    amount: number;
    notes: string;
    user_created: any;
    user_updated: any;
    form_status: {
        id: number;
        status: string;
    };
    first_name: any;
    documents: any;
    filled_tax_form: string;
}

const AccountPage: FC<Props> = (props) => {
    const [TYPE_FILES, SET_TYPE_FILES] = useState([]);
    const [loader, setLoader] = useState(false);
    const [loaderForms, setLoaderForms] = useState(true);
    const [passportInfo, setPassportInfo] = useState({ validated: false, expiryDate: new Date('January 1, 1970') });
    const dispatch = useDispatch();
    const history = useHistory();
    const PAGEOVERVIEW = useSelector(accountpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);
    const [user, setUser] = useState<undefined | any>(undefined);
    const [forms, setForms] = useState<form[] | undefined>(undefined);
    const [errorDelete, setErrorDelete] = useState(false);

    useEffect(() => {
        setLoader(true);
        dispatch(getQuestionsAnswers());
        dispatch(getQuestionsRequest());
    }, []);

    useEffect(() => {
        APIManager.getProfile()
            .then((res) => {
                if (res.status === 200) {
                    const { validated_passport, expiry_date_passport } = res.data.data;
                    setUser(res.data.data);
                    setPassportInfo({
                        validated: validated_passport,
                        expiryDate: expiry_date_passport,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        APIManager.getTypeFiles()
            .then((res) => {
                if (res.status === 200) {
                    SET_TYPE_FILES(res.data.data);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        SET_PAGE(PAGEOVERVIEW);
    }, [PAGEOVERVIEW]);

    useEffect(() => {
        if (TYPE_FILES.length > 0) {
            setLoader(false);
        }
    }, [TYPE_FILES]);

    useEffect(() => {
        APIManager.isUserLoggedIn().then((loggedIn: boolean) => {
            if (loggedIn === false) {
                dispatch(doLogout());
                history.push('/sign-in');
            }
        });

        getForms();
    }, []);

    useEffect(() => {
        const BASE_URL = 'https://app.chatwoot.com';
        const SRC = '/packs/js/sdk.js';

        const removejscssfile = (filename: string) => {
            var tags = document.getElementsByTagName('script');
            for (var i = tags.length; i >= 0; i--) {
                //search backwards within nodelist for matching elements to remove
                // @ts-ignore
                if (
                    tags[i] &&
                    tags[i].getAttribute('src') !== null &&
                    // @ts-ignore
                    tags[i].getAttribute('src').indexOf(filename) !== -1
                )
                    // @ts-ignore
                    tags[i].parentNode.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
            }
        };

        function removeElementsByClass(className: string) {
            const elements = document.getElementsByClassName(className);
            while (elements.length > 0) {
                // @ts-ignore
                elements[0].parentNode.removeChild(elements[0]);
            }
        }

        return () => {
            removejscssfile(BASE_URL + SRC);
            // removeElementsByClass('woot-widget-holder');
            // removeElementsByClass('woot--bubble-holder');
        };
    }, []);

    useEffect(() => {
        const cookie = CookieManager.getLocalStorage(cookieQuestionaire);
        if (cookie !== null) {
            const decryptInfo = CryptoJSManager.decrypt(cookie);
            const infoUser = JSON.parse(decryptInfo);
            console.log(infoUser);
            if (forms !== undefined) {
                const found = forms.find((f) => f.year === infoUser.year);
                if (!infoUser.finished) {
                    return;
                } else if (found) {
                    CookieManager.deleteLocalStorage(cookieQuestionaire);
                } else {
                    createForm(infoUser);
                }
            }
        }
    }, [forms]);

    const createForm = (infoUser: any) => {
        const { year } = infoUser;
        const total = calculateTotal(infoUser);

        const answers = {
            basePrice: infoUser.basePrice,
            amountByYear: infoUser.amountByYear,
            selectedWay: infoUser.selectedWay,
            questionsResident: infoUser.questionsResident,
            questionsNonResident: infoUser.questionsNonResident,
        };

        const formStatus = infoUser.questionsResident?.find((q: any) => q.question.id === 47) ? 9 : 1;

        APIManager.createForm(year, total, answers, formStatus)
            .then((res) => {
                if (res.status === 200) {
                    setLoader(false);
                    CookieManager.deleteLocalStorage(cookieQuestionaire);
                    getForms();
                } else {
                    setLoader(false);
                }
            })
            .catch((err) => {
                setLoader(false);
            });
    };

    const deleteDocument = (id: number) => {
        APIManager.deleteDocument(id)
            .then((res) => {
                res.status === 204 ? getForms() : setErrorDelete(true);
            })
            .catch((err) => {
                console.log(err);
                setErrorDelete(true);
            });
    };

    const getForms = () => {
        setLoaderForms(true);
        window.scrollTo(0, 0);
        APIManager.getForms()
            .then((res) => {
                const filtered = res.data.data.filter((form: any) => {
                    if (form.user_created) {
                        return form.user_created.id === user.id;
                    } else {
                        return true;
                    }
                });
                setForms(filtered);
                setLoaderForms(false);
            })
            .catch((err) => {
                console.log(err);
                setLoaderForms(false);
            });
    };

    const calculateTotal = (infoUser: any) => {
        const basePrice = infoUser.basePrice;
        if (infoUser.selectedWay === 1) {
            const totalQuestions = infoUser.questionsResident
                .map((q: any) => {
                    return q.answer.amount;
                })
                .reduce((prev: number, curr: number) => prev + curr, 0);
            return basePrice + totalQuestions;
        } else {
            const totalQuestions = infoUser.questionsNonResident
                .map((q: any) => {
                    return q.answer.amount;
                })
                .reduce((prev: number, curr: number) => prev + curr, 0);
            return basePrice + totalQuestions;
        }
    };

    const isInProgressOrApproved = (formStatus: number) => {
        if (formStatus !== 7) {
            return true;
        } else {
            return false;
        }
    };

    const isCompleted = (formStatus: number) => {
        if (formStatus === 7) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <Wrapper
            loader={loader}
            activePage={I18n.t('NAV_ACCOUNT')}
            circles_is_request_page
            bottom_banner_button_arrow
            page={PAGE}
            seo_title={PAGE.seo_title}
            seo_description={PAGE.seo_description}
        >
            <Menu activeTab={I18n.t('ACCOUNT_DASHBOARD')} />
            <Fragment>
                <ChatwootWidget />
            </Fragment>
            <section className='dashboard container mx-auto'>
                {loaderForms ? (
                    <div className='container-loader'>
                        <Loader show={loaderForms} />
                    </div>
                ) : (
                    <>
                        <div className='row d-flex justify-content-center'>
                            <div className='col-12 col-lg-10'>
                                {forms?.map((form) => {
                                    return (
                                        isInProgressOrApproved(form.form_status.id) && (
                                            <FormTax
                                                key={form.id}
                                                form={form}
                                                user={user}
                                                deleteDocument={deleteDocument}
                                                errorDelete={errorDelete}
                                                updateDocument={getForms}
                                                confirmationDeleteDocument={deleteDocument}
                                                passportInfo={passportInfo}
                                                fileTypes={TYPE_FILES}
                                            />
                                        )
                                    );
                                })}
                            </div>
                        </div>
                        <div className='row d-flex'>
                            {forms?.map((form) => {
                                return isCompleted(form.form_status.id) && <DocCompleted data={form} key={form.id} />;
                            })}
                        </div>
                    </>
                )}
            </section>
        </Wrapper>
    );
};

export default AccountPage;
