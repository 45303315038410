import React, { useState, FC, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../../style/style.scss';
import I18n from "../../../../helpers/i18n";
import API from '../../../../helpers/api';
import { accountpage } from '../../../../redux/pages';
import Menu from '../../components/menu/menu';
import PersonalInformationForm from '../../components/personal_information_form/personal_information_form';
import Wrapper from '../../../../components/wrapper/wrapper';
import { initialPage, interfacePage } from '../../../../models/page';

const APIManager = API.instance;

interface Props {
}

const PersonalInfoPage : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGEOVERVIEW = useSelector(accountpage);
    const [PAGE, SET_PAGE] = useState<interfacePage>(initialPage);

    useEffect(() => {
        setLoader(false);
        SET_PAGE(PAGEOVERVIEW)
    }, [PAGEOVERVIEW]);

    return (
        <Wrapper
            loader={loader}
            circles_is_request_page
            bottom_banner_button_arrow
            page={PAGE}
        >
            <Menu activeTab={I18n.t('ACCOUNT_PERSONAL')} />

            <section className="personal-info">
                <div className="container">
                    <div className="row d-flex justify-content-center align-items-center m-0">
                        <div className="col-12 col-lg-8 p-0">
                            <PersonalInformationForm />
                        </div>
                    </div>
                </div>
            </section>
        </Wrapper>
    );
}

export default PersonalInfoPage;